import React from "react";
import { withRouter } from "react-router-dom";
import "./ProgramSearchInput.css";
import { SearchIcon } from "../../assets";
import Geosuggest from "react-geosuggest";

class ProgramSearchInput extends React.Component {
  constructor(props) {
    super(props);
    console.log("PROPS IN PROGRAM SEARCH", props);
    this.state = {
      q: props.value || ""
    };
    this._geoSuggest = null;
    this.setAddress = this.setAddress.bind(this);
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (!this.props.location.pathname === "/register")
      this.props.history.push(`/search/${this.state.q}`);
  };

  setAddress = e => {
    if (e && e.gmaps) {
      try {
        this.setState({ q: e.gmaps.formatted_address.split(",")[0] });

        if (this.props.gLoc)
          this.props.gLoc(e.gmaps.formatted_address, e.location);
      } catch (ex) {}
    } else {
      this.setState({ q: e });
    }
  };

  getLatLng() {
    try {
      return new window.google.maps.LatLng(43.6499722, -79.3997312);
    } catch (ex) {
      return { lat: 43.6499722, lng: -79.3997312 };
    }
  }

  render() {
    return (
      <div
        className={
          this.props.noicon
            ? this.props.blueborder
              ? `n-search-blue`
              : `n-search-reg`
            : "n-search-container"
        }
      >
        <div className={`n-search-header`} style={this.props.style || {}}>
          <form
            className="n-search-main w-100"
            style={{ position: "relative",
            fontSize: "0.93rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "400",
            color: "#797979" }}
          >
            {/* <input name="utf8" type="hidden" value="&#x2713;" /> */}
            {/* <Input
              type="text"
              name="q"
              placeholder={this.props.placeholder}
              value={this.state.q}
              onChange={this.handleChange}
              className="w-100"
            /> */}
            <Geosuggest
              onSuggestSelect={this.setAddress}
              onChange={this.setAddress}
              initialValue={this.state.q}
              placeholder="Search by address or postal code"
              style={{
              fontFamily: "futura-pt, sans-serif",
              color: "#797979" }}
              ref={el => (this._geoSuggest = el)}
              location={this.getLatLng()}
              radius={30}
              id={this.props.name ? this.props.name : "geo"}
              autoComplete={"off"}
              disabled={this.props.disabled}
              // location={new google.maps.LatLng(53.558572, 9.9278215)}
              // radius="20"
            />
            {!this.props.noicon && (
              <button
                type="submit"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  marginRight: "10px",
                  width: "13%",
                  zIndex: 9999999
                }}
                disabled={true}
                onClick={this.handleSubmit}
              >
                <img src={SearchIcon} alt="Search" />
              </button>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(ProgramSearchInput);
