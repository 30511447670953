import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditFreePlan extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total_price: 0,
      description: "",
      isCapEnabled: false,
      isBilledLater: false,
      planCap: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async savePlan(e) {
    e.preventDefault();
    const obj = { ...this.state, type: "Free" };
    obj.description.substring(0, 50);

    const parsedPlans = this.global.program.plans.map(e =>
      typeof e === "string" ? JSON.parse(e) : e
    );
    const updatedPlans = parsedPlans.map(e =>
      e.id === this.props.selectedItem.id
        ? { ...this.props.selectedItem, description: this.state.description, isCapEnabled: this.state.isCapEnabled, isBilledLater: this.state.isBilledLater, planCap: this.state.planCap}
        : e
    );

    updatedPlans.forEach(e => {
      if (e.id === this.props.selectedItem.id) {
        if (this.global.program.total_spots && this.global.program.total_spots < e.planCap) {
          e.planCap = this.global.program.total_spots;
        } else {
          if (parseInt(e.planCap) > 200) {
            e.planCap = 200;
          }
        }
      }
    });

    // const plans = this.global.program.plans;
    // plans[this.props.selectedItem.id] = {
    //   ...this.props.selectedItem,
    //   description: this.state.description
    // };

    this.setGlobal(
      {
        program: {
          ...this.global.program,
          plans: updatedPlans
        }
      },
      () => {
        this.dispatch.saveProgram();
      }
    );
    this.props.onClose();
  }

  componentDidMount() {
    this.setState({ 
      description: this.props.selectedItem.description,
      isCapEnabled: this.props.selectedItem.isCapEnabled,
      isBilledLater: this.props.selectedItem.isBilledLater,
      planCap: this.props.selectedItem.planCap
     });
  }

  getBaseModalFields() {
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: true,
        maxLength: 32,
        info: "Max 32 characters",
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      }
    };

    const AddCap = {
      type: SWITCH,
      data: {
        name: "Enable Program Plan Cap",
        isDisabled: false,
        checked: this.state.isCapEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isCapEnabled: e.target.checked,
            planCap: ""
          })
      }
    };

    
    const BilledLater = {
      type: SWITCH,
      data: {
        name: "Billed Later",
        isDisabled: false,
        checked: this.state.isBilledLater,
        info: "If checked, the plan will display as Billed Later rather than Free plan on your marketplace page",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isBilledLater: e.target.checked
          })
      }
    };

    const PlanCap = {
      type: STRING_INPUT,
      data: {
        type: "number",
        name: this.global.program.total_spots ? `Plan Cap (Max: ${this.global.program.total_spots})` : "Plan Cap",
        required: false,
        value: this.state.planCap,
        placeholder: "Enter Cap",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            planCap: e.target.value
          });
        }
      }
    }; 

    const fields = [];

    fields.push(descriptionInput);
    if (this.global.program.program_details !== "Recurring") {
      fields.push(AddCap);
    }
    if (this.state.isCapEnabled) {
      fields.push(PlanCap);
    }
    fields.push(BilledLater);

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.savePlan(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Free Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    console.log("this.props", this.props);

    const label = (
      <>
        Description{" "}
        <span style={{ fontStyle: "italic", fontWeight: "normal" }}>
          (max 50 char)
        </span>
      </>
    );

    return (
      <ModalTemplate
        heading="Edit Free Plan"
        onClose={_ => this.props.onClose()}
      >
        <form onSubmit={e => this.savePlan(e)}>
          <div className="modal-main">
            <ModalFields.input
              label={label}
              name="description"
              type="text"
              onChange={e => {
                this.setState({
                  description: e.target.value
                });
              }}
              value={this.state.description.substring(0, 50)}
            />
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn">
              Save
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(EditFreePlan);
