import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  SELECT_MULTIPLE_DROPDOWN,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN,
  DATE_PICKER
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddFreeTrialPlanModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total_price: 0,
      description: "",
      isCapEnabled: false,
      planCap: "",
      trialDemographic: [],
      separateForChildren: false,
      trialPeriod: 1,
      allowAutoEmail: false
    }
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async savePlan(e) {
    e.preventDefault();
    const obj = { ...this.state, type: "Free Trial" };
    obj.description.substring(0, 50);
    if (this.global.program.total_spots && this.global.program.total_spots < parseInt(obj.planCap)) {
      obj.planCap = this.global.program.total_spots;
    } else {
      if (parseInt(obj.planCap) > 200) {
        obj.planCap = 200;
      }
    }

    this.setGlobal(
      {
        program: {
          ...this.global.program,
          // product_stripe: res.data.data.product,
          plans: [
            ...this.global.program.plans,
            {
              ...obj,
              id: this.global.program.plans.length
            }
          ]
        }
      },
      () => {
        this.dispatch.saveProgram();
      }
    );
    this.props.onClose();
  }

  getBaseModalFields() {
    console.log("Props: ", this.props);
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: true,
        maxLength: 32,
        info: "Max 32 characters",
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      },
      validators: {validateSubmit: () => this.state.description.trim()}
    };

    const trialDemographic = {
      type: DROPDOWN,
      data: {
        name: "Target Audience for Free Trial",
        placeholder: "Please Select",
        info: "Select the type of 'First Time' customers you want to target.",
        required: true,
        handleChange: e=> {
          this.setState({
            trialDemographic: e.target.value
          });
        },
        value: this.state.trialDemographic,
        choices: [
          {text: "Never booked anything with me before (New to Org)", value: 0},
          {text: "Never booked for this program before (New to program)", value: 1},
          {text: "Hasn't booked with me for a year (Inactive customers)", value: 2},
        ],
        titleStyle: {
          width: "270px"
        },
        styles: {
          width: "21.5vw",
          height: "auto"
        },
        wrapperStyles: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }
      }
    }
    console.log("addchild: ", this.props);
    const trialPerChild = {
      type: SWITCH,
      data: {
        name: "Allow separate free trials for children",
        isDisabled: !this.props.addChild,
        info: "This option is only available if Target Children is toggle on. Determines free trial eligibility based on each child rather than the Parent's account.",
        checked: this.state.separateForChildren,
        handleClick: e => 
          this.setState({
            separateForChildren: e.target.checked
          }),
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
      }
    }
    /* This is commented out for now. May come back to it to add this feature
    const trialPeriod = {
      type: STRING_INPUT,
      data: {
        name: "Trial Period",
        required: true,
        info: "This is the number of classes or sessions that the free-trial will last for.",
        handleChange: e => {
          this.setState({
            trialPeriod: e.target.value
          });
        },
        value: this.state.trialPeriod
      }
    }
    */

    const autoEmail = {
      type: SWITCH,
      data: {
        name: "Enable Automatic Follow-up Emails",
        isDisabled: false,
        checked: this.state.allowAutoEmail,
        handleClick: e =>
          this.setState({
            allowAutoEmail: e.target.checked
          }),
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
      }
    }

    const AddCap = {
      type: SWITCH,
      data: {
        name: "Enable Program Plan Cap",
        isDisabled: false,
        checked: this.state.isCapEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isCapEnabled: e.target.checked,
            planCap: ""
          })
      }
    };

    const PlanCap = {
      type: STRING_INPUT,
      data: {
        type: "number",
        name: this.global.program.total_spots ? `Plan Cap (Max: ${this.global.program.total_spots})` : "Plan Cap",
        required: false,
        placeholder: "Enter Cap",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            planCap: e.target.value
          });
        }
      }
    }; 

    const fields = [];

    fields.push(descriptionInput);
    fields.push(trialDemographic);
    fields.push(trialPerChild);
    fields.push(autoEmail);
    //fields.push(trialPeriod);
    if (this.global.program.program_details !== "Recurring") {
      fields.push(AddCap);
    }
    if (this.state.isCapEnabled) {
      fields.push(PlanCap);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Create",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.savePlan(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create Free Trial Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(AddFreeTrialPlanModal);