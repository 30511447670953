import React from "reactn";
import axios from "axios";
import { NewTabbedTable } from "../../components/UI";

import { format, isAfter, isBefore } from "date-fns";
import "../../assets/css/componentSpecificCss/newTabbedTable.css";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import RefundModal from "../../components/modals/RefundModal";
import EmailClientModal from "../../components/modals/EmailClientModal";

import PaidOrdersModal from "../../components/modals/PaidOrdersModal";
import InvoiceCustomerModal from "../../components/modals/InvoiceCustomerModal";
import CreateCustomerModal from "../../components/modals/CreateCustomerModal";
import DeleteInvoiceConfirmationModal from "../../components/modals/DeleteInvoiceConfirmationModal";

import { MiniPlus } from "../../assets";
import { withRouter } from "react-router-dom";
import NudgeInvoiceModal from "../../components/modals/NudgeInvoiceModal";
import CancelConfirmationModal from "../../components/modals/CancelConfirmationModal";

class TuitionPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Orders: [],
        "Installments Orders": [],
        Invoices: [],
        Pending: [],
        Payments: []
      },
      counts: {
        overdue: 0,
        paid: 0,
        pending: 0,
        total: 0
      },
      selectedStudent: null,
      selectedName: null,
      selectedData: {
        data: [],
        counts: {
          overdue: 0,
          paid: 0,
          pending: 0,
          total: 0
        }
      },
      selectedInvoice: null,
      openRefundModal: false,
      openEmailClientModal: false,
      selectedItem: null,
      usage: "paid_orders",
      openViewDetailsModal: false,
      openNudgeEmailModal: false,
      pendingCustomers: null,
      openCancelInvoiceModal: false,
      filter: "",
      page: -1
    };
    this.actions = [{ name: "VIEW", action: _ => true }];
    this.tabs = ["Pending", "Payments"];
    this.inputRef = React.createRef();

    // this.tabs = ["Orders", "Installments Orders", "Invoices"];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Order Tracking", to: "/orders" }
      ]
    });
  }

  getStatusColor = status => {
    if (status === "Draft") {
      return "rgb(33 91 255)";
    } else if (status === "Ready to Bill") {
      return "#eba93e";
    } else if (status === "Cancelled") {
      return "#ff3b8e";
    } else if (status === "Issued") {
      return "rgba(92 200 220)";
    } else if (status === "Late" || status === "Failed") {
      return "#ff3b8e";
    } else if (status === "Processing") {
      return "rgb(33 91 255)";
    } else {
      return "#1bc88a";
    }
  };

  getStatusElement = status => {
    return (
      <td>
        <div
          style={{
            textAlign: "right",
            display: "flex"
          }}
        >
          <span
            className="red"
            style={{
              minWidth: "100px",
              background: this.getStatusColor(status),
              padding: "3px",
              borderRadius: "13px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Open Sans",
              fontSize: "12px",
              fontWeight: 600
            }}
          >
            {status}
          </span>
        </div>
      </td>
    );
  };

  headings = () => {
    return {
      Pending: [
        {
          id: "amount",
          label: "Created Date",
          customCell: (r, i) => (
            <td className="table-custom-text">
              {format(new Date(r.createdDate), "LLLL dd, yyyy")}
            </td>
          )
        },
        {
          id: "start",
          label: "Issue Date",
          customCell: (r, i) => (
            <td className="table-custom-text">
              {format(new Date(r.startDate), "LLLL dd, yyyy")}
            </td>
          )
        },
        {
          id: "item",
          label: "Item",
          customCell: (r, i) => {
            let productNameArr = r.invoice_details.map(
              item => JSON.parse(item).itemName
            );
            if (r.type === "Invoice" && r.cartOrder) {

              let productName =
                "Invoice: " +
                productNameArr.map(item => item.split(": ")[1]).join(", ");

              if (productName.length > 90) {
                productName = productName.substring(0, 90) + "...";
              }
              return <td className="table-custom-text">{productName}</td>;
            }
            return <td className="table-custom-text">{productNameArr}</td>;
          }
        },
        {
          id: "amountOverdue",
          label: "Total", // Both Invoice and orders have the same field
          customCell: (r, i) => (
            <td className="table-custom-text">
              {parseFloat(r.total / 100)
                // .toFixed(2)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })}
            </td>
          )
        },
        {
          id: "purchaser",
          label: "Purchaser",
          customCell: (r, i) => {
            if (r.childName?.length > 0) {
              const childNamesConcatenated = r.childName.join(", ");

              const truncatedChildNames =
                childNamesConcatenated.length > 25
                  ? childNamesConcatenated.substring(0, 25) + "..."
                  : childNamesConcatenated;
              return (
                <td className="table-custom-text">
                  {r.purchaser} (
                  <span style={{ fontStyle: "italic" }}>
                    {truncatedChildNames}
                  </span>
                  )
                </td>
              );
            }
            return <td className="table-custom-text">{r.purchaser}</td>;
          }
        },
        {
          id: "status",
          label: "Status",
          customCell: (r, i) => this.getStatusElement(r.status)
        },
        {
          id: "actio",
          label: "",
          customStyle: { width: 80 },
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
              <div>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    r.type === "Invoice" 
                      ? this.invoiceMenu(r, i)
                      : this.menu(r, i)
                  }
                  animation="slide-up"
                >
                  <button
                    className="table-action-button"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    disabled={this.canSubUserEdit()}
                  >
                    Action
                  </button>
                </Dropdown>
              </div>
            </td>
          )
        }
      ],
      Payments: [
        {
          id: "payment_date",
          label: "Payment Date",
          customCell: (r, i) => {
            return (
              <td key={i} className="table-custom-text">
                {r.userPaidDate
                  ? format(new Date(r.userPaidDate), "LLL dd, yyyy")
                  : ""}
              </td>
            );
          }
        },
        {
          id: "amount",
          label: "Created Date",
          customCell: (r, i) => (
            <td className="table-custom-text" key={i}>
              {format(new Date(r.createdDate), "LLLL dd, yyyy")}
            </td>
          )
        },
        {
          id: "item",
          label: "Item",
          customCell: (r, i) => {
            let productNameArr = r.invoice_details.map(
              item => JSON.parse(item).itemName
            );
            if (r.type === "Invoice" && r.cartOrder) {

              let productName =
                "Invoice: " +
                productNameArr.map(item => item.split(": ")[1]).join(", ");

              if (productName.length > 90) {
                productName = productName.substring(0, 90) + "...";
              }
              return <td className="table-custom-text">{productName}</td>;
            }
            return <td className="table-custom-text">{productNameArr[0].includes("undefined")? r.item : productNameArr}</td>;
          }
        },
        {
          id: "amountOverdue",
          label: "Total", // Both Invoice and orders have the same field
          customCell: (r, i) => (
            <td className="table-custom-text">
              {parseFloat(r.total / 100)
                // .toFixed(2)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })}
            </td>
          )
        },
        {
          id: "amountOverdue",
          label: "Purchaser",
          customCell: (r, i) => {
            if (r.childName?.length > 0) {
              const childNamesConcatenated = r.childName.join(", ");

              const truncatedChildNames =
                childNamesConcatenated.length > 25
                  ? childNamesConcatenated.substring(0, 25) + "..."
                  : childNamesConcatenated;
              return (
                <td className="table-custom-text">
                  {r.purchaser} (
                  <span style={{ fontStyle: "italic" }}>
                    {truncatedChildNames}
                  </span>
                  )
                </td>
              );
            }
            return <td className="table-custom-text">{r.purchaser}</td>;
          }
        },
        {
          id: "userPaymentMethod",
          label: "Paid Using",
          customCell: (r, i) => (
            <td className="table-custom-text">{r.paymentMethod}</td>
          )
        },
        {
          id: "status",
          label: "Status",
          customCell: (r, i) => this.getStatusElement(r.status)
        },
        {
          id: "actio",
          label: "",
          customStyle: { width: 80 },
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
              <div>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    // r.type === "Invoice"
                    // ? this.invoiceMenu(r, i)
                    // :
                    this.menu(r, i)
                  }
                  animation="slide-up"
                >
                  <button
                    className="table-action-button"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    disabled={this.canSubUserEdit()}
                  >
                    Action
                  </button>
                </Dropdown>
              </div>
            </td>
          )
        }
      ]
    };
  };

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return this.global.dsUser.accesspoints.Financials["Paid Orders"].edit
        ? false
        : true;
    }
  };

  onSelect = async (r, i, event) => {
    if (event.key === "MarkPaid") {
      this.markAsPaid(r);
    }
    if (event.key === "MarkUnpaid") {
      this.markAsUnpaid(r);
    }
    if (event.key === "refund") {
      if (r.installment_amount) {
        this.setState({
          openRefundModal: true,
          selectedItem: r.payments[r.payments.length - 1].invoice,
          usage: "recurring_payments"
        });
        return;
      }
      this.setState({
        openRefundModal: true,
        selectedItem: r,
        usage: "paid_orders"
      });
    }
    if (event.key === "emailClient") {
      this.setState({ openEmailClientModal: true, selectedItem: r });
    }

    if (event.key === "editInvoice") {
      this.props.history.push({
        pathname: `/partners/orders/invoice-builder/${r.id}`,
        state: { isNewCustomer: false }
      });
    }

    if (event.key === "viewDetails") {
      // this.props.history.push(`orders/orders-receipt/${r.orderId}`);
      this.props.history.push(`orders/orders-receipt/${r.id}`);
      // this.setState({ openViewDetailsModal: true, selectedItem: r });
    }
  };

  menu = (r, i) => {
    return (
      <Menu onClick={event => this.onSelect(r, i, event)}>
        {(r.status === "Paid" || r.status === "Paid (refunded)") && (
          <MenuItem key="refund">Refund</MenuItem>
        )}
        <MenuItem key="viewDetails">View Details</MenuItem>
      </Menu>
    );
  };

  onInvoiceSelect = async (r, i, event) => {
    if (event.key === "viewDetails") {
      this.props.history.push({
        pathname: `orders/invoice-builder/${r.id}`,
        state: { isNewCustomer: false }
      });
    }

    if (event.key === "refund") {
      if (r.installment_amount) {
        this.setState({
          openRefundModal: true,
          selectedItem: r.payments[r.payments.length - 1].invoice,
          usage: "recurring_payments"
        });
        return;
      }

      this.setState({
        openRefundModal: true,
        selectedItem: r,
        usage: "paid_orders"
      });
    }

    if (event.key === "delete-invoice") {
      this.setState({
        openDeleteInvoiceConfirmationModal: true,
        selectedItem: r,
        usage: "paid_orders"
      });
    }

    if (event.key === "nudge") {
      this.setState({
        openNudgeEmailModal: true,
        selectedItem: r
      });
    }

    if (event.key === "cancel") {
      this.setState({
        openCancelInvoiceModal: true,
        selectedItem: r
      });
    }
  };

  invoiceMenu = (r, i) => {
    return (
      <Menu onClick={event => this.onInvoiceSelect(r, i, event)}>
        {r.status === "Paid" && <MenuItem key="refund">Refund</MenuItem>}
        <MenuItem key="viewDetails">View Details</MenuItem>
        {r.tab === "pending" && (r.status === "Issued" || r.status === "Late") && (
          <MenuItem key="nudge">Nudge</MenuItem>
        )}
        {r.tab === "pending" && r.status === "Issued" && (
          <MenuItem key="cancel">Cancel</MenuItem>
        )}
        {r.tab === "pending" &&
          r.status !== "Issued" &&
          r.status !== "Processing" && (
            <MenuItem key="delete-invoice">Delete Invoice</MenuItem>
          )}
      </Menu>
    );
  };

  async markAsPaid(r) {
    const ep = `${process.env.REACT_APP_API}/partners/orders/mark_paid`;
    const res = await axios.put(ep, r);
    if (res.data.success) {
      this.fetchInitialData();
    }
  }

  async markAsUnpaid(r) {
    const ep = `${process.env.REACT_APP_API}/partners/orders/mark_unpaid`;
    const res = await axios.put(ep, r);

    if (res.data.success) {
      this.fetchInitialData();
    }
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    console.time('making request');
    const ep = `${process.env.REACT_APP_API}/partners/order-tracking`;
    const res = await axios.get(ep);
    console.timeEnd('making request');
    console.log("res", res.data);

    const dateSort = (d1, d2) => {
      if (isBefore(d1, d2)) {
        return -1;
      }
      if (isAfter(d1, d2)) {
        return 1;
      }
      return 0;
    };

    if (res.data?.success) {
      console.time('setting state');
      this.setState({
        data: {
          Pending: res.data.data.Once.pending
            .filter(e => !e.hidden)
            .sort(
              (e1, e2) =>
                new Date(e2.date).getTime() - new Date(e1.date).getTime()
            ),
          Payments: [
            ...res.data.data.Once.failed,
            ...res.data.data.Once.payments
          ]
            .filter(e => !e.hidden)
            .sort(
              (e1, e2) =>
                new Date(e2.date).getTime() - new Date(e1.date).getTime()
            )
        },
        earnings: res.data.data.Once.payments.reduce(
          (prev, curr) => prev + parseInt(curr.total) / 100,
          0
        ),
        refunds: res.data.data.Once.payments.reduce(
          (prev, curr) => prev + parseInt(curr.refundedAmount) / 100,
          0
        )
      });
      console.timeEnd('setting state');
    } else {
      console.error("Something went wrong fetching the data");
    }
    this.setGlobal({ loading: false });
  };

  getFilteredOrders() {
    const customersArr = JSON.parse(JSON.stringify(this.state.data));

    if (customersArr["Pending"]) {
      customersArr["Pending"] = customersArr["Pending"].filter(customer => {
        const value = customer?.purchaser?.toLowerCase().replace(/\s/g, "");
        const item = customer?.item?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter) || (item && item.includes(filter));
      });
    }

    if (customersArr["Payments"]) {
      console.log(customersArr);
      customersArr["Payments"] = customersArr["Payments"].filter(customer => {
        const value = customer?.purchaser?.toLowerCase().replace(/\s/g, "");
        const item = customer?.item?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter) || (item && item.includes(filter));
      });
    }

    return customersArr;
  }

  render() {
    console.log("this.props", this.props);
    console.log("this.state", this.state);

    return (
      <>
        {this.state.openCreateCustomerModal && (
          <CreateCustomerModal
            createNewCustomer={_ =>
              this.setState({ openCreateCustomerModal: true })
            }
            pendingCustomers={this.state.pendingCustomers}
            onClose={_ => {
              this.setState({ openCreateCustomerModal: false });
            }}
          />
        )}
        {this.state.openInvoiceCustomerModal && (
          <InvoiceCustomerModal
            createNewCustomer={e => {
              this.setState({
                openCreateCustomerModal: true,
                pendingCustomers: e
              });
            }}
            onClose={_ => {
              this.setState({ openInvoiceCustomerModal: false });
            }}
          />
        )}
        {this.state.openViewDetailsModal && (
          <PaidOrdersModal
            selectedItem={this.state.selectedItem}
            onClose={_ => {
              this.setState({ openViewDetailsModal: false });
            }}
          />
        )}
        {this.state.openRefundModal && (
          <RefundModal
            selectedItem={this.state.selectedItem}
            amountRefunded={this.state.selectedItem.refundedAmount}
            post_payment_credit_notes_amount={
              this.state.selectedItem.invoice
                ? this.state.selectedItem.invoice
                    .post_payment_credit_notes_amount
                : this.state.selectedItem.post_payment_credit_notes_amount
            }
            onClose={_ => {
              this.setState({ openRefundModal: false });
            }}
            refresh={this.fetchInitialData}
            usage={this.state.usage}
          />
        )}
        {this.state.openEmailClientModal && (
          <EmailClientModal
            selectedItem={this.state.selectedItem}
            charge={
              this.state.selectedItem.id ||
              this.state.selectedItem.payments[
                this.state.selectedItem.payments.length - 1
              ].invoice.charge
            }
            onClose={_ => {
              // this.fetchInitialData();
              this.setState({ openEmailClientModal: false });
            }}
          />
        )}
        {this.state.openDeleteInvoiceConfirmationModal && (
          <DeleteInvoiceConfirmationModal
            selectedItem={this.state.selectedItem}
            confirmDelete={_ => this.fetchInitialData()}
            onClose={_ => {
              // this.fetchInitialData();
              this.setState({ openDeleteInvoiceConfirmationModal: false });
            }}
          />
        )}
        {this.state.openNudgeEmailModal && (
          <NudgeInvoiceModal
            selectedItem={this.state.selectedItem}
            confirmNudge={_ => this.fetchInitialData()}
            onClose={_ => {
              this.setState({ openNudgeEmailModal: false });
            }}
          />
        )}
        {this.state.openCancelInvoiceModal && (
          <CancelConfirmationModal
            id={this.state.selectedItem.id}
            confirmCancel={_ => this.fetchInitialData()}
            onClose={_ => this.setState({ openCancelInvoiceModal: false })}
          />
        )}
        <div className="container-fluid">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Order Tracking</h1>
            </div>

            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div
                  className="forbtnapp enrollment-btn"
                  style={{ marginLeft: "20px" }}
                >
                  <button
                    className="newapplications"
                    style={{
                      cursor: "pointer"
                    }}
                    onClick={_ => {
                      this.setState({ openInvoiceCustomerModal: true });
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="cont">
            <div className="row">
              <div className="col-lg-3">
                <div className="money-box mt-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-6">
                      <div className="money-box-inner">
                        <h2>ORDER EARNINGS</h2>
                        <h3
                          style={{ paddingBottom: "2px", paddingTop: "20px" }}
                        >
                          {parseFloat(this.state.earnings || 0).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD"
                            }
                          )}
                        </h3>
                        <p
                          style={{
                            color: "#a1a1a1",
                            textAlign: "center",
                            paddingBottom: "20px",
                            fontStyle: "italic",
                            fontSize: "0.9rem"
                          }}
                        >
                          Refunds (to-date):{" "}
                          {parseFloat(this.state.refunds || 0).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD"
                            }
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6">
                      <div className="money-box-inner">
                        <h2>TOTAL ORDERS</h2>
                        <h3>
                          {this.state.data.Payments.length +
                            this.state.data.Pending.length}
                        </h3>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6">
                      <div className="money-box-inner">
                        <h2>PAID ORDERS</h2>
                        <h3>{this.state.data.Payments.length}</h3>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6">
                      <div className="money-box-inner">
                        <h2>PAYMENTS OWED</h2>
                        <h3>{this.state.data.Pending.length}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div
                  style={{
                    display: "inline-flex",
                    flex: "1 1",
                    border: "none",
                    borderRadius: "10px",
                    overflow: "hidden",
                    width: "100%"
                  }}
                >
                  <i
                    className="fas fa-search"
                    style={{ padding: "8px", background: "white" }}
                  ></i>
                  <input
                    type="text"
                    ref={this.inputRef}
                    placeholder="Search by purchaser name or item name"
                    style={{
                      border: "none",
                      fontSize: "13px",
                      padding: "5px",
                      outline: 0,
                      background: "white",
                      flex: 1
                    }}
                    value={this.state.filter}
                    onChange={e => {
                      this.setState({ 
                        filter: this.inputRef.current.value,
                        page: 0  
                      });
                    }}
                  />
                </div>
                <NewTabbedTable
                  data={this.getFilteredOrders()}
                  tabs={this.tabs}
                  page={"Financials>Paid Orders"}
                  headings={this.headings()}
                  actions={this.actions}
                  currentPage={this.state.page}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(TuitionPayments);

// import React from "reactn";
// // import Tables from "../../components/table";
// import axios from "axios";
// import { NewTabbedTable } from "../../components/UI";
// // import { AmountArrow } from "../../assets";
// import {
//   eachDayOfInterval,
//   format
//   // addWeeks
// } from "date-fns";
// // import PaymentDetailsModal from "../../components/modals/PaymentDetailsModal";
// // import { Link } from "react-router-dom";
// import "../../assets/css/componentSpecificCss/newTabbedTable.css";
// import Dropdown from "rc-dropdown";
// import Menu, { Item as MenuItem } from "rc-menu";
// import RefundModal from "../../components/modals/RefundModal";
// import EmailClientModal from "../../components/modals/EmailClientModal";
// import { DateTime } from "luxon";
// import PaidOrdersModal from "../../components/modals/PaidOrdersModal";
// import InvoiceCustomerModal from "../../components/modals/InvoiceCustomerModal";
// import CreateCustomerModal from "../../components/modals/CreateCustomerModal";
// import DeleteInvoiceConfirmationModal from "../../components/modals/DeleteInvoiceConfirmationModal";

// import { MiniPlus } from "../../assets";
// import { withRouter } from "react-router-dom";

// class TuitionPayments extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       data: {
//         Orders: [],
//         "Installments Orders": [],
//         Invoices: [],
//         Pending: [],
//         Payments: []
//       },
//       counts: {
//         overdue: 0,
//         paid: 0,
//         pending: 0,
//         total: 0
//       },
//       selectedStudent: null,
//       selectedName: null,
//       selectedData: {
//         data: [],
//         counts: {
//           overdue: 0,
//           paid: 0,
//           pending: 0,
//           total: 0
//         }
//       },
//       selectedInvoice: null,
//       openRefundModal: false,
//       openEmailClientModal: false,
//       selectedItem: null,
//       usage: "paid_orders",
//       openViewDetailsModal: false
//     };
//     this.actions = [{ name: "VIEW", action: _ => true }];
//     this.tabs = ["Pending", "Payments"];

//     // this.tabs = ["Orders", "Installments Orders", "Invoices"];
//     this.setGlobal({
//       partnerPathway: [
//         ...this.global.partnerPathway.slice(0, 1),
//         { label: "Order Tracking", to: "/orders" }
//       ]
//     });
//   }

//   headings = () => {
//     // pending headings
//     const pendingDate = {
//       id: "amount",
//       label: "Date",
//       customCell: (r, i) => {
//         let local;
//         if (r.type !== "Invoice") {
//           local = DateTime.fromSeconds(r.created);
//         }
//         if (r.type === "Invoice") {
//           local = DateTime.fromMillis(new Date(r.createdAt).getTime());
//         }
//         const rezoned = local.setZone(this.global.organizationInfo.timezone);
//         return <td className="table-custom-text">{rezoned.toFormat("DD")}</td>;
//       }
//     };

//     const pendingItem = {
//       id: "item",
//       label: "Item",
//       customCell: (r, i) => {
//         if (r.type !== "Invoice") {
//           return <td className="table-custom-text">{r.item}</td>;
//         }
//         if (r.type === "Invoice") {
//           return (
//             <td className="table-custom-text">
//               Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//             </td>
//           );
//         }
//       }
//     };

//     const pendingTotal = {
//       id: "amountOverdue",
//       label: "Total", // Both Invoice and orders have the same field
//       customCell: (r, i) => (
//         <td className="table-custom-text">
//           ${parseFloat(r.amount / 100).toFixed(2)}
//         </td>
//       )
//     };

//     const pendingPurchaser = {
//       id: "amountOverdue",
//       label: "Purchaser",
//       customCell: (r, i) => {
//         if (r.type !== "Invoice") {
//           return <td className="table-custom-text">{r.customer_name}</td>;
//         }
//         if (r.type === "Invoice") {
//           return <td className="table-custom-text">{r.displayName}</td>;
//         }
//       }
//     };

//     const pendingStatus = {
//       id: "status",
//       label: "Status",
//       customCell: (r, i) => {
//         if (r.type === "Invoice") {
//           let color = "#eba93e";
//           let text = "Ready to Bill";
//           switch (r.invoice_status) {
//             case "Draft":
//               text = "Draft";
//               color = "rgb(33 91 255)";
//               break;

//             case "Issued":
//               text = "Issued";
//               color = "rgba(92 200 220)";
//               break;

//             case "Ready to Bill":
//               text = "Ready to Bill";
//               color = "#eba93e";
//               break;

//             case "Cancelled":
//               text = "Cancelled";
//               color = "#ff3b8e";
//               break;

//             case "Paid Offline":
//               text = "Paid Offline";
//               color = "#1bc88a";
//               break;

//             default:
//               break;
//           }

//           if (
//             r.invoice_status === "Issued" &&
//             new Date() > new Date(r.due_date)
//           ) {
//             // return <td className="table-custom-text">Late</td>;
//             text = "Late";
//             color = "#ff3b8e";
//           }

//           if (r.status === "Processing") {
//             text = "Processing";
//             color = "rgb(33 91 255)";
//           }

//           return (
//             <td>
//               <div
//                 style={{
//                   textAlign: "right",
//                   display: "flex"
//                 }}
//               >
//                 <span
//                   className="red"
//                   style={{
//                     minWidth: "100px",
//                     background: color,
//                     padding: "3px",
//                     borderRadius: "13px",
//                     color: "white",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     fontFamily: "Open Sans",
//                     fontSize: "12px",
//                     fontWeight: 600
//                   }}
//                 >
//                   {text}
//                 </span>
//               </div>
//             </td>
//           );
//         }
//         return this.orderStatus(r, i);
//       }
//     };

//     const pendingAction = {
//       id: "actio",
//       label: "",
//       customStyle: { width: 80 },
//       customCell: (r, i) => (
//         <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//           <div>
//             <Dropdown
//               trigger={["click"]}
//               overlay={
//                 r.type === "Invoice" ? this.invoiceMenu(r, i) : this.menu(r, i)
//               }
//               animation="slide-up"
//             >
//               <button
//                 className="table-action-button"
//                 style={{
//                   cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//                 }}
//                 disabled={this.canSubUserEdit()}
//               >
//                 Action
//               </button>
//             </Dropdown>
//           </div>
//         </td>
//       )
//     };

//     const pendingHeadings = [
//       pendingDate,
//       pendingItem,
//       pendingTotal,
//       pendingPurchaser,
//       pendingStatus,
//       pendingAction
//     ];

//     // if (r.type !== "Invoice") {
//     //         const createdDate = new Date(r.created * 1000);

//     //         const local = DateTime.fromSeconds(r.created);
//     //         const rezoned = local.setZone(
//     //           this.global.organizationInfo.timezone
//     //         );
//     //         return (
//     //           <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//     //         );
//     //       }
//     //       if (r.type === "Invoice") {
//     //         const createdDate = new Date(r.createdAt * 1000);

//     //         const local = DateTime.fromMillis(
//     //           new Date(r.createdAt).getTime()
//     //         );
//     //         const rezoned = local.setZone(
//     //           this.global.organizationInfo.timezone
//     //         );
//     //         return (
//     //           <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//     //         );
//     //       }
//     const paymentsDate = {
//       id: "amount",
//       label: "Date",
//       customCell: (r, i) => {
//         const date = new Date(r.date);
//         if (isNaN(date.getTime())) {
//           return <td className="table-custom-text">---</td>;
//         }
//         return (
//           <td className="table-custom-text">{format(date, "MMM dd, yyyy")}</td>
//         );
//       }
//     };

//     const paymentsItem = {
//       id: "item",
//       label: "Item",
//       customCell: (r, i) => {
//         if (r.type !== "Invoice") {
//           return (
//             <td className="table-custom-text">
//               {r.product_table === "partner_programs" &&
//                 this.showProgramPlan(r)}
//               {r.product_table === "partner_event" && this.showEventPlan(r)}
//               {r.product_table === "partner_memberships" &&
//                 this.showMembershipPlan(r)}
//               {r.product_table === "partner_online" && this.showProgramPlan(r)}
//             </td>
//           );
//         }
//         if (r.type === "Invoice") {
//           return (
//             <td className="table-custom-text">
//               Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//             </td>
//           );
//         }
//       }
//     };

//     const paymentsTotal = {
//       id: "amountOverdue",
//       label: "Total", // Both Invoice and orders have the same field
//       customCell: (r, i) => {
//         if (r.isInstallment) {
//           return (
//             <td className="table-custom-text">
//               ${parseFloat(r.installment_amount).toFixed(2)}
//             </td>
//           );
//         }
//         return (
//           <td className="table-custom-text">
//             ${parseFloat(r.amount / 100).toFixed(2)}
//           </td>
//         );
//       }
//     };

//     const paymentsPurchaser = {
//       id: "amountOverdue",
//       label: "Purchaser",
//       customCell: (r, i) => {
//         // if (r.isInstallment) {
//         //   return <td className="table-custom-text">{r.buyer}</td>;
//         // }
//         // if (r.type !== "Invoice") {
//         //   return <td className="table-custom-text">{r.customer_name}</td>;
//         // }
//         // if (r.type === "Invoice") {
//         //   return <td className="table-custom-text">{r.displayName}</td>;
//         // }
//         return <td className="table-custom-text">{r.purchaser}</td>;
//       }
//     };

//     const paymentsPaidUsing = {
//       id: "userPaymentMethod",
//       label: "Paid Using",
//       customCell: (r, i) => {
//         // if (r.userPaymentMethod) {
//         //   return <td className="table-custom-text">{r.userPaymentMethod}</td>;
//         // } else if (r.manual) {
//         //   return <td className="table-custom-text">Offline</td>;
//         // } else if (r.stripe) {
//         //   return <td className="table-custom-text">Credit Card</td>;
//         // } else if (r.stripe_transaction_id?.includes("pi_")) {
//         //   return <td className="table-custom-text">Credit Card</td>;
//         // } else {
//         //   return <td className="table-custom-text">Offline</td>;
//         // }
//         return <td className="table-custom-text">{r.paidUsing}</td>;
//       }
//     };

//     const paymentsStatus = {
//       id: "status",
//       label: "Status",
//       customCell: (r, i) => {
//         if (r.type === "Invoice") {
//           return (
//             <td className="green">
//               <span
//                 style={{
//                   minWidth: "100px",
//                   background: "#1bc88a",
//                   padding: "3px",
//                   borderRadius: "13px",
//                   color: "white",
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   fontFamily: "Open Sans",
//                   fontSize: "12px",
//                   fontWeight: 600
//                 }}
//               >
//                 {r.invoice_status === "Paid Offline" ? `Paid (Manual)` : "Paid"}
//               </span>
//             </td>
//           );
//         }

//         return this.orderStatus(r, i);
//       }
//     };

//     const paymentsAction = {
//       id: "actio",
//       label: "",
//       customStyle: { width: 80 },
//       customCell: (r, i) => (
//         <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//           <div>
//             <Dropdown
//               trigger={["click"]}
//               overlay={
//                 r.type === "Invoice" ? this.invoiceMenu(r, i) : this.menu(r, i)
//               }
//               animation="slide-up"
//             >
//               <button
//                 className="table-action-button"
//                 style={{
//                   cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//                 }}
//                 disabled={this.canSubUserEdit()}
//               >
//                 Action
//               </button>
//             </Dropdown>
//           </div>
//         </td>
//       )
//     };

//     const paymentsHeadings = [
//       paymentsDate,
//       paymentsItem,
//       paymentsTotal,
//       paymentsPurchaser,
//       paymentsPaidUsing,
//       paymentsStatus,
//       paymentsAction
//     ];

//     return {
//       Pending: pendingHeadings,
//       Payments: paymentsHeadings
//       // [
//       // {
//       //   id: "amount",
//       //   label: "Date",
//       //   customCell: (r, i) => {
//       //     if (r.type !== "Invoice") {
//       //       const createdDate = new Date(r.created * 1000);

//       //       const local = DateTime.fromSeconds(r.created);
//       //       const rezoned = local.setZone(
//       //         this.global.organizationInfo.timezone
//       //       );
//       //       return (
//       //         <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//       //       );
//       //     }
//       //     if (r.type === "Invoice") {
//       //       const createdDate = new Date(r.createdAt * 1000);

//       //       const local = DateTime.fromMillis(
//       //         new Date(r.createdAt).getTime()
//       //       );
//       //       const rezoned = local.setZone(
//       //         this.global.organizationInfo.timezone
//       //       );
//       //       return (
//       //         <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//       //       );
//       //     }
//       //   }
//       // },
//       // {
//       //   id: "item",
//       //   label: "Item",
//       //   customCell: (r, i) => {
//       //     if (r.type !== "Invoice") {
//       //       return (
//       //         <td className="table-custom-text">
//       //           {r.product_table === "partner_programs" &&
//       //             this.showProgramPlan(r)}
//       //           {r.product_table === "partner_event" && this.showEventPlan(r)}
//       //           {r.product_table === "partner_memberships" &&
//       //             this.showMembershipPlan(r)}
//       //           {r.product_table === "partner_online" &&
//       //             this.showProgramPlan(r)}
//       //         </td>
//       //       );
//       //     }
//       //     if (r.type === "Invoice") {
//       //       return (
//       //         <td className="table-custom-text">
//       //           Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//       //         </td>
//       //       );
//       //     }
//       //   }
//       // },
//       // {
//       //   id: "amountOverdue",
//       //   label: "Sub-Total",
//       //   customCell: (r, i) => {
//       //     if (r.type === "Invoice") {
//       //       return (
//       //         <td className="table-custom-text">
//       //           ${parseFloat(r.itemAmount / 100).toFixed(2)}
//       //         </td>
//       //       );
//       //     }
//       //     return this.displaySubTotal(r, i);
//       //   }
//       // },
//       // {
//       //   id: "tax",
//       //   label: "Tax %",
//       //   customCell: (r, i) =>this.displayTaxOrders(r)
//       // },
//       // {
//       //   id: "amountOverdue",
//       //   label: "Total", // Both Invoice and orders have the same field
//       //   customCell: (r, i) => (
//       //     <td className="table-custom-text">
//       //       ${parseFloat(r.amount / 100).toFixed(2)}
//       //     </td>
//       //   )
//       // },
//       // {
//       //   id: "amountOverdue",
//       //   label: "Purchaser",
//       //   customCell: (r, i) => {
//       //     if (r.type !== "Invoice") {
//       //       return <td className="table-custom-text">{r.customer_name}</td>;
//       //     }
//       //     if (r.type === "Invoice") {
//       //       return <td className="table-custom-text">{r.displayName}</td>;
//       //     }
//       //   }
//       // },
//       // {
//       //   id: "status",
//       //   label: "Status",
//       //   customCell: (r, i) => {
//       //     if (r.type === "Invoice") {
//       //       let color = "#eba93e";
//       //       let text = "Ready to Bill";
//       //       switch (r.invoice_status) {
//       //         case "Draft":
//       //           text = "Draft";
//       //           color = "rgb(33 91 255)";
//       //           break;

//       //         case "Issued":
//       //           text = "Issued";
//       //           color = "rgba(92 200 220)";
//       //           break;

//       //         case "Ready to Bill":
//       //           text = "Ready to Bill";
//       //           color = "#eba93e";
//       //           break;

//       //         case "Cancelled":
//       //           text = "Cancelled";
//       //           color = "#ff3b8e";
//       //           break;

//       //         case "Paid Offline":
//       //           text = "Paid Offline";
//       //           color = "#1bc88a";
//       //           break;

//       //         default:
//       //           break;
//       //       }

//       //       if (
//       //         r.invoice_status === "Issued" &&
//       //         new Date() > new Date(r.due_date)
//       //       ) {
//       //         // return <td className="table-custom-text">Late</td>;
//       //         text = "Late";
//       //         color = "#ff3b8e";
//       //       }

//       //       if (r.status === "Processing") {
//       //         text = "Processing";
//       //         color = "rgb(33 91 255)";
//       //       }

//       //       return (
//       //         <td>
//       //           <div
//       //             style={{
//       //               textAlign: "right",
//       //               display: "flex"
//       //             }}
//       //           >
//       //             <span
//       //               className="red"
//       //               style={{
//       //                 minWidth: "100px",
//       //                 background: color,
//       //                 padding: "3px",
//       //                 borderRadius: "13px",
//       //                 color: "white",
//       //                 display: "flex",
//       //                 justifyContent: "center",
//       //                 alignItems: "center",
//       //                 fontFamily: "Open Sans",
//       //                 fontSize: "12px",
//       //                 fontWeight: 600
//       //               }}
//       //             >
//       //               {text}
//       //             </span>
//       //           </div>
//       //         </td>
//       //       );
//       //     }
//       //     return this.orderStatus(r, i);
//       //   }
//       // },
//       // {
//       //   id: "actio",
//       //   label: "",
//       //   customStyle: { width: 80 },
//       //   customCell: (r, i) => (
//       //     <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//       //       {/* {r.manual && !r.paid && (
//       //         <button
//       //           onClick={_ => this.markAsPaid(r)}
//       //           className="table-action-button"
//       //         >
//       //           Mark as Paid
//       //         </button>
//       //       )} */}
//       //       {/* <button className="table-action-button">VIEW</button> */}

//       //       <div
//       //       // style={{
//       //       //   position: "absolute",
//       //       //   right: "15px",
//       //       //   top: "5px"
//       //       // }}
//       //       >
//       //         <Dropdown
//       //           trigger={["click"]}
//       //           overlay={
//       //             r.type === "Invoice"
//       //               ? this.invoiceMenu(r, i)
//       //               : this.menu(r, i)
//       //           }
//       //           animation="slide-up"
//       //         >
//       //           <button
//       //             className="table-action-button"
//       //             style={{
//       //               cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//       //             }}
//       //             disabled={this.canSubUserEdit()}
//       //           >
//       //             Action
//       //           </button>
//       //         </Dropdown>
//       //       </div>
//       //     </td>
//       //   )
//       // }
//       //],
//       // Payments: [
//       //   {
//       //     id: "amount",
//       //     label: "Date",
//       //     customCell: (r, i) => {
//       //       if (r.type !== "Invoice") {
//       //         const createdDate = new Date(r.created * 1000);

//       //         const local = DateTime.fromSeconds(r.created);
//       //         const rezoned = local.setZone(
//       //           this.global.organizationInfo.timezone
//       //         );
//       //         return (
//       //           <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//       //         );
//       //       }
//       //       if (r.type === "Invoice") {
//       //         const createdDate = new Date(r.createdAt * 1000);

//       //         const local = DateTime.fromMillis(
//       //           new Date(r.createdAt).getTime()
//       //         );
//       //         const rezoned = local.setZone(
//       //           this.global.organizationInfo.timezone
//       //         );
//       //         return (
//       //           <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//       //         );
//       //       }
//       //     }
//       //   },
//       //   {
//       //     id: "item",
//       //     label: "Item",
//       //     customCell: (r, i) => {
//       //       if (r.type !== "Invoice") {
//       //         return (
//       //           <td className="table-custom-text">
//       //             {r.product_table === "partner_programs" &&
//       //               this.showProgramPlan(r)}
//       //             {r.product_table === "partner_event" && this.showEventPlan(r)}
//       //             {r.product_table === "partner_memberships" &&
//       //               this.showMembershipPlan(r)}
//       //             {r.product_table === "partner_online" &&
//       //               this.showProgramPlan(r)}
//       //           </td>
//       //         );
//       //       }
//       //       if (r.type === "Invoice") {
//       //         return (
//       //           <td className="table-custom-text">
//       //             Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//       //           </td>
//       //         );
//       //       }
//       //     }
//       //   },
//       //   {
//       //     id: "amountOverdue",
//       //     label: "Sub-Total",
//       //     customCell: (r, i) => {
//       //       if (r.type === "Invoice") {
//       //         return (
//       //           <td className="table-custom-text">
//       //             ${parseFloat(r.itemAmount / 100).toFixed(2)}
//       //           </td>
//       //         );
//       //       }
//       //       if (r.type === "Installment") {
//       //         return (
//       //           <td className="table-custom-text">
//       //             ${parseFloat(r.amount / 100).toFixed(2)}
//       //           </td>
//       //         );
//       //       }

//       //       return this.displaySubTotal(r, i);
//       //     }
//       //   },
//       //   // {
//       //   //   id: "tax",
//       //   //   label: "Tax %",
//       //   //   customCell: (r, i) =>this.displayTaxOrders(r)
//       //   // },
//       //   {
//       //     id: "amountOverdue",
//       //     label: "Total", // Both Invoice and orders have the same field
//       //     customCell: (r, i) => {
//       //       if (r.isInstallment) {
//       //         return (
//       //           <td className="table-custom-text">
//       //             ${parseFloat(r.installment_amount).toFixed(2)}
//       //           </td>
//       //         );
//       //       }
//       //       return (
//       //         <td className="table-custom-text">
//       //           ${parseFloat(r.amount / 100).toFixed(2)}
//       //         </td>
//       //       );
//       //     }
//       //   },
//       //   {
//       //     id: "amountOverdue",
//       //     label: "Purchaser",
//       //     customCell: (r, i) => {
//       //       if (r.isInstallment) {
//       //         return <td className="table-custom-text">{r.buyer}</td>;
//       //       }
//       //       if (r.type !== "Invoice") {
//       //         return <td className="table-custom-text">{r.customer_name}</td>;
//       //       }
//       //       if (r.type === "Invoice") {
//       //         return <td className="table-custom-text">{r.displayName}</td>;
//       //       }
//       //     }
//       //   },
//       //   {
//       //     id: "userPaymentMethod",
//       //     label: "Paid Using",
//       //     customCell: (r, i) => {
//       //       if (r.userPaymentMethod) {
//       //         return (
//       //           <td className="table-custom-text">{r.userPaymentMethod}</td>
//       //         );
//       //       } else if (r.manual) {
//       //         return <td className="table-custom-text">Offline</td>;
//       //       } else if (r.stripe) {
//       //         return <td className="table-custom-text">Credit Card</td>;
//       //       } else if (r.stripe_transaction_id?.includes("pi_")) {
//       //         return <td className="table-custom-text">Credit Card</td>;
//       //       } else {
//       //         return <td className="table-custom-text">Offline</td>;
//       //       }
//       //     }
//       //   },
//       //   {
//       //     id: "status",
//       //     label: "Status",
//       //     customCell: (r, i) => {
//       //       if (r.type === "Invoice") {
//       //         return (
//       //           <td className="green">
//       //             <span
//       //               style={{
//       //                 minWidth: "100px",
//       //                 background: "#1bc88a",
//       //                 padding: "3px",
//       //                 borderRadius: "13px",
//       //                 color: "white",
//       //                 display: "flex",
//       //                 justifyContent: "center",
//       //                 alignItems: "center",
//       //                 fontFamily: "Open Sans",
//       //                 fontSize: "12px",
//       //                 fontWeight: 600
//       //               }}
//       //             >
//       //               {r.invoice_status === "Paid Offline"
//       //                 ? `Paid (Manual)`
//       //                 : "Paid"}
//       //             </span>
//       //           </td>
//       //         );
//       //       }

//       //       return this.orderStatus(r, i);
//       //     }
//       //   },
//       //   {
//       //     id: "actio",
//       //     label: "",
//       //     customStyle: { width: 80 },
//       //     customCell: (r, i) => (
//       //       <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//       //         {/* {r.manual && !r.paid && (
//       //           <button
//       //             onClick={_ => this.markAsPaid(r)}
//       //             className="table-action-button"
//       //           >
//       //             Mark as Paid
//       //           </button>
//       //         )} */}
//       //         {/* <button className="table-action-button">VIEW</button> */}

//       //         <div
//       //         // style={{
//       //         //   position: "absolute",
//       //         //   right: "15px",
//       //         //   top: "5px"
//       //         // }}
//       //         >
//       //           <Dropdown
//       //             trigger={["click"]}
//       //             overlay={
//       //               r.type === "Invoice"
//       //                 ? this.invoiceMenu(r, i)
//       //                 : this.menu(r, i)
//       //             }
//       //             animation="slide-up"
//       //           >
//       //             <button
//       //               className="table-action-button"
//       //               style={{
//       //                 cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//       //               }}
//       //               disabled={this.canSubUserEdit()}
//       //             >
//       //               Action
//       //             </button>
//       //           </Dropdown>
//       //         </div>
//       //       </td>
//       //     )
//       //   }
//       // ]
//     };
//   };

//   // oldheadings = () => {
//   //   return {
//   //     Orders: [
//   //       {
//   //         id: "amount",
//   //         label: "Date",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             const createdDate = new Date(r.created * 1000);

//   //             const local = DateTime.fromSeconds(r.created);
//   //             const rezoned = local.setZone(
//   //               this.global.organizationInfo.timezone
//   //             );
//   //             return (
//   //               <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//   //             );
//   //           }
//   //           if (r.type === "Invoice") {
//   //             const createdDate = new Date(r.createdAt * 1000);

//   //             const local = DateTime.fromSeconds(
//   //               new Date(r.createdAt).getTime()
//   //             );
//   //             const rezoned = local.setZone(
//   //               this.global.organizationInfo.timezone
//   //             );
//   //             return (
//   //               <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//   //             );
//   //           }
//   //         }
//   //       },
//   //       {
//   //         id: "item",
//   //         label: "Item",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             return (
//   //               <td className="table-custom-text">
//   //                 {r.product_table === "partner_programs" &&
//   //                   this.showProgramPlan(r)}
//   //                 {r.product_table === "partner_event" && this.showEventPlan(r)}
//   //                 {r.product_table === "partner_memberships" &&
//   //                   this.showMembershipPlan(r)}
//   //                 {r.product_table === "partner_online" &&
//   //                   this.showProgramPlan(r)}
//   //               </td>
//   //             );
//   //           }
//   //           if (r.type === "Invoice") {
//   //             return (
//   //               <td className="table-custom-text">
//   //                 Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//   //               </td>
//   //             );
//   //           }
//   //         }
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Sub-Total",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             this.displaySubTotal(r, i);
//   //           }
//   //           if (r.type === "Invoice") {
//   //             return (
//   //               <td className="table-custom-text">
//   //                 ${parseFloat(r.itemAmount / 100).toFixed(2)}
//   //               </td>
//   //             );
//   //           }
//   //         }
//   //       },
//   //       // {
//   //       //   id: "tax",
//   //       //   label: "Tax %",
//   //       //   customCell: (r, i) =>this.displayTaxOrders(r)
//   //       // },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Total", // Both Invoice and orders have the same field
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             ${parseFloat(r.amount / 100).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Purchaser",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             return <td className="table-custom-text">{r.customer_name}</td>;
//   //           }
//   //           if (r.type === "Invoice") {
//   //             return <td className="table-custom-text">{r.displayName}</td>;
//   //           }
//   //         }
//   //       },
//   //       {
//   //         id: "status",
//   //         label: "Status",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             return this.orderStatus;
//   //           }
//   //           if (r.type === "Invoice") {
//   //             return <td className="table-custom-text">{r.invoice_status}</td>;
//   //           }
//   //         }
//   //       },
//   //       {
//   //         id: "actio",
//   //         label: "",
//   //         customStyle: { width: 80 },
//   //         customCell: (r, i) => (
//   //           <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//   //             {/* {r.manual && !r.paid && (
//   //               <button
//   //                 onClick={_ => this.markAsPaid(r)}
//   //                 className="table-action-button"
//   //               >
//   //                 Mark as Paid
//   //               </button>
//   //             )} */}
//   //             {/* <button className="table-action-button">VIEW</button> */}

//   //             <div
//   //             // style={{
//   //             //   position: "absolute",
//   //             //   right: "15px",
//   //             //   top: "5px"
//   //             // }}
//   //             >
//   //               <Dropdown
//   //                 trigger={["click"]}
//   //                 overlay={this.menu(r, i)}
//   //                 animation="slide-up"
//   //               >
//   //                 <button
//   //                   className="table-action-button"
//   //                   style={{
//   //                     cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//   //                   }}
//   //                   disabled={this.canSubUserEdit()}
//   //                 >
//   //                   Action
//   //                 </button>
//   //               </Dropdown>
//   //             </div>
//   //           </td>
//   //         )
//   //       }
//   //     ],
//   //     "Installments Orders": [
//   //       {
//   //         id: "amount",
//   //         label: "Date",
//   //         customCell: (r, i) => {
//   //           const createdDate = new Date(r.created * 1000);
//   //           const local = DateTime.fromSeconds(r.created);
//   //           const rezoned = local.setZone(
//   //             this.global.organizationInfo.timezone
//   //           );

//   //           return (
//   //             <td className="table-custom-text">
//   //               {/* {format(
//   //                 new Date(
//   //                   createdDate.getTime() +
//   //                   Math.abs(createdDate.getTimezoneOffset() * 60000)
//   //                 ),
//   //                 "LLL dd, yyyy"
//   //               )} */}
//   //               {rezoned.toFormat("DD")}
//   //             </td>
//   //           );
//   //         }
//   //       },
//   //       // {
//   //       //   id: "tax",
//   //       //   label: "Tax %",
//   //       //   customCell: (r, i) =>this.displayTaxInstallments(r)
//   //       // },
//   //       {
//   //         id: "amountPaid",
//   //         label: "Amnt Paid",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             $
//   //             {parseFloat(
//   //               r.payments.reduce((p, c) => p + parseFloat(c.amount), 0) //  * 1.13
//   //             ).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountPaid",
//   //         label: "Installment #",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             {r.payments.length} of {r.no_payments} payments
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountPaid",
//   //         label: "Amnt Remaining",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             $
//   //             {parseFloat(
//   //               r.installment_amount - // * 1.13
//   //                 r.payments.reduce(
//   //                   (p, c) => p + parseFloat(c.amount), // * 1.13
//   //                   0
//   //                 )
//   //             ).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         //  * 1.13
//   //         id: "amountOverdue",
//   //         label: "Total",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             ${parseFloat(r.installment_amount).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Purchaser",
//   //         customCell: (r, i) => <td className="table-custom-text">{r.buyer}</td>
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Status",
//   //         customCell: (row, i) => (
//   //           <td key={`id-${i}`}>
//   //             <span className="green table-custom-text">
//   //               Paid{" "}
//   //               {row.payments[row.payments.length - 1].invoice.charge
//   //                 .amount_refunded !== 0 && "(Refunded)"}
//   //             </span>
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "actio",
//   //         label: "",
//   //         customStyle: { width: 80 },
//   //         customCell: (r, i) => (
//   //           <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//   //             <div>
//   //               <Dropdown
//   //                 trigger={["click"]}
//   //                 overlay={this.menu(r, i)}
//   //                 animation="slide-up"
//   //               >
//   //                 <button
//   //                   className="table-action-button"
//   //                   style={{
//   //                     cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//   //                   }}
//   //                   disabled={this.canSubUserEdit()}
//   //                 >
//   //                   Action
//   //                 </button>
//   //               </Dropdown>
//   //             </div>
//   //           </td>
//   //         )
//   //       }
//   //     ],
//   //     Invoices: [
//   //       {
//   //         id: "amount",
//   //         label: "Date",
//   //         customCell: (r, i) => {
//   //           const createdDate = new Date(r.createdAt * 1000);

//   //           const local = DateTime.fromSeconds(new Date(r.createdAt).getTime());
//   //           const rezoned = local.setZone(
//   //             this.global.organizationInfo.timezone
//   //           );
//   //           return (
//   //             <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//   //           );
//   //         }
//   //       },
//   //       {
//   //         id: "item",
//   //         label: "Item",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Sub-Total",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             ${parseFloat(r.itemAmount / 100).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       // {
//   //       //   id: "tax",
//   //       //   label: "Tax %",
//   //       //   customCell: (r, i) =>this.displayTaxOrders(r)
//   //       // },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Total",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             ${parseFloat(r.amount / 100).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Purchaser",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">{r.displayName}</td>
//   //         )
//   //       },
//   //       {
//   //         id: "status",
//   //         label: "Status",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">{r.invoice_status}</td>
//   //         )
//   //       },
//   //       {
//   //         id: "actio",
//   //         label: "",
//   //         customStyle: { width: 80 },
//   //         customCell: (r, i) => (
//   //           <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//   //             <div>
//   //               <Dropdown
//   //                 trigger={["click"]}
//   //                 overlay={this.invoiceMenu(r, i)}
//   //                 animation="slide-up"
//   //               >
//   //                 <button
//   //                   className="table-action-button"
//   //                   style={{
//   //                     cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//   //                   }}
//   //                   disabled={this.canSubUserEdit()}
//   //                 >
//   //                   Action
//   //                 </button>
//   //               </Dropdown>
//   //             </div>
//   //           </td>
//   //         )
//   //       }
//   //     ]
//   //   };
//   // };

//   orderStatus = (row, i) => {
//     const isRefunded =
//       !row.amount_refunded || row.amount_refunded === 0 ? false : true;

//     return (
//       <td key={`id-${i}`}>
//         {row.paid ? (
//           <span
//             className="green"
//             style={{
//               minWidth: "100px",
//               background: "#1bc88a",
//               padding: "3px",
//               borderRadius: "13px",
//               color: "white",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               fontFamily: "Open Sans",
//               fontSize: "12px",
//               fontWeight: 600
//             }}
//           >
//             Paid{" "}
//             {/* {row.manual &&
//               !row.is_manual_stripe &&
//               row.amount != 0 &&
//               "(Manual)"} */}
//             {isRefunded && (
//               <span>{row.amount_refunded === 0 ? "" : " (Refunded)"}</span>
//             )}
//           </span>
//         ) : (
//           <div style={{ textAlign: "right", display: "flex" }}>
//             <span
//               className="red"
//               style={{
//                 minWidth: "100px",
//                 background: "rgb(33 91 255)",
//                 padding: "3px",
//                 borderRadius: "13px",
//                 color: "white",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 fontFamily: "Open Sans",
//                 fontSize: "12px",
//                 fontWeight: 600
//               }}
//             >
//               {row.status === "Processing" ? "Processing" : "Pending"}
//             </span>
//           </div>
//         )}
//         {/* {isRefunded && (
//           <span>{row.amount_refunded === 0 ? "" : "(Refunded)"}</span>
//         )} */}
//       </td>
//     );
//   };

//   /**
//    * This function handles the edit button functionality of a sub user
//    * returns a boolean value
//    */
//   canSubUserEdit = () => {
//     if (!this.global.dsUser.accesspoints) {
//       return false;
//     }
//     if (this.global.dsUser.accesspoints) {
//       return this.global.dsUser.accesspoints.Financials["Paid Orders"].edit
//         ? false
//         : true;
//     }
//   };

//   displaySubTotal = (r, i) => {
//     const tableName = r.product_table;
//     if (tableName === "partner_programs") {
//       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
//         ? JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage
//         : 0;

//       const plan = JSON.parse(r.product.plans[r.planId]);

//       const price = !plan.type
//         ? parseFloat(plan.tuition_rate)
//         : r.drop_in_dates
//         ? (plan.total_price / plan.dayCount) *
//           r.selectedDays.length *
//           (1 + parseInt(plan.dropin_interest) / 100)
//         : parseFloat(plan.total_price) *
//           (r.session_quantity ? r.session_quantity : 1);
//       // console.log('price', price);
//       let couponDiscount = 0;
//       // Coupon Applied
//       if (r.coupon) {
//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(price) * (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }
//       if (r.drop_in_dates && r.coupon) {
//         const plan = JSON.parse(r.product.plans[r.planId]);

//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
//                   (1 + parseInt(plan.dropin_interest) / 100) *
//                   (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }

//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           ${parseFloat(price - couponDiscount).toFixed(2)}
//         </td>
//       );
//     } else if (tableName === "partner_event") {
//       const taxPercentage = JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
//         ? JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage
//         : 0;

//       const plan = JSON.parse(r.product.tickets[r.planId]);
//       const ticketPrice = parseFloat(plan.ticket_price);
//       let couponDiscount = 0;
//       // Coupon Applied
//       if (r.coupon) {
//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(ticketPrice) *
//                   (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }

//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           ${parseFloat(ticketPrice - couponDiscount).toFixed(2)}
//         </td>
//       );
//     } else if (tableName === "partner_memberships") {
//       const taxPercentage = JSON.parse(r.product.prices[r.planId]).isTaxEnabled
//         ? JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage
//         : 0;

//       const plan = JSON.parse(r.product.prices[r.planId]);
//       const pricePerTerm = parseFloat(plan.price_per_term);
//       let couponDiscount = 0;
//       // Coupon Applied
//       if (r.coupon) {
//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(pricePerTerm) *
//                   (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           ${parseFloat(pricePerTerm - couponDiscount).toFixed(2)}
//         </td>
//       );
//     } else if (tableName === "partner_online") {
//       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
//         ? JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage
//         : 0;

//       const plan = JSON.parse(r.product.plans[r.planId]);

//       const price = !plan.type
//         ? parseFloat(plan.tuition_rate)
//         : r.drop_in_dates
//         ? (plan.total_price / plan.dayCount) *
//           r.selectedDays.length *
//           (1 + parseInt(plan.dropin_interest) / 100)
//         : parseFloat(plan.total_price) *
//           (r.session_quantity ? r.session_quantity : 1);

//       let couponDiscount = 0;
//       // Coupon Applied
//       if (r.coupon) {
//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(price) * (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }

//       if (r.drop_in_dates && r.coupon) {
//         const plan = JSON.parse(r.product.plans[r.planId]);

//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
//                   (1 + parseInt(plan.dropin_interest) / 100) *
//                   (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }

//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           ${parseFloat(price - couponDiscount).toFixed(2)}
//         </td>
//       );
//     }
//   };

//   displayTaxOrders = (r, i) => {
//     const tableName = r.product_table;
//     if (tableName === "partner_programs") {
//       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
//         ? `${JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage}%`
//         : "0%";
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           {taxPercentage}
//         </td>
//       );
//     } else if (tableName === "partner_event") {
//       const taxPercentage = JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
//         ? `${JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage}%`
//         : "0%";
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           {taxPercentage}
//         </td>
//       );
//     } else if (tableName === "partner_memberships") {
//       const taxPercentage = JSON.parse(r.product.prices[r.planId]).isTaxEnabled
//         ? `${JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage}%`
//         : "0%";
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           {taxPercentage}
//         </td>
//       );
//     } else if (tableName === "partner_online") {
//       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
//         ? `${JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage}%`
//         : "0%";
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           {taxPercentage}
//         </td>
//       );
//     }
//   };

//   displayTaxInstallments = (r, i) => {
//     const taxPercentage = r.payments[0].invoice.tax_percent
//       ? r.payments[0].invoice.tax_percent
//       : 0;

//     return (
//       <td
//         key={i}
//         style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//       >
//         {taxPercentage}%
//       </td>
//     );
//   };

//   onSelect = async (r, i, event) => {
//     if (event.key === "MarkPaid") {
//       this.markAsPaid(r);
//     }
//     if (event.key === "MarkUnpaid") {
//       this.markAsUnpaid(r);
//     }
//     if (event.key === "refund") {
//       if (r.installment_amount) {
//         this.setState({
//           openRefundModal: true,
//           selectedItem: r.payments[r.payments.length - 1].invoice,
//           usage: "recurring_payments"
//         });
//         return;
//       }
//       this.setState({
//         openRefundModal: true,
//         selectedItem: r,
//         usage: "paid_orders"
//       });
//     }
//     if (event.key === "emailClient") {
//       this.setState({ openEmailClientModal: true, selectedItem: r });
//     }

//     if (event.key === "editInvoice") {
//       console.log("EDIT DETAILS", r);
//       this.props.history.push({
//         pathname: `/partners/orders/invoice-builder/${r.id}`,
//         state: { isNewCustomer: false }
//       });
//     }

//     if (event.key === "viewDetails") {
//       console.log("VIEW DETAILS", r);
//       this.props.history.push(`orders/orders-receipt/${r.orderId}`);
//       // this.setState({ openViewDetailsModal: true, selectedItem: r });
//     }
//   };

//   menu = (r, i) => {
//     return (
//       <Menu onClick={event => this.onSelect(r, i, event)}>
//         {r.manual && !r.is_manual_stripe && !r.paid && (
//           <MenuItem key="MarkPaid">Mark as Paid</MenuItem>
//         )}
//         {r.manual && !r.is_manual_stripe && r.paid && (
//           <MenuItem key="MarkUnpaid">Mark as Unpaid</MenuItem>
//         )}
//         {!r.manual && r.status !== "Processing" && (
//           <MenuItem key="refund">Refund</MenuItem>
//         )}
//         {r.manual && r.status !== "Processing" && r.is_manual_stripe && (
//           <MenuItem key="refund">Refund</MenuItem>
//         )}
//         {!r.manual && <MenuItem key="emailClient">Email Client</MenuItem>}
//         {r.invoice_status === "Issued" &&
//         r.is_manual_stripe &&
//         r.status !== "Paid" ? (
//           <MenuItem key="editInvoice">Edit Invoice</MenuItem>
//         ) : (
//           <MenuItem key="viewDetails">View Details</MenuItem>
//         )}
//       </Menu>
//     );
//   };

//   onInvoiceSelect = async (r, i, event) => {
//     if (event.key === "viewDetails") {
//       console.log("VIEW DETAILS", r);

//       this.props.history.push({
//         pathname: `orders/invoice-builder/${r.id}`,
//         state: { isNewCustomer: false }
//       });
//     }

//     if (event.key === "refund") {
//       if (r.installment_amount) {
//         this.setState({
//           openRefundModal: true,
//           selectedItem: r.payments[r.payments.length - 1].invoice,
//           usage: "recurring_payments"
//         });
//         return;
//       }

//       this.setState({
//         openRefundModal: true,
//         selectedItem: r,
//         usage: "paid_orders"
//       });
//     }

//     if (event.key === "delete-invoice") {
//       this.setState({
//         openDeleteInvoiceConfirmationModal: true,
//         selectedItem: r,
//         usage: "paid_orders"
//       });
//     }
//   };

//   invoiceMenu = (r, i) => {
//     return (
//       <Menu onClick={event => this.onInvoiceSelect(r, i, event)}>
//         {/* {r.manual && !r.paid && (
//           <MenuItem key="MarkPaid">Mark as Paid</MenuItem>
//         )}
//         {r.manual && r.paid && (
//           <MenuItem key="MarkUnpaid">Mark as Unpaid</MenuItem>
//         )}
//         {!r.manual && <MenuItem key="emailClient">Email Client</MenuItem>} */}
//         {!r.userPaymentMethod === "Credit Card" && (
//           <MenuItem key="refund">Refund</MenuItem>
//         )}
//         <MenuItem key="viewDetails">View Details</MenuItem>
//         {r.tab === "Pending" &&
//           r.invoice_status !== "Issued" &&
//           r.status !== "Processing" && (
//             <MenuItem key="delete-invoice">Delete Invoice</MenuItem>
//           )}
//       </Menu>
//     );
//   };

//   showProgramPlan = r => {
//     // if (r.planId === null) {
//     //   return null;
//     // }
//     if (r.plan === null) {
//       return null;
//     }
//     // const plan = JSON.parse(r.product.plans[r.planId]);
//     const plan = r.plan;
//     const objConv = {
//       week: "Weekly",
//       "bi-week": "Bi-Weekly",
//       month: "Monthly"
//     };
//     return (
//       <span>
//         {plan.type
//           ? r.drop_in_dates
//             ? `Semester Drop-in - $${parseFloat(
//                 (plan.total_price / plan.dayCount) *
//                   (1 + parseInt(plan.dropin_interest) / 100)
//               ).toFixed(0)}`
//             : plan.installments
//             ? `Semester - Inst - $${parseFloat(
//                 (plan.total_price / plan.installments_breakdown) *
//                   (1 + parseInt(plan.installments_interest) / 100)
//               ).toFixed(0)} - ${plan.installments_plan}`
//             : plan.type === "Single Sessions"
//             ? `Single Sessions - $${plan.total_price} x(${r.session_quantity})`
//             : `Semester - $${plan.total_price}`
//           : `Recurring - $${plan.tuition_rate} / ${
//               objConv[plan.billing_cycle]
//             }, \n ${plan.timing[0]} to ${plan.timing[1]}`}
//       </span>
//     );
//   };

//   showEventPlan = r => {
//     const plan = JSON.parse(r.product.tickets[r.planId]);
//     const ticketName =
//       plan.ticket_status === "Paid" ? "Standard" : plan.ticket_status;
//     const ticketPrice = plan.ticket_price ? `$${plan.ticket_price}` : "Free";
//     return (
//       <span>
//         {ticketName} Ticket / {ticketPrice}
//       </span>
//     );
//   };

//   showMembershipPlan = r => {
//     const plan = JSON.parse(r.product.prices[r.planId]);
//     const membershipTerm = plan.membership_term;
//     const renewalStatus = plan.auto_renew
//       ? "Auto Renews"
//       : "Standard Membership";
//     const pricePerTerm = plan.price_per_term;
//     return (
//       <span>
//         {membershipTerm} Month Term - {renewalStatus} - {pricePerTerm}
//       </span>
//     );
//   };

//   amountCell = r => {
//     return (
//       <td key={`${r.id}-amt`} className="table-custom-text">
//         ${parseFloat(r.amount).toFixed(2)}
//       </td>
//     );
//   };

//   servicePeriodCell = r => {
//     if (!r.servicePeriodStart) return <td key={`${r.id}-sp`}>N/A</td>;
//     return (
//       <td key={`${r.id}-sp`} className="table-custom-text">
//         {format(new Date(r.servicePeriodStart), "LLL d, yyyy")} -{" "}
//         {format(new Date(r.servicePeriodEnd), "LLL d, yyyy")}
//       </td>
//     );
//   };

//   customDueDate = r => {
//     return (
//       <td key={`${r.id}-dd`} className="table-custom-text">
//         {format(new Date(r.dueDate), "LLL d, yyyy")}
//       </td>
//     );
//   };

//   async markAsPaid(r) {
//     const ep = `${process.env.REACT_APP_API}/partners/orders/mark_paid`;
//     const res = await axios.put(ep, r);
//     if (res.data.success) {
//       this.fetchInitialData();
//       // this.setState({
//       //   data: {
//       //     ...this.state.data,
//       //     Orders: this.state.data.Orders.map(e =>
//       //       e.id === r.id ? { ...e, paid: true } : e
//       //     )
//       //   }
//       // });
//     }
//   }

//   async markAsUnpaid(r) {
//     const ep = `${process.env.REACT_APP_API}/partners/orders/mark_unpaid`;
//     const res = await axios.put(ep, r);

//     if (res.data.success) {
//       this.fetchInitialData();
//     }
//   }

//   async componentDidMount() {
//     this.fetchInitialData();

//     // Testing Using Dummy Data:
//     // this.setState({
//     //   data: {
//     //     Orders: [
//     //       {
//     //         amount: 62150,
//     //         created: 1597248282,
//     //         customer_name: "test",
//     //         id: 50,
//     //         manual: true,
//     //         paid: false,
//     //         status: null
//     //       }
//     //     ]
//     //   }
//     // });
//   }

//   fetchInitialData = async () => {
//     this.setGlobal({ loading: true });
//     const ep = `${process.env.REACT_APP_API}/partners/orders`;
//     const res = await axios.get(ep);

//     console.log("res", res.data);
//     if (res.data.success) {
//       const ordersInvoicePaid = res.data.data.Invoices.invoices
//         .filter(e => e.status === "Paid")
//         .map(e => {
//           let item = e;
//           item.created = new Date(e.createdAt).getTime();
//           item.tab = "Payments";
//           return item;
//         });
//       const ordersInvoicePending = res.data.data.Invoices.invoices
//         .filter(e => e.status !== "Paid")
//         .map(e => {
//           let item = e;
//           item.created = new Date(e.createdAt).getTime();
//           item.tab = "Pending";
//           return item;
//         });
//       let orderChargesPaid =
//         res.data.data.AllPayouts?.charges
//           ?.filter(e => e.paid || e.status === "Processing")
//           .sort((a, b) => b.created - a.created) || [];
//       if (orderChargesPaid.length > 0) {
//         orderChargesPaid.tab = "Payments";
//       }
//       let orderChargesPending =
//         res.data.data.AllPayouts?.charges
//           ?.filter(
//             e => !e.paid && !(e.status === "Processing") && e.status !== null
//           )
//           .sort((a, b) => b.created - a.created) || [];
//       console.log("orderChargesPending", orderChargesPending);
//       console.log("orderChargesPaid", orderChargesPaid);
//       if (orderChargesPending.length > 0) {
//         orderChargesPending.tab = "Payments";
//       }
//       let stripeInstallments = res.data.data.AllInstallments
//         ? res.data.data.AllInstallments?.charges?.sort(
//             (a, b) => b.created - a.created
//           ) || []
//         : [];
//       if (stripeInstallments.length > 0) {
//         stripeInstallments.tab = "Payments";
//       }

//       const payments = [
//         ...orderChargesPaid,
//         ...ordersInvoicePaid,
//         ...stripeInstallments
//       ].sort((a, b) => new Date(b.created * 1000) - new Date(a.created * 1000));
//       const pending = [...ordersInvoicePending, ...orderChargesPending].sort(
//         (a, b) => new Date(b.created * 1000) - new Date(a.created * 1000)
//       );

//       console.log({
//         data: {
//           Pending: pending,
//           Payments: payments
//           // Orders: res.data.data.AllPayouts
//           //   ? res.data.data.AllPayouts?.charges?.sort(
//           //       (a, b) => b.created - a.created
//           //     ) || []
//           //   : [],
//           // "Installments Orders": res.data.data.AllInstallments
//           //   ? res.data.data.AllInstallments?.charges?.sort(
//           //       (a, b) => b.created - a.created
//           //     ) || []
//           //   : [],
//           // Invoices: res.data.data.Invoices.sort(
//           //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
//           // )
//         },
//         earnings:
//           orderChargesPaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0) +
//           stripeInstallments.reduce(
//             (p, c) => p + parseFloat(c.installment_amount), //  * 1.13
//             0
//           ) +
//           ordersInvoicePaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0)
//       });
//       this.setState({
//         data: {
//           Pending: pending,
//           Payments: payments
//           // Orders: res.data.data.AllPayouts
//           //   ? res.data.data.AllPayouts?.charges?.sort(
//           //       (a, b) => b.created - a.created
//           //     ) || []
//           //   : [],
//           // "Installments Orders": res.data.data.AllInstallments
//           //   ? res.data.data.AllInstallments?.charges?.sort(
//           //       (a, b) => b.created - a.created
//           //     ) || []
//           //   : [],
//           // Invoices: res.data.data.Invoices.sort(
//           //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
//           // )
//         },
//         earnings:
//           orderChargesPaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0) +
//           stripeInstallments.reduce(
//             (p, c) => p + parseFloat(c.installment_amount), //  * 1.13
//             0
//           ) +
//           ordersInvoicePaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0)
//       });
//     }
//     this.setGlobal({ loading: false });
//   };

//   render() {
//     console.log("this.props", this.props);

//     return (
//       <>
//         {this.state.openCreateCustomerModal && (
//           <CreateCustomerModal
//             createNewCustomer={_ =>
//               this.setState({ openCreateCustomerModal: true })
//             }
//             onClose={_ => {
//               this.setState({ openCreateCustomerModal: false });
//             }}
//           />
//         )}
//         {this.state.openInvoiceCustomerModal && (
//           <InvoiceCustomerModal
//             createNewCustomer={_ =>
//               this.setState({ openCreateCustomerModal: true })
//             }
//             onClose={_ => {
//               this.setState({ openInvoiceCustomerModal: false });
//             }}
//           />
//         )}
//         {this.state.openViewDetailsModal && (
//           <PaidOrdersModal
//             selectedItem={this.state.selectedItem}
//             onClose={_ => {
//               this.setState({ openViewDetailsModal: false });
//             }}
//           />
//         )}
//         {this.state.openRefundModal && (
//           <RefundModal
//             selectedItem={this.state.selectedItem}
//             amountRefunded={
//               this.state.usage === "paid_orders"
//                 ? this.state.selectedItem.amount_refunded
//                 : this.state.selectedItem.charge.amount_refunded
//             }
//             post_payment_credit_notes_amount={
//               this.state.selectedItem.invoice
//                 ? this.state.selectedItem.invoice
//                     .post_payment_credit_notes_amount
//                 : this.state.selectedItem.post_payment_credit_notes_amount
//             }
//             onClose={_ => {
//               // this.fetchInitialData();
//               this.fetchInitialData();
//               this.setState({ openRefundModal: false });
//             }}
//             usage={this.state.usage}
//           />
//         )}
//         {this.state.openEmailClientModal && (
//           <EmailClientModal
//             selectedItem={this.state.selectedItem}
//             charge={
//               this.state.selectedItem.id ||
//               this.state.selectedItem.payments[
//                 this.state.selectedItem.payments.length - 1
//               ].invoice.charge
//             }
//             onClose={_ => {
//               // this.fetchInitialData();
//               this.setState({ openEmailClientModal: false });
//             }}
//           />
//         )}
//         {this.state.openDeleteInvoiceConfirmationModal && (
//           <DeleteInvoiceConfirmationModal
//             selectedItem={this.state.selectedItem}
//             confirmDelete={_ => this.fetchInitialData()}
//             onClose={_ => {
//               // this.fetchInitialData();
//               this.setState({ openDeleteInvoiceConfirmationModal: false });
//             }}
//           />
//         )}
//         <div className="container-fluid">
//           <div className="row cont">
//             <div className="col-md-6">
//               <h1>Order Tracking</h1>
//             </div>

//             <div className="col-md-6">
//               <div className="forbtnwrap justify-end">
//                 <div
//                   className="forbtnapp enrollment-btn"
//                   style={{ marginLeft: "20px" }}
//                 >
//                   <button
//                     className="newapplications"
//                     style={{
//                       cursor: "pointer"
//                     }}
//                     onClick={_ => {
//                       this.setState({ openInvoiceCustomerModal: true });
//                     }}
//                   >
//                     <img
//                       src={MiniPlus}
//                       alt=""
//                       style={{ alignSelf: "center" }}
//                     />
//                     Invoice
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="cont">
//             <div className="row">
//               <div className="col-lg-3">
//                 <div className="money-box mt-0">
//                   <div className="row">
//                     <div className="col-lg-12 col-sm-6">
//                       <div className="money-box-inner">
//                         <h2>ORDER EARNINGS</h2>
//                         <h3>
//                           {parseFloat(this.state.earnings || 0).toLocaleString(
//                             "en-US",
//                             {
//                               style: "currency",
//                               currency: "USD"
//                             }
//                           )}
//                         </h3>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-sm-6">
//                       <div className="money-box-inner">
//                         <h2>TOTAL ORDERS</h2>
//                         <h3>
//                           {this.state.data.Payments.length +
//                             this.state.data["Pending"].length}
//                         </h3>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-sm-6">
//                       <div className="money-box-inner">
//                         <h2>PAID ORDERS</h2>
//                         <h3>{this.state.data.Payments.length}</h3>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-sm-6">
//                       <div className="money-box-inner">
//                         <h2>PAYMENTS OWED</h2>
//                         <h3>{this.state.data.Pending.length}</h3>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-9">
//                 <NewTabbedTable
//                   data={this.state.data}
//                   tabs={this.tabs}
//                   headings={this.headings()}
//                   actions={this.actions}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default withRouter(TuitionPayments);

// import React from "reactn";
// // import Tables from "../../components/table";
// import axios from "axios";
// import { NewTabbedTable } from "../../components/UI";
// // import { AmountArrow } from "../../assets";
// import {
//   format
//   // addWeeks
// } from "date-fns";
// // import PaymentDetailsModal from "../../components/modals/PaymentDetailsModal";
// // import { Link } from "react-router-dom";
// import "../../assets/css/componentSpecificCss/newTabbedTable.css";
// import Dropdown from "rc-dropdown";
// import Menu, { Item as MenuItem } from "rc-menu";
// import RefundModal from "../../components/modals/RefundModal";
// import EmailClientModal from "../../components/modals/EmailClientModal";
// import { DateTime } from "luxon";
// import PaidOrdersModal from "../../components/modals/PaidOrdersModal";
// import InvoiceCustomerModal from "../../components/modals/InvoiceCustomerModal";
// import CreateCustomerModal from "../../components/modals/CreateCustomerModal";
// import DeleteInvoiceConfirmationModal from "../../components/modals/DeleteInvoiceConfirmationModal";

// import { MiniPlus } from "../../assets";
// import { withRouter } from "react-router-dom";

// class TuitionPayments extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       data: {
//         Orders: [],
//         "Installments Orders": [],
//         Invoices: [],
//         Pending: [],
//         Payments: []
//       },
//       counts: {
//         overdue: 0,
//         paid: 0,
//         pending: 0,
//         total: 0
//       },
//       selectedStudent: null,
//       selectedName: null,
//       selectedData: {
//         data: [],
//         counts: {
//           overdue: 0,
//           paid: 0,
//           pending: 0,
//           total: 0
//         }
//       },
//       selectedInvoice: null,
//       openRefundModal: false,
//       openEmailClientModal: false,
//       selectedItem: null,
//       usage: "paid_orders",
//       openViewDetailsModal: false
//     };
//     this.actions = [{ name: "VIEW", action: _ => true }];
//     this.tabs = ["Pending", "Payments"];

//     // this.tabs = ["Orders", "Installments Orders", "Invoices"];
//     this.setGlobal({
//       partnerPathway: [
//         ...this.global.partnerPathway.slice(0, 1),
//         { label: "Order Tracking", to: "/orders" }
//       ]
//     });
//   }

//   headings = () => {
//     return {
//       Pending: [
//         {
//           id: "amount",
//           label: "Date",
//           customCell: (r, i) => {
//             if (r.type !== "Invoice") {
//               const createdDate = new Date(r.created * 1000);

//               const local = DateTime.fromSeconds(r.created);
//               const rezoned = local.setZone(
//                 this.global.organizationInfo.timezone
//               );
//               return (
//                 <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//               );
//             }
//             if (r.type === "Invoice") {
//               const createdDate = new Date(r.createdAt * 1000);

//               const local = DateTime.fromMillis(
//                 new Date(r.createdAt).getTime()
//               );
//               const rezoned = local.setZone(
//                 this.global.organizationInfo.timezone
//               );
//               return (
//                 <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//               );
//             }
//           }
//         },
//         {
//           id: "item",
//           label: "Item",
//           customCell: (r, i) => {
//             if (r.type !== "Invoice") {
//               return (
//                 <td className="table-custom-text">
//                   {r.product_table === "partner_programs" &&
//                     this.showProgramPlan(r)}
//                   {r.product_table === "partner_event" && this.showEventPlan(r)}
//                   {r.product_table === "partner_memberships" &&
//                     this.showMembershipPlan(r)}
//                   {r.product_table === "partner_online" &&
//                     this.showProgramPlan(r)}
//                 </td>
//               );
//             }
//             if (r.type === "Invoice") {
//               return (
//                 <td className="table-custom-text">
//                   Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//                 </td>
//               );
//             }
//           }
//         },
//         {
//           id: "amountOverdue",
//           label: "Sub-Total",
//           customCell: (r, i) => {
//             if (r.type === "Invoice") {
//               return (
//                 <td className="table-custom-text">
//                   ${parseFloat(r.itemAmount / 100).toFixed(2)}
//                 </td>
//               );
//             }
//             return this.displaySubTotal(r, i);
//           }
//         },
//         // {
//         //   id: "tax",
//         //   label: "Tax %",
//         //   customCell: (r, i) =>this.displayTaxOrders(r)
//         // },
//         {
//           id: "amountOverdue",
//           label: "Total", // Both Invoice and orders have the same field
//           customCell: (r, i) => (
//             <td className="table-custom-text">
//               ${parseFloat(r.amount / 100).toFixed(2)}
//             </td>
//           )
//         },
//         {
//           id: "amountOverdue",
//           label: "Purchaser",
//           customCell: (r, i) => {
//             if (r.type !== "Invoice") {
//               return <td className="table-custom-text">{r.customer_name}</td>;
//             }
//             if (r.type === "Invoice") {
//               return <td className="table-custom-text">{r.displayName}</td>;
//             }
//           }
//         },
//         {
//           id: "status",
//           label: "Status",
//           customCell: (r, i) => {
//             if (r.type === "Invoice") {
//               let color = "#eba93e";
//               let text = "Ready to Bill";
//               switch (r.invoice_status) {
//                 case "Draft":
//                   text = "Draft";
//                   color = "rgb(33 91 255)";
//                   break;

//                 case "Issued":
//                   text = "Issued";
//                   color = "rgba(92 200 220)";
//                   break;

//                 case "Ready to Bill":
//                   text = "Ready to Bill";
//                   color = "#eba93e";
//                   break;

//                 case "Cancelled":
//                   text = "Cancelled";
//                   color = "#ff3b8e";
//                   break;

//                 case "Paid Offline":
//                   text = "Paid Offline";
//                   color = "#1bc88a";
//                   break;

//                 default:
//                   break;
//               }

//               if (
//                 r.invoice_status === "Issued" &&
//                 new Date() > new Date(r.due_date)
//               ) {
//                 // return <td className="table-custom-text">Late</td>;
//                 text = "Late";
//                 color = "#ff3b8e";
//               }

//               if (r.status === "Processing") {
//                 text = "Processing";
//                 color = "rgb(33 91 255)";
//               }

//               return (
//                 <td>
//                   <div
//                     style={{
//                       textAlign: "right",
//                       display: "flex"
//                     }}
//                   >
//                     <span
//                       className="red"
//                       style={{
//                         minWidth: "100px",
//                         background: color,
//                         padding: "3px",
//                         borderRadius: "13px",
//                         color: "white",
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         fontFamily: "Open Sans",
//                         fontSize: "12px",
//                         fontWeight: 600
//                       }}
//                     >
//                       {text}
//                     </span>
//                   </div>
//                 </td>
//               );
//             }
//             return this.orderStatus(r, i);
//           }
//         },
//         {
//           id: "actio",
//           label: "",
//           customStyle: { width: 80 },
//           customCell: (r, i) => (
//             <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//               {/* {r.manual && !r.paid && (
//                 <button
//                   onClick={_ => this.markAsPaid(r)}
//                   className="table-action-button"
//                 >
//                   Mark as Paid
//                 </button>
//               )} */}
//               {/* <button className="table-action-button">VIEW</button> */}

//               <div
//               // style={{
//               //   position: "absolute",
//               //   right: "15px",
//               //   top: "5px"
//               // }}
//               >
//                 <Dropdown
//                   trigger={["click"]}
//                   overlay={
//                     r.type === "Invoice"
//                       ? this.invoiceMenu(r, i)
//                       : this.menu(r, i)
//                   }
//                   animation="slide-up"
//                 >
//                   <button
//                     className="table-action-button"
//                     style={{
//                       cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//                     }}
//                     disabled={this.canSubUserEdit()}
//                   >
//                     Action
//                   </button>
//                 </Dropdown>
//               </div>
//             </td>
//           )
//         }
//       ],
//       Payments: [
//         {
//           id: "amount",
//           label: "Date",
//           customCell: (r, i) => {
//             if (r.type !== "Invoice") {
//               const createdDate = new Date(r.created * 1000);

//               const local = DateTime.fromSeconds(r.created);
//               const rezoned = local.setZone(
//                 this.global.organizationInfo.timezone
//               );
//               return (
//                 <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//               );
//             }
//             if (r.type === "Invoice") {
//               const createdDate = new Date(r.createdAt * 1000);

//               const local = DateTime.fromMillis(
//                 new Date(r.createdAt).getTime()
//               );
//               const rezoned = local.setZone(
//                 this.global.organizationInfo.timezone
//               );
//               return (
//                 <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//               );
//             }
//           }
//         },
//         {
//           id: "item",
//           label: "Item",
//           customCell: (r, i) => {
//             if (r.type !== "Invoice") {
//               return (
//                 <td className="table-custom-text">
//                   {r.product_table === "partner_programs" &&
//                     this.showProgramPlan(r)}
//                   {r.product_table === "partner_event" && this.showEventPlan(r)}
//                   {r.product_table === "partner_memberships" &&
//                     this.showMembershipPlan(r)}
//                   {r.product_table === "partner_online" &&
//                     this.showProgramPlan(r)}
//                 </td>
//               );
//             }
//             if (r.type === "Invoice") {
//               return (
//                 <td className="table-custom-text">
//                   Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//                 </td>
//               );
//             }
//           }
//         },
//         {
//           id: "amountOverdue",
//           label: "Sub-Total",
//           customCell: (r, i) => {
//             if (r.type === "Invoice") {
//               return (
//                 <td className="table-custom-text">
//                   ${parseFloat(r.itemAmount / 100).toFixed(2)}
//                 </td>
//               );
//             }
//             if (r.type === "Installment") {
//               return (
//                 <td className="table-custom-text">
//                   ${parseFloat(r.amount / 100).toFixed(2)}
//                 </td>
//               );
//             }

//             return this.displaySubTotal(r, i);
//           }
//         },
//         // {
//         //   id: "tax",
//         //   label: "Tax %",
//         //   customCell: (r, i) =>this.displayTaxOrders(r)
//         // },
//         {
//           id: "amountOverdue",
//           label: "Total", // Both Invoice and orders have the same field
//           customCell: (r, i) => {
//             if (r.isInstallment) {
//               return (
//                 <td className="table-custom-text">
//                   ${parseFloat(r.installment_amount).toFixed(2)}
//                 </td>
//               );
//             }
//             return (
//               <td className="table-custom-text">
//                 ${parseFloat(r.amount / 100).toFixed(2)}
//               </td>
//             );
//           }
//         },
//         {
//           id: "amountOverdue",
//           label: "Purchaser",
//           customCell: (r, i) => {
//             if (r.isInstallment) {
//               return <td className="table-custom-text">{r.buyer}</td>;
//             }
//             if (r.type !== "Invoice") {
//               return <td className="table-custom-text">{r.customer_name}</td>;
//             }
//             if (r.type === "Invoice") {
//               return <td className="table-custom-text">{r.displayName}</td>;
//             }
//           }
//         },
//         {
//           id: "userPaymentMethod",
//           label: "Paid Using",
//           customCell: (r, i) => {
//             if (r.userPaymentMethod) {
//               return (
//                 <td className="table-custom-text">{r.userPaymentMethod}</td>
//               );
//             } else if (r.manual) {
//               return <td className="table-custom-text">Offline</td>;
//             } else if (r.stripe) {
//               return <td className="table-custom-text">Credit Card</td>;
//             } else if (r.stripe_transaction_id?.includes("pi_")) {
//               return <td className="table-custom-text">Credit Card</td>;
//             } else {
//               return <td className="table-custom-text">Offline</td>;
//             }
//           }
//         },
//         {
//           id: "status",
//           label: "Status",
//           customCell: (r, i) => {
//             if (r.type === "Invoice") {
//               let text;
//               if (r.invoice_status === "Paid Offline") {
//                 text = "Paid (Manual)";
//               } else if (parseInt(r.amount_refunded) > 0) {
//                 text = "Paid (Refunded)";
//               } else {
//                 text = "Paid";
//               }
//               return (
//                 <td className="green">
//                   <span
//                     style={{
//                       minWidth: "100px",
//                       background: "#1bc88a",
//                       padding: "3px",
//                       borderRadius: "13px",
//                       color: "white",
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       fontFamily: "Open Sans",
//                       fontSize: "12px",
//                       fontWeight: 600
//                     }}
//                   >
//                     {/* {r.invoice_status === "Paid Offline"
//                       ? `Paid (Manual)`
//                       : "Paid"} */}
//                     {text}
//                   </span>
//                 </td>
//               );
//             }

//             return this.orderStatus(r, i);
//           }
//         },
//         {
//           id: "actio",
//           label: "",
//           customStyle: { width: 80 },
//           customCell: (r, i) => (
//             <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//               {/* {r.manual && !r.paid && (
//                 <button
//                   onClick={_ => this.markAsPaid(r)}
//                   className="table-action-button"
//                 >
//                   Mark as Paid
//                 </button>
//               )} */}
//               {/* <button className="table-action-button">VIEW</button> */}

//               <div
//               // style={{
//               //   position: "absolute",
//               //   right: "15px",
//               //   top: "5px"
//               // }}
//               >
//                 <Dropdown
//                   trigger={["click"]}
//                   overlay={
//                     r.type === "Invoice"
//                       ? this.invoiceMenu(r, i)
//                       : this.menu(r, i)
//                   }
//                   animation="slide-up"
//                 >
//                   <button
//                     className="table-action-button"
//                     style={{
//                       cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//                     }}
//                     disabled={this.canSubUserEdit()}
//                   >
//                     Action
//                   </button>
//                 </Dropdown>
//               </div>
//             </td>
//           )
//         }
//       ]
//     };
//   };

//   // oldheadings = () => {
//   //   return {
//   //     Orders: [
//   //       {
//   //         id: "amount",
//   //         label: "Date",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             const createdDate = new Date(r.created * 1000);

//   //             const local = DateTime.fromSeconds(r.created);
//   //             const rezoned = local.setZone(
//   //               this.global.organizationInfo.timezone
//   //             );
//   //             return (
//   //               <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//   //             );
//   //           }
//   //           if (r.type === "Invoice") {
//   //             const createdDate = new Date(r.createdAt * 1000);

//   //             const local = DateTime.fromSeconds(
//   //               new Date(r.createdAt).getTime()
//   //             );
//   //             const rezoned = local.setZone(
//   //               this.global.organizationInfo.timezone
//   //             );
//   //             return (
//   //               <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//   //             );
//   //           }
//   //         }
//   //       },
//   //       {
//   //         id: "item",
//   //         label: "Item",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             return (
//   //               <td className="table-custom-text">
//   //                 {r.product_table === "partner_programs" &&
//   //                   this.showProgramPlan(r)}
//   //                 {r.product_table === "partner_event" && this.showEventPlan(r)}
//   //                 {r.product_table === "partner_memberships" &&
//   //                   this.showMembershipPlan(r)}
//   //                 {r.product_table === "partner_online" &&
//   //                   this.showProgramPlan(r)}
//   //               </td>
//   //             );
//   //           }
//   //           if (r.type === "Invoice") {
//   //             return (
//   //               <td className="table-custom-text">
//   //                 Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//   //               </td>
//   //             );
//   //           }
//   //         }
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Sub-Total",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             this.displaySubTotal(r, i);
//   //           }
//   //           if (r.type === "Invoice") {
//   //             return (
//   //               <td className="table-custom-text">
//   //                 ${parseFloat(r.itemAmount / 100).toFixed(2)}
//   //               </td>
//   //             );
//   //           }
//   //         }
//   //       },
//   //       // {
//   //       //   id: "tax",
//   //       //   label: "Tax %",
//   //       //   customCell: (r, i) =>this.displayTaxOrders(r)
//   //       // },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Total", // Both Invoice and orders have the same field
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             ${parseFloat(r.amount / 100).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Purchaser",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             return <td className="table-custom-text">{r.customer_name}</td>;
//   //           }
//   //           if (r.type === "Invoice") {
//   //             return <td className="table-custom-text">{r.displayName}</td>;
//   //           }
//   //         }
//   //       },
//   //       {
//   //         id: "status",
//   //         label: "Status",
//   //         customCell: (r, i) => {
//   //           if (r.type !== "Invoice") {
//   //             return this.orderStatus;
//   //           }
//   //           if (r.type === "Invoice") {
//   //             return <td className="table-custom-text">{r.invoice_status}</td>;
//   //           }
//   //         }
//   //       },
//   //       {
//   //         id: "actio",
//   //         label: "",
//   //         customStyle: { width: 80 },
//   //         customCell: (r, i) => (
//   //           <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//   //             {/* {r.manual && !r.paid && (
//   //               <button
//   //                 onClick={_ => this.markAsPaid(r)}
//   //                 className="table-action-button"
//   //               >
//   //                 Mark as Paid
//   //               </button>
//   //             )} */}
//   //             {/* <button className="table-action-button">VIEW</button> */}

//   //             <div
//   //             // style={{
//   //             //   position: "absolute",
//   //             //   right: "15px",
//   //             //   top: "5px"
//   //             // }}
//   //             >
//   //               <Dropdown
//   //                 trigger={["click"]}
//   //                 overlay={this.menu(r, i)}
//   //                 animation="slide-up"
//   //               >
//   //                 <button
//   //                   className="table-action-button"
//   //                   style={{
//   //                     cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//   //                   }}
//   //                   disabled={this.canSubUserEdit()}
//   //                 >
//   //                   Action
//   //                 </button>
//   //               </Dropdown>
//   //             </div>
//   //           </td>
//   //         )
//   //       }
//   //     ],
//   //     "Installments Orders": [
//   //       {
//   //         id: "amount",
//   //         label: "Date",
//   //         customCell: (r, i) => {
//   //           const createdDate = new Date(r.created * 1000);
//   //           const local = DateTime.fromSeconds(r.created);
//   //           const rezoned = local.setZone(
//   //             this.global.organizationInfo.timezone
//   //           );

//   //           return (
//   //             <td className="table-custom-text">
//   //               {/* {format(
//   //                 new Date(
//   //                   createdDate.getTime() +
//   //                   Math.abs(createdDate.getTimezoneOffset() * 60000)
//   //                 ),
//   //                 "LLL dd, yyyy"
//   //               )} */}
//   //               {rezoned.toFormat("DD")}
//   //             </td>
//   //           );
//   //         }
//   //       },
//   //       // {
//   //       //   id: "tax",
//   //       //   label: "Tax %",
//   //       //   customCell: (r, i) =>this.displayTaxInstallments(r)
//   //       // },
//   //       {
//   //         id: "amountPaid",
//   //         label: "Amnt Paid",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             $
//   //             {parseFloat(
//   //               r.payments.reduce((p, c) => p + parseFloat(c.amount), 0) //  * 1.13
//   //             ).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountPaid",
//   //         label: "Installment #",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             {r.payments.length} of {r.no_payments} payments
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountPaid",
//   //         label: "Amnt Remaining",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             $
//   //             {parseFloat(
//   //               r.installment_amount - // * 1.13
//   //                 r.payments.reduce(
//   //                   (p, c) => p + parseFloat(c.amount), // * 1.13
//   //                   0
//   //                 )
//   //             ).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         //  * 1.13
//   //         id: "amountOverdue",
//   //         label: "Total",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             ${parseFloat(r.installment_amount).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Purchaser",
//   //         customCell: (r, i) => <td className="table-custom-text">{r.buyer}</td>
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Status",
//   //         customCell: (row, i) => (
//   //           <td key={`id-${i}`}>
//   //             <span className="green table-custom-text">
//   //               Paid{" "}
//   //               {row.payments[row.payments.length - 1].invoice.charge
//   //                 .amount_refunded !== 0 && "(Refunded)"}
//   //             </span>
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "actio",
//   //         label: "",
//   //         customStyle: { width: 80 },
//   //         customCell: (r, i) => (
//   //           <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//   //             <div>
//   //               <Dropdown
//   //                 trigger={["click"]}
//   //                 overlay={this.menu(r, i)}
//   //                 animation="slide-up"
//   //               >
//   //                 <button
//   //                   className="table-action-button"
//   //                   style={{
//   //                     cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//   //                   }}
//   //                   disabled={this.canSubUserEdit()}
//   //                 >
//   //                   Action
//   //                 </button>
//   //               </Dropdown>
//   //             </div>
//   //           </td>
//   //         )
//   //       }
//   //     ],
//   //     Invoices: [
//   //       {
//   //         id: "amount",
//   //         label: "Date",
//   //         customCell: (r, i) => {
//   //           const createdDate = new Date(r.createdAt * 1000);

//   //           const local = DateTime.fromSeconds(new Date(r.createdAt).getTime());
//   //           const rezoned = local.setZone(
//   //             this.global.organizationInfo.timezone
//   //           );
//   //           return (
//   //             <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
//   //           );
//   //         }
//   //       },
//   //       {
//   //         id: "item",
//   //         label: "Item",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             Invoice ${parseFloat(r.amount / 100).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Sub-Total",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             ${parseFloat(r.itemAmount / 100).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       // {
//   //       //   id: "tax",
//   //       //   label: "Tax %",
//   //       //   customCell: (r, i) =>this.displayTaxOrders(r)
//   //       // },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Total",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">
//   //             ${parseFloat(r.amount / 100).toFixed(2)}
//   //           </td>
//   //         )
//   //       },
//   //       {
//   //         id: "amountOverdue",
//   //         label: "Purchaser",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">{r.displayName}</td>
//   //         )
//   //       },
//   //       {
//   //         id: "status",
//   //         label: "Status",
//   //         customCell: (r, i) => (
//   //           <td className="table-custom-text">{r.invoice_status}</td>
//   //         )
//   //       },
//   //       {
//   //         id: "actio",
//   //         label: "",
//   //         customStyle: { width: 80 },
//   //         customCell: (r, i) => (
//   //           <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
//   //             <div>
//   //               <Dropdown
//   //                 trigger={["click"]}
//   //                 overlay={this.invoiceMenu(r, i)}
//   //                 animation="slide-up"
//   //               >
//   //                 <button
//   //                   className="table-action-button"
//   //                   style={{
//   //                     cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
//   //                   }}
//   //                   disabled={this.canSubUserEdit()}
//   //                 >
//   //                   Action
//   //                 </button>
//   //               </Dropdown>
//   //             </div>
//   //           </td>
//   //         )
//   //       }
//   //     ]
//   //   };
//   // };

//   orderStatus = (row, i) => {
//     const isRefunded =
//       !row.amount_refunded || row.amount_refunded === 0 ? false : true;

//     return (
//       <td key={`id-${i}`}>
//         {row.paid ? (
//           <span
//             className="green"
//             style={{
//               minWidth: "100px",
//               background: "#1bc88a",
//               padding: "3px",
//               borderRadius: "13px",
//               color: "white",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               fontFamily: "Open Sans",
//               fontSize: "12px",
//               fontWeight: 600
//             }}
//           >
//             Paid{" "}
//             {row.manual &&
//               !row.is_manual_stripe &&
//               row.amount != 0 &&
//               "(Manual)"}
//             {isRefunded && (
//               <span>{row.amount_refunded === 0 ? "" : " (Refunded)"}</span>
//             )}
//           </span>
//         ) : (
//           <div style={{ textAlign: "right", display: "flex" }}>
//             <span
//               className="red"
//               style={{
//                 minWidth: "100px",
//                 background: "rgb(33 91 255)",
//                 padding: "3px",
//                 borderRadius: "13px",
//                 color: "white",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 fontFamily: "Open Sans",
//                 fontSize: "12px",
//                 fontWeight: 600
//               }}
//             >
//               {row.status === "Processing" ? "Processing" : "Pending"}
//             </span>
//           </div>
//         )}
//         {/* {isRefunded && (
//           <span>{row.amount_refunded === 0 ? "" : "(Refunded)"}</span>
//         )} */}
//       </td>
//     );
//   };

//   /**
//    * This function handles the edit button functionality of a sub user
//    * returns a boolean value
//    */
//   canSubUserEdit = () => {
//     if (!this.global.dsUser.accesspoints) {
//       return false;
//     }
//     if (this.global.dsUser.accesspoints) {
//       return this.global.dsUser.accesspoints.Financials["Paid Orders"].edit
//         ? false
//         : true;
//     }
//   };

//   displaySubTotal = (r, i) => {
//     const tableName = r.product_table;
//     if (tableName === "partner_programs") {
//       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
//         ? JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage
//         : 0;

//       const plan = JSON.parse(r.product.plans[r.planId]);

//       const price = !plan.type
//         ? parseFloat(plan.tuition_rate)
//         : r.drop_in_dates
//         ? (plan.total_price / plan.dayCount) *
//           r.selectedDays.length *
//           (1 + parseInt(plan.dropin_interest) / 100)
//         : parseFloat(plan.total_price) *
//           (r.session_quantity ? r.session_quantity : 1);
//       // console.log('price', price);
//       let couponDiscount = 0;
//       // Coupon Applied
//       if (r.coupon) {
//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(price) * (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }
//       if (r.drop_in_dates && r.coupon) {
//         const plan = JSON.parse(r.product.plans[r.planId]);

//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
//                   (1 + parseInt(plan.dropin_interest) / 100) *
//                   (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }

//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           ${parseFloat(price - couponDiscount).toFixed(2)}
//         </td>
//       );
//     } else if (tableName === "partner_event") {
//       const taxPercentage = JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
//         ? JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage
//         : 0;

//       const plan = JSON.parse(r.product.tickets[r.planId]);
//       const ticketPrice = parseFloat(plan.ticket_price);
//       let couponDiscount = 0;
//       // Coupon Applied
//       if (r.coupon) {
//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(ticketPrice) *
//                   (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }

//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           ${parseFloat(ticketPrice - couponDiscount).toFixed(2)}
//         </td>
//       );
//     } else if (tableName === "partner_memberships") {
//       const taxPercentage = JSON.parse(r.product.prices[r.planId]).isTaxEnabled
//         ? JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage
//         : 0;

//       const plan = JSON.parse(r.product.prices[r.planId]);
//       const pricePerTerm = parseFloat(plan.price_per_term);
//       let couponDiscount = 0;
//       // Coupon Applied
//       if (r.coupon) {
//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(pricePerTerm) *
//                   (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           ${parseFloat(pricePerTerm - couponDiscount).toFixed(2)}
//         </td>
//       );
//     } else if (tableName === "partner_online") {
//       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
//         ? JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage
//         : 0;

//       const plan = JSON.parse(r.product.plans[r.planId]);

//       const price = !plan.type
//         ? parseFloat(plan.tuition_rate)
//         : r.drop_in_dates
//         ? (plan.total_price / plan.dayCount) *
//           r.selectedDays.length *
//           (1 + parseInt(plan.dropin_interest) / 100)
//         : parseFloat(plan.total_price) *
//           (r.session_quantity ? r.session_quantity : 1);

//       let couponDiscount = 0;
//       // Coupon Applied
//       if (r.coupon) {
//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(price) * (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }

//       if (r.drop_in_dates && r.coupon) {
//         const plan = JSON.parse(r.product.plans[r.planId]);

//         couponDiscount =
//           r.coupon.type === "Percentage"
//             ? parseFloat(
//                 parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
//                   (1 + parseInt(plan.dropin_interest) / 100) *
//                   (parseFloat(r.coupon.amount_off) / 100)
//               )
//             : parseFloat(r.coupon.amount_off);
//       }

//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           ${parseFloat(price - couponDiscount).toFixed(2)}
//         </td>
//       );
//     }
//   };

//   displayTaxOrders = (r, i) => {
//     const tableName = r.product_table;
//     if (tableName === "partner_programs") {
//       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
//         ? `${JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage}%`
//         : "0%";
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           {taxPercentage}
//         </td>
//       );
//     } else if (tableName === "partner_event") {
//       const taxPercentage = JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
//         ? `${JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage}%`
//         : "0%";
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           {taxPercentage}
//         </td>
//       );
//     } else if (tableName === "partner_memberships") {
//       const taxPercentage = JSON.parse(r.product.prices[r.planId]).isTaxEnabled
//         ? `${JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage}%`
//         : "0%";
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           {taxPercentage}
//         </td>
//       );
//     } else if (tableName === "partner_online") {
//       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
//         ? `${JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage}%`
//         : "0%";
//       return (
//         <td
//           key={i}
//           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//         >
//           {taxPercentage}
//         </td>
//       );
//     }
//   };

//   displayTaxInstallments = (r, i) => {
//     const taxPercentage = r.payments[0].invoice.tax_percent
//       ? r.payments[0].invoice.tax_percent
//       : 0;

//     return (
//       <td
//         key={i}
//         style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
//       >
//         {taxPercentage}%
//       </td>
//     );
//   };

//   onSelect = async (r, i, event) => {
//     if (event.key === "MarkPaid") {
//       this.markAsPaid(r);
//     }
//     if (event.key === "MarkUnpaid") {
//       this.markAsUnpaid(r);
//     }
//     if (event.key === "refund") {
//       if (r.installment_amount) {
//         this.setState({
//           openRefundModal: true,
//           selectedItem: r.payments[r.payments.length - 1].invoice,
//           usage: "recurring_payments"
//         });
//         return;
//       }
//       this.setState({
//         openRefundModal: true,
//         selectedItem: r,
//         usage: "paid_orders"
//       });
//     }
//     if (event.key === "emailClient") {
//       this.setState({ openEmailClientModal: true, selectedItem: r });
//     }

//     if (event.key === "editInvoice") {
//       console.log("EDIT DETAILS", r);
//       this.props.history.push({
//         pathname: `/partners/orders/invoice-builder/${r.id}`,
//         state: { isNewCustomer: false }
//       });
//     }

//     if (event.key === "viewDetails") {
//       console.log("VIEW DETAILS", r);
//       this.props.history.push(`orders/orders-receipt/${r.orderId}`);
//       // this.setState({ openViewDetailsModal: true, selectedItem: r });
//     }
//   };

//   menu = (r, i) => {
//     return (
//       <Menu onClick={event => this.onSelect(r, i, event)}>
//         {r.manual && !r.is_manual_stripe && !r.paid && (
//           <MenuItem key="MarkPaid">Mark as Paid</MenuItem>
//         )}
//         {r.manual && !r.is_manual_stripe && r.paid && (
//           <MenuItem key="MarkUnpaid">Mark as Unpaid</MenuItem>
//         )}
//         {!r.manual && r.status !== "Processing" && (
//           <MenuItem key="refund">Refund</MenuItem>
//         )}
//         {r.manual && r.status !== "Processing" && r.is_manual_stripe && (
//           <MenuItem key="refund">Refund</MenuItem>
//         )}
//         {!r.manual && <MenuItem key="emailClient">Email Client</MenuItem>}
//         {r.invoice_status === "Issued" &&
//         r.is_manual_stripe &&
//         r.status !== "Paid" ? (
//           <MenuItem key="editInvoice">Edit Invoice</MenuItem>
//         ) : (
//           <MenuItem key="viewDetails">View Details</MenuItem>
//         )}
//       </Menu>
//     );
//   };

//   onInvoiceSelect = async (r, i, event) => {
//     if (event.key === "viewDetails") {
//       console.log("VIEW DETAILS", r);

//       this.props.history.push({
//         pathname: `orders/invoice-builder/${r.id}`,
//         state: { isNewCustomer: false }
//       });
//     }

//     if (event.key === "refund") {
//       if (r.installment_amount) {
//         this.setState({
//           openRefundModal: true,
//           selectedItem: r.payments[r.payments.length - 1].invoice,
//           usage: "recurring_payments"
//         });
//         return;
//       }

//       this.setState({
//         openRefundModal: true,
//         selectedItem: r,
//         usage: "paid_orders"
//       });
//     }

//     if (event.key === "delete-invoice") {
//       this.setState({
//         openDeleteInvoiceConfirmationModal: true,
//         selectedItem: r,
//         usage: "paid_orders"
//       });
//     }
//   };

//   invoiceMenu = (r, i) => {
//     return (
//       <Menu onClick={event => this.onInvoiceSelect(r, i, event)}>
//         {/* {r.manual && !r.paid && (
//           <MenuItem key="MarkPaid">Mark as Paid</MenuItem>
//         )}
//         {r.manual && r.paid && (
//           <MenuItem key="MarkUnpaid">Mark as Unpaid</MenuItem>
//         )}
//         {!r.manual && <MenuItem key="emailClient">Email Client</MenuItem>} */}
//         {/* {!r.userPaymentMethod === "Credit Card" && (
//           <MenuItem key="refund">Refund</MenuItem>
//         )} */}
//         {r.userPaymentMethod === "Credit Card" && (
//           <MenuItem key="refund">Refund</MenuItem>
//         )}
//         <MenuItem key="viewDetails">View Details</MenuItem>
//         {r.tab === "Pending" &&
//           r.invoice_status !== "Issued" &&
//           r.status !== "Processing" && (
//             <MenuItem key="delete-invoice">Delete Invoice</MenuItem>
//           )}
//       </Menu>
//     );
//   };

//   showProgramPlan = r => {
//     if (r.planId === null) {
//       return null;
//     }
//     const plan = JSON.parse(r.product.plans[r.planId]);
//     const objConv = {
//       week: "Weekly",
//       "bi-week": "Bi-Weekly",
//       month: "Monthly"
//     };
//     return (
//       <span>
//         {plan.type
//           ? r.drop_in_dates
//             ? `Semester Drop-in - $${parseFloat(
//                 (plan.total_price / plan.dayCount) *
//                   (1 + parseInt(plan.dropin_interest) / 100)
//               ).toFixed(0)}`
//             : plan.installments
//             ? `Semester - Inst - $${parseFloat(
//                 (plan.total_price / plan.installments_breakdown) *
//                   (1 + parseInt(plan.installments_interest) / 100)
//               ).toFixed(0)} - ${plan.installments_plan}`
//             : plan.type === "Single Sessions"
//             ? `Single Sessions - $${plan.total_price} x(${r.session_quantity})`
//             : `Semester - $${plan.total_price}`
//           : `Recurring - $${plan.tuition_rate} / ${
//               objConv[plan.billing_cycle]
//             }, \n ${plan.timing[0]} to ${plan.timing[1]}`}
//       </span>
//     );
//   };

//   showEventPlan = r => {
//     const plan = JSON.parse(r.product.tickets[r.planId]);
//     const ticketName =
//       plan.ticket_status === "Paid" ? "Standard" : plan.ticket_status;
//     const ticketPrice = plan.ticket_price ? `$${plan.ticket_price}` : "Free";
//     return (
//       <span>
//         {ticketName} Ticket / {ticketPrice}
//       </span>
//     );
//   };

//   showMembershipPlan = r => {
//     const plan = JSON.parse(r.product.prices[r.planId]);
//     const membershipTerm = plan.membership_term;
//     const renewalStatus = plan.auto_renew
//       ? "Auto Renews"
//       : "Standard Membership";
//     const pricePerTerm = plan.price_per_term;
//     return (
//       <span>
//         {membershipTerm} Month Term - {renewalStatus} - {pricePerTerm}
//       </span>
//     );
//   };

//   amountCell = r => {
//     return (
//       <td key={`${r.id}-amt`} className="table-custom-text">
//         ${parseFloat(r.amount).toFixed(2)}
//       </td>
//     );
//   };

//   servicePeriodCell = r => {
//     if (!r.servicePeriodStart) return <td key={`${r.id}-sp`}>N/A</td>;
//     return (
//       <td key={`${r.id}-sp`} className="table-custom-text">
//         {format(new Date(r.servicePeriodStart), "LLL d, yyyy")} -{" "}
//         {format(new Date(r.servicePeriodEnd), "LLL d, yyyy")}
//       </td>
//     );
//   };

//   customDueDate = r => {
//     return (
//       <td key={`${r.id}-dd`} className="table-custom-text">
//         {format(new Date(r.dueDate), "LLL d, yyyy")}
//       </td>
//     );
//   };

//   async markAsPaid(r) {
//     const ep = `${process.env.REACT_APP_API}/partners/orders/mark_paid`;
//     const res = await axios.put(ep, r);
//     if (res.data.success) {
//       this.fetchInitialData();
//       // this.setState({
//       //   data: {
//       //     ...this.state.data,
//       //     Orders: this.state.data.Orders.map(e =>
//       //       e.id === r.id ? { ...e, paid: true } : e
//       //     )
//       //   }
//       // });
//     }
//   }

//   async markAsUnpaid(r) {
//     const ep = `${process.env.REACT_APP_API}/partners/orders/mark_unpaid`;
//     const res = await axios.put(ep, r);

//     if (res.data.success) {
//       this.fetchInitialData();
//     }
//   }

//   async componentDidMount() {
//     this.fetchInitialData();

//     // Testing Using Dummy Data:
//     // this.setState({
//     //   data: {
//     //     Orders: [
//     //       {
//     //         amount: 62150,
//     //         created: 1597248282,
//     //         customer_name: "test",
//     //         id: 50,
//     //         manual: true,
//     //         paid: false,
//     //         status: null
//     //       }
//     //     ]
//     //   }
//     // });
//   }

//   fetchInitialData = async () => {
//     this.setGlobal({ loading: true });
//     const ep = `${process.env.REACT_APP_API}/partners/orders`;
//     const res = await axios.get(ep);

//     console.log("res", res.data);
//     if (res.data.success) {
//       const ordersInvoicePaid = res.data.data.Invoices.invoices
//         .filter(e => e.status === "Paid")
//         .map(e => {
//           let item = e;
//           item.created = new Date(e.createdAt).getTime();
//           item.tab = "Payments";
//           return item;
//         });
//       const ordersInvoicePending = res.data.data.Invoices.invoices
//         .filter(e => e.status !== "Paid")
//         .map(e => {
//           let item = e;
//           item.created = new Date(e.createdAt).getTime();
//           item.tab = "Pending";
//           return item;
//         });
//       console.log("ordersInvoicePaid", ordersInvoicePaid);
//       console.log("ordersInvoicePending", ordersInvoicePending);
//       let orderChargesPaid =
//         res.data.data.AllPayouts?.charges
//           ?.filter(e => e.paid || e.status === "Processing")
//           .sort((a, b) => b.created - a.created) || [];
//       if (orderChargesPaid.length > 0) {
//         orderChargesPaid.tab = "Payments";
//       }
//       let orderChargesPending =
//         res.data.data.AllPayouts?.charges
//           ?.filter(
//             e => !e.paid && !(e.status === "Processing") && e.status !== null
//           )
//           .sort((a, b) => b.created - a.created) || [];
//       console.log("orderChargesPending", orderChargesPending);
//       console.log("orderChargesPaid", orderChargesPaid);
//       if (orderChargesPending.length > 0) {
//         orderChargesPending.tab = "Payments";
//       }
//       let stripeInstallments = res.data.data.AllInstallments
//         ? res.data.data.AllInstallments?.charges?.sort(
//             (a, b) => b.created - a.created
//           ) || []
//         : [];
//       if (stripeInstallments.length > 0) {
//         stripeInstallments.tab = "Payments";
//       }

//       const payments = [
//         ...orderChargesPaid,
//         ...ordersInvoicePaid,
//         ...stripeInstallments
//       ].sort((a, b) => new Date(b.created * 1000) - new Date(a.created * 1000));
//       console.log("payments", payments);
//       const pending = [...ordersInvoicePending, ...orderChargesPending].sort(
//         (a, b) => new Date(b.created * 1000) - new Date(a.created * 1000)
//       );
//       console.log("pending", pending);

//       this.setState({
//         data: {
//           Pending: pending,
//           Payments: payments
//           // Orders: res.data.data.AllPayouts
//           //   ? res.data.data.AllPayouts?.charges?.sort(
//           //       (a, b) => b.created - a.created
//           //     ) || []
//           //   : [],
//           // "Installments Orders": res.data.data.AllInstallments
//           //   ? res.data.data.AllInstallments?.charges?.sort(
//           //       (a, b) => b.created - a.created
//           //     ) || []
//           //   : [],
//           // Invoices: res.data.data.Invoices.sort(
//           //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
//           // )
//         },
//         earnings:
//           orderChargesPaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0) +
//           stripeInstallments.reduce(
//             (p, c) => p + parseFloat(c.installment_amount), //  * 1.13
//             0
//           ) +
//           ordersInvoicePaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0)
//       });
//     }
//     this.setGlobal({ loading: false });
//   };

//   render() {
//     console.log("this.props", this.props);

//     return (
//       <>
//         {this.state.openCreateCustomerModal && (
//           <CreateCustomerModal
//             createNewCustomer={_ =>
//               this.setState({ openCreateCustomerModal: true })
//             }
//             onClose={_ => {
//               this.setState({ openCreateCustomerModal: false });
//             }}
//           />
//         )}
//         {this.state.openInvoiceCustomerModal && (
//           <InvoiceCustomerModal
//             createNewCustomer={_ =>
//               this.setState({ openCreateCustomerModal: true })
//             }
//             onClose={_ => {
//               this.setState({ openInvoiceCustomerModal: false });
//             }}
//           />
//         )}
//         {this.state.openViewDetailsModal && (
//           <PaidOrdersModal
//             selectedItem={this.state.selectedItem}
//             onClose={_ => {
//               this.setState({ openViewDetailsModal: false });
//             }}
//           />
//         )}
//         {this.state.openRefundModal && (
//           <RefundModal
//             selectedItem={this.state.selectedItem}
//             amountRefunded={
//               this.state.usage === "paid_orders"
//                 ? this.state.selectedItem.amount_refunded
//                 : this.state.selectedItem.charge.amount_refunded
//             }
//             post_payment_credit_notes_amount={
//               this.state.selectedItem.invoice
//                 ? this.state.selectedItem.invoice
//                     .post_payment_credit_notes_amount
//                 : this.state.selectedItem.post_payment_credit_notes_amount
//             }
//             onClose={_ => {
//               // this.fetchInitialData();
//               this.fetchInitialData();
//               this.setState({ openRefundModal: false });
//             }}
//             usage={this.state.usage}
//           />
//         )}
//         {this.state.openEmailClientModal && (
//           <EmailClientModal
//             selectedItem={this.state.selectedItem}
//             charge={
//               this.state.selectedItem.id ||
//               this.state.selectedItem.payments[
//                 this.state.selectedItem.payments.length - 1
//               ].invoice.charge
//             }
//             onClose={_ => {
//               // this.fetchInitialData();
//               this.setState({ openEmailClientModal: false });
//             }}
//           />
//         )}
//         {this.state.openDeleteInvoiceConfirmationModal && (
//           <DeleteInvoiceConfirmationModal
//             selectedItem={this.state.selectedItem}
//             confirmDelete={_ => this.fetchInitialData()}
//             onClose={_ => {
//               // this.fetchInitialData();
//               this.setState({ openDeleteInvoiceConfirmationModal: false });
//             }}
//           />
//         )}
//         <div className="container-fluid">
//           <div className="row cont">
//             <div className="col-md-6">
//               <h1>Order Tracking</h1>
//             </div>

//             <div className="col-md-6">
//               <div className="forbtnwrap justify-end">
//                 <div
//                   className="forbtnapp enrollment-btn"
//                   style={{ marginLeft: "20px" }}
//                 >
//                   <button
//                     className="newapplications"
//                     style={{
//                       cursor: "pointer"
//                     }}
//                     onClick={_ => {
//                       this.setState({ openInvoiceCustomerModal: true });
//                     }}
//                   >
//                     <img
//                       src={MiniPlus}
//                       alt=""
//                       style={{ alignSelf: "center" }}
//                     />
//                     Invoice
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="cont">
//             <div className="row">
//               <div className="col-lg-3">
//                 <div className="money-box mt-0">
//                   <div className="row">
//                     <div className="col-lg-12 col-sm-6">
//                       <div className="money-box-inner">
//                         <h2>ORDER EARNINGS</h2>
//                         <h3>
//                           {parseFloat(this.state.earnings || 0).toLocaleString(
//                             "en-US",
//                             {
//                               style: "currency",
//                               currency: "USD"
//                             }
//                           )}
//                         </h3>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-sm-6">
//                       <div className="money-box-inner">
//                         <h2>TOTAL ORDERS</h2>
//                         <h3>
//                           {this.state.data.Payments.length +
//                             this.state.data["Pending"].length}
//                         </h3>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-sm-6">
//                       <div className="money-box-inner">
//                         <h2>PAID ORDERS</h2>
//                         <h3>{this.state.data.Payments.length}</h3>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-sm-6">
//                       <div className="money-box-inner">
//                         <h2>PAYMENTS OWED</h2>
//                         <h3>{this.state.data.Pending.length}</h3>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-9">
//                 <NewTabbedTable
//                   data={this.state.data}
//                   tabs={this.tabs}
//                   headings={this.headings()}
//                   actions={this.actions}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default withRouter(TuitionPayments);

// // import React from "reactn";
// // // import Tables from "../../components/table";
// // import axios from "axios";
// // import { NewTabbedTable } from "../../components/UI";
// // // import { AmountArrow } from "../../assets";
// // import {
// //   eachDayOfInterval,
// //   format
// //   // addWeeks
// // } from "date-fns";
// // // import PaymentDetailsModal from "../../components/modals/PaymentDetailsModal";
// // // import { Link } from "react-router-dom";
// // import "../../assets/css/componentSpecificCss/newTabbedTable.css";
// // import Dropdown from "rc-dropdown";
// // import Menu, { Item as MenuItem } from "rc-menu";
// // import RefundModal from "../../components/modals/RefundModal";
// // import EmailClientModal from "../../components/modals/EmailClientModal";
// // import { DateTime } from "luxon";
// // import PaidOrdersModal from "../../components/modals/PaidOrdersModal";
// // import InvoiceCustomerModal from "../../components/modals/InvoiceCustomerModal";
// // import CreateCustomerModal from "../../components/modals/CreateCustomerModal";
// // import DeleteInvoiceConfirmationModal from "../../components/modals/DeleteInvoiceConfirmationModal";

// // import { MiniPlus } from "../../assets";
// // import { withRouter } from "react-router-dom";

// // class TuitionPayments extends React.Component {
// //   constructor(props) {
// //     super(props);
// //     this.state = {
// //       data: {
// //         Orders: [],
// //         "Installments Orders": [],
// //         Invoices: [],
// //         Pending: [],
// //         Payments: []
// //       },
// //       counts: {
// //         overdue: 0,
// //         paid: 0,
// //         pending: 0,
// //         total: 0
// //       },
// //       selectedStudent: null,
// //       selectedName: null,
// //       selectedData: {
// //         data: [],
// //         counts: {
// //           overdue: 0,
// //           paid: 0,
// //           pending: 0,
// //           total: 0
// //         }
// //       },
// //       selectedInvoice: null,
// //       openRefundModal: false,
// //       openEmailClientModal: false,
// //       selectedItem: null,
// //       usage: "paid_orders",
// //       openViewDetailsModal: false
// //     };
// //     this.actions = [{ name: "VIEW", action: _ => true }];
// //     this.tabs = ["Pending", "Payments"];

// //     // this.tabs = ["Orders", "Installments Orders", "Invoices"];
// //     this.setGlobal({
// //       partnerPathway: [
// //         ...this.global.partnerPathway.slice(0, 1),
// //         { label: "Order Tracking", to: "/orders" }
// //       ]
// //     });
// //   }

// //   headings = () => {
// //     // pending headings
// //     const pendingDate = {
// //       id: "amount",
// //       label: "Date",
// //       customCell: (r, i) => {
// //         let local;
// //         if (r.type !== "Invoice") {
// //           local = DateTime.fromSeconds(r.created);
// //         }
// //         if (r.type === "Invoice") {
// //           local = DateTime.fromMillis(new Date(r.createdAt).getTime());
// //         }
// //         const rezoned = local.setZone(this.global.organizationInfo.timezone);
// //         return <td className="table-custom-text">{rezoned.toFormat("DD")}</td>;
// //       }
// //     };

// //     const pendingItem = {
// //       id: "item",
// //       label: "Item",
// //       customCell: (r, i) => {
// //         if (r.type !== "Invoice") {
// //           return <td className="table-custom-text">{r.item}</td>;
// //         }
// //         if (r.type === "Invoice") {
// //           return (
// //             <td className="table-custom-text">
// //               Invoice ${parseFloat(r.amount / 100).toFixed(2)}
// //             </td>
// //           );
// //         }
// //       }
// //     };

// //     const pendingTotal = {
// //       id: "amountOverdue",
// //       label: "Total", // Both Invoice and orders have the same field
// //       customCell: (r, i) => (
// //         <td className="table-custom-text">
// //           ${parseFloat(r.amount / 100).toFixed(2)}
// //         </td>
// //       )
// //     };

// //     const pendingPurchaser = {
// //       id: "amountOverdue",
// //       label: "Purchaser",
// //       customCell: (r, i) => {
// //         if (r.type !== "Invoice") {
// //           return <td className="table-custom-text">{r.customer_name}</td>;
// //         }
// //         if (r.type === "Invoice") {
// //           return <td className="table-custom-text">{r.displayName}</td>;
// //         }
// //       }
// //     };

// //     const pendingStatus = {
// //       id: "status",
// //       label: "Status",
// //       customCell: (r, i) => {
// //         if (r.type === "Invoice") {
// //           let color = "#eba93e";
// //           let text = "Ready to Bill";
// //           switch (r.invoice_status) {
// //             case "Draft":
// //               text = "Draft";
// //               color = "rgb(33 91 255)";
// //               break;

// //             case "Issued":
// //               text = "Issued";
// //               color = "rgba(92 200 220)";
// //               break;

// //             case "Ready to Bill":
// //               text = "Ready to Bill";
// //               color = "#eba93e";
// //               break;

// //             case "Cancelled":
// //               text = "Cancelled";
// //               color = "#ff3b8e";
// //               break;

// //             case "Paid Offline":
// //               text = "Paid Offline";
// //               color = "#1bc88a";
// //               break;

// //             default:
// //               break;
// //           }

// //           if (
// //             r.invoice_status === "Issued" &&
// //             new Date() > new Date(r.due_date)
// //           ) {
// //             // return <td className="table-custom-text">Late</td>;
// //             text = "Late";
// //             color = "#ff3b8e";
// //           }

// //           if (r.status === "Processing") {
// //             text = "Processing";
// //             color = "rgb(33 91 255)";
// //           }

// //           return (
// //             <td>
// //               <div
// //                 style={{
// //                   textAlign: "right",
// //                   display: "flex"
// //                 }}
// //               >
// //                 <span
// //                   className="red"
// //                   style={{
// //                     minWidth: "100px",
// //                     background: color,
// //                     padding: "3px",
// //                     borderRadius: "13px",
// //                     color: "white",
// //                     display: "flex",
// //                     justifyContent: "center",
// //                     alignItems: "center",
// //                     fontFamily: "Open Sans",
// //                     fontSize: "12px",
// //                     fontWeight: 600
// //                   }}
// //                 >
// //                   {text}
// //                 </span>
// //               </div>
// //             </td>
// //           );
// //         }
// //         return this.orderStatus(r, i);
// //       }
// //     };

// //     const pendingAction = {
// //       id: "actio",
// //       label: "",
// //       customStyle: { width: 80 },
// //       customCell: (r, i) => (
// //         <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
// //           <div>
// //             <Dropdown
// //               trigger={["click"]}
// //               overlay={
// //                 r.type === "Invoice" ? this.invoiceMenu(r, i) : this.menu(r, i)
// //               }
// //               animation="slide-up"
// //             >
// //               <button
// //                 className="table-action-button"
// //                 style={{
// //                   cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
// //                 }}
// //                 disabled={this.canSubUserEdit()}
// //               >
// //                 Action
// //               </button>
// //             </Dropdown>
// //           </div>
// //         </td>
// //       )
// //     };

// //     const pendingHeadings = [
// //       pendingDate,
// //       pendingItem,
// //       pendingTotal,
// //       pendingPurchaser,
// //       pendingStatus,
// //       pendingAction
// //     ];

// //     // if (r.type !== "Invoice") {
// //     //         const createdDate = new Date(r.created * 1000);

// //     //         const local = DateTime.fromSeconds(r.created);
// //     //         const rezoned = local.setZone(
// //     //           this.global.organizationInfo.timezone
// //     //         );
// //     //         return (
// //     //           <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
// //     //         );
// //     //       }
// //     //       if (r.type === "Invoice") {
// //     //         const createdDate = new Date(r.createdAt * 1000);

// //     //         const local = DateTime.fromMillis(
// //     //           new Date(r.createdAt).getTime()
// //     //         );
// //     //         const rezoned = local.setZone(
// //     //           this.global.organizationInfo.timezone
// //     //         );
// //     //         return (
// //     //           <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
// //     //         );
// //     //       }
// //     const paymentsDate = {
// //       id: "amount",
// //       label: "Date",
// //       customCell: (r, i) => {
// //         const date = new Date(r.date);
// //         if (isNaN(date.getTime())) {
// //           return <td className="table-custom-text">---</td>;
// //         }
// //         return (
// //           <td className="table-custom-text">{format(date, "MMM dd, yyyy")}</td>
// //         );
// //       }
// //     };

// //     const paymentsItem = {
// //       id: "item",
// //       label: "Item",
// //       customCell: (r, i) => {
// //         if (r.type !== "Invoice") {
// //           return (
// //             <td className="table-custom-text">
// //               {r.product_table === "partner_programs" &&
// //                 this.showProgramPlan(r)}
// //               {r.product_table === "partner_event" && this.showEventPlan(r)}
// //               {r.product_table === "partner_memberships" &&
// //                 this.showMembershipPlan(r)}
// //               {r.product_table === "partner_online" && this.showProgramPlan(r)}
// //             </td>
// //           );
// //         }
// //         if (r.type === "Invoice") {
// //           return (
// //             <td className="table-custom-text">
// //               Invoice ${parseFloat(r.amount / 100).toFixed(2)}
// //             </td>
// //           );
// //         }
// //       }
// //     };

// //     const paymentsTotal = {
// //       id: "amountOverdue",
// //       label: "Total", // Both Invoice and orders have the same field
// //       customCell: (r, i) => {
// //         if (r.isInstallment) {
// //           return (
// //             <td className="table-custom-text">
// //               ${parseFloat(r.installment_amount).toFixed(2)}
// //             </td>
// //           );
// //         }
// //         return (
// //           <td className="table-custom-text">
// //             ${parseFloat(r.amount / 100).toFixed(2)}
// //           </td>
// //         );
// //       }
// //     };

// //     const paymentsPurchaser = {
// //       id: "amountOverdue",
// //       label: "Purchaser",
// //       customCell: (r, i) => {
// //         // if (r.isInstallment) {
// //         //   return <td className="table-custom-text">{r.buyer}</td>;
// //         // }
// //         // if (r.type !== "Invoice") {
// //         //   return <td className="table-custom-text">{r.customer_name}</td>;
// //         // }
// //         // if (r.type === "Invoice") {
// //         //   return <td className="table-custom-text">{r.displayName}</td>;
// //         // }
// //         return <td className="table-custom-text">{r.purchaser}</td>;
// //       }
// //     };

// //     const paymentsPaidUsing = {
// //       id: "userPaymentMethod",
// //       label: "Paid Using",
// //       customCell: (r, i) => {
// //         // if (r.userPaymentMethod) {
// //         //   return <td className="table-custom-text">{r.userPaymentMethod}</td>;
// //         // } else if (r.manual) {
// //         //   return <td className="table-custom-text">Offline</td>;
// //         // } else if (r.stripe) {
// //         //   return <td className="table-custom-text">Credit Card</td>;
// //         // } else if (r.stripe_transaction_id?.includes("pi_")) {
// //         //   return <td className="table-custom-text">Credit Card</td>;
// //         // } else {
// //         //   return <td className="table-custom-text">Offline</td>;
// //         // }
// //         return <td className="table-custom-text">{r.paidUsing}</td>;
// //       }
// //     };

// //     const paymentsStatus = {
// //       id: "status",
// //       label: "Status",
// //       customCell: (r, i) => {
// //         if (r.type === "Invoice") {
// //           return (
// //             <td className="green">
// //               <span
// //                 style={{
// //                   minWidth: "100px",
// //                   background: "#1bc88a",
// //                   padding: "3px",
// //                   borderRadius: "13px",
// //                   color: "white",
// //                   display: "flex",
// //                   justifyContent: "center",
// //                   alignItems: "center",
// //                   fontFamily: "Open Sans",
// //                   fontSize: "12px",
// //                   fontWeight: 600
// //                 }}
// //               >
// //                 {r.invoice_status === "Paid Offline" ? `Paid (Manual)` : "Paid"}
// //               </span>
// //             </td>
// //           );
// //         }

// //         return this.orderStatus(r, i);
// //       }
// //     };

// //     const paymentsAction = {
// //       id: "actio",
// //       label: "",
// //       customStyle: { width: 80 },
// //       customCell: (r, i) => (
// //         <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
// //           <div>
// //             <Dropdown
// //               trigger={["click"]}
// //               overlay={
// //                 r.type === "Invoice" ? this.invoiceMenu(r, i) : this.menu(r, i)
// //               }
// //               animation="slide-up"
// //             >
// //               <button
// //                 className="table-action-button"
// //                 style={{
// //                   cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
// //                 }}
// //                 disabled={this.canSubUserEdit()}
// //               >
// //                 Action
// //               </button>
// //             </Dropdown>
// //           </div>
// //         </td>
// //       )
// //     };

// //     const paymentsHeadings = [
// //       paymentsDate,
// //       paymentsItem,
// //       paymentsTotal,
// //       paymentsPurchaser,
// //       paymentsPaidUsing,
// //       paymentsStatus,
// //       paymentsAction
// //     ];

// //     return {
// //       Pending: pendingHeadings,
// //       Payments: paymentsHeadings
// //       // [
// //       // {
// //       //   id: "amount",
// //       //   label: "Date",
// //       //   customCell: (r, i) => {
// //       //     if (r.type !== "Invoice") {
// //       //       const createdDate = new Date(r.created * 1000);

// //       //       const local = DateTime.fromSeconds(r.created);
// //       //       const rezoned = local.setZone(
// //       //         this.global.organizationInfo.timezone
// //       //       );
// //       //       return (
// //       //         <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
// //       //       );
// //       //     }
// //       //     if (r.type === "Invoice") {
// //       //       const createdDate = new Date(r.createdAt * 1000);

// //       //       const local = DateTime.fromMillis(
// //       //         new Date(r.createdAt).getTime()
// //       //       );
// //       //       const rezoned = local.setZone(
// //       //         this.global.organizationInfo.timezone
// //       //       );
// //       //       return (
// //       //         <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
// //       //       );
// //       //     }
// //       //   }
// //       // },
// //       // {
// //       //   id: "item",
// //       //   label: "Item",
// //       //   customCell: (r, i) => {
// //       //     if (r.type !== "Invoice") {
// //       //       return (
// //       //         <td className="table-custom-text">
// //       //           {r.product_table === "partner_programs" &&
// //       //             this.showProgramPlan(r)}
// //       //           {r.product_table === "partner_event" && this.showEventPlan(r)}
// //       //           {r.product_table === "partner_memberships" &&
// //       //             this.showMembershipPlan(r)}
// //       //           {r.product_table === "partner_online" &&
// //       //             this.showProgramPlan(r)}
// //       //         </td>
// //       //       );
// //       //     }
// //       //     if (r.type === "Invoice") {
// //       //       return (
// //       //         <td className="table-custom-text">
// //       //           Invoice ${parseFloat(r.amount / 100).toFixed(2)}
// //       //         </td>
// //       //       );
// //       //     }
// //       //   }
// //       // },
// //       // {
// //       //   id: "amountOverdue",
// //       //   label: "Sub-Total",
// //       //   customCell: (r, i) => {
// //       //     if (r.type === "Invoice") {
// //       //       return (
// //       //         <td className="table-custom-text">
// //       //           ${parseFloat(r.itemAmount / 100).toFixed(2)}
// //       //         </td>
// //       //       );
// //       //     }
// //       //     return this.displaySubTotal(r, i);
// //       //   }
// //       // },
// //       // {
// //       //   id: "tax",
// //       //   label: "Tax %",
// //       //   customCell: (r, i) =>this.displayTaxOrders(r)
// //       // },
// //       // {
// //       //   id: "amountOverdue",
// //       //   label: "Total", // Both Invoice and orders have the same field
// //       //   customCell: (r, i) => (
// //       //     <td className="table-custom-text">
// //       //       ${parseFloat(r.amount / 100).toFixed(2)}
// //       //     </td>
// //       //   )
// //       // },
// //       // {
// //       //   id: "amountOverdue",
// //       //   label: "Purchaser",
// //       //   customCell: (r, i) => {
// //       //     if (r.type !== "Invoice") {
// //       //       return <td className="table-custom-text">{r.customer_name}</td>;
// //       //     }
// //       //     if (r.type === "Invoice") {
// //       //       return <td className="table-custom-text">{r.displayName}</td>;
// //       //     }
// //       //   }
// //       // },
// //       // {
// //       //   id: "status",
// //       //   label: "Status",
// //       //   customCell: (r, i) => {
// //       //     if (r.type === "Invoice") {
// //       //       let color = "#eba93e";
// //       //       let text = "Ready to Bill";
// //       //       switch (r.invoice_status) {
// //       //         case "Draft":
// //       //           text = "Draft";
// //       //           color = "rgb(33 91 255)";
// //       //           break;

// //       //         case "Issued":
// //       //           text = "Issued";
// //       //           color = "rgba(92 200 220)";
// //       //           break;

// //       //         case "Ready to Bill":
// //       //           text = "Ready to Bill";
// //       //           color = "#eba93e";
// //       //           break;

// //       //         case "Cancelled":
// //       //           text = "Cancelled";
// //       //           color = "#ff3b8e";
// //       //           break;

// //       //         case "Paid Offline":
// //       //           text = "Paid Offline";
// //       //           color = "#1bc88a";
// //       //           break;

// //       //         default:
// //       //           break;
// //       //       }

// //       //       if (
// //       //         r.invoice_status === "Issued" &&
// //       //         new Date() > new Date(r.due_date)
// //       //       ) {
// //       //         // return <td className="table-custom-text">Late</td>;
// //       //         text = "Late";
// //       //         color = "#ff3b8e";
// //       //       }

// //       //       if (r.status === "Processing") {
// //       //         text = "Processing";
// //       //         color = "rgb(33 91 255)";
// //       //       }

// //       //       return (
// //       //         <td>
// //       //           <div
// //       //             style={{
// //       //               textAlign: "right",
// //       //               display: "flex"
// //       //             }}
// //       //           >
// //       //             <span
// //       //               className="red"
// //       //               style={{
// //       //                 minWidth: "100px",
// //       //                 background: color,
// //       //                 padding: "3px",
// //       //                 borderRadius: "13px",
// //       //                 color: "white",
// //       //                 display: "flex",
// //       //                 justifyContent: "center",
// //       //                 alignItems: "center",
// //       //                 fontFamily: "Open Sans",
// //       //                 fontSize: "12px",
// //       //                 fontWeight: 600
// //       //               }}
// //       //             >
// //       //               {text}
// //       //             </span>
// //       //           </div>
// //       //         </td>
// //       //       );
// //       //     }
// //       //     return this.orderStatus(r, i);
// //       //   }
// //       // },
// //       // {
// //       //   id: "actio",
// //       //   label: "",
// //       //   customStyle: { width: 80 },
// //       //   customCell: (r, i) => (
// //       //     <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
// //       //       {/* {r.manual && !r.paid && (
// //       //         <button
// //       //           onClick={_ => this.markAsPaid(r)}
// //       //           className="table-action-button"
// //       //         >
// //       //           Mark as Paid
// //       //         </button>
// //       //       )} */}
// //       //       {/* <button className="table-action-button">VIEW</button> */}

// //       //       <div
// //       //       // style={{
// //       //       //   position: "absolute",
// //       //       //   right: "15px",
// //       //       //   top: "5px"
// //       //       // }}
// //       //       >
// //       //         <Dropdown
// //       //           trigger={["click"]}
// //       //           overlay={
// //       //             r.type === "Invoice"
// //       //               ? this.invoiceMenu(r, i)
// //       //               : this.menu(r, i)
// //       //           }
// //       //           animation="slide-up"
// //       //         >
// //       //           <button
// //       //             className="table-action-button"
// //       //             style={{
// //       //               cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
// //       //             }}
// //       //             disabled={this.canSubUserEdit()}
// //       //           >
// //       //             Action
// //       //           </button>
// //       //         </Dropdown>
// //       //       </div>
// //       //     </td>
// //       //   )
// //       // }
// //       //],
// //       // Payments: [
// //       //   {
// //       //     id: "amount",
// //       //     label: "Date",
// //       //     customCell: (r, i) => {
// //       //       if (r.type !== "Invoice") {
// //       //         const createdDate = new Date(r.created * 1000);

// //       //         const local = DateTime.fromSeconds(r.created);
// //       //         const rezoned = local.setZone(
// //       //           this.global.organizationInfo.timezone
// //       //         );
// //       //         return (
// //       //           <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
// //       //         );
// //       //       }
// //       //       if (r.type === "Invoice") {
// //       //         const createdDate = new Date(r.createdAt * 1000);

// //       //         const local = DateTime.fromMillis(
// //       //           new Date(r.createdAt).getTime()
// //       //         );
// //       //         const rezoned = local.setZone(
// //       //           this.global.organizationInfo.timezone
// //       //         );
// //       //         return (
// //       //           <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
// //       //         );
// //       //       }
// //       //     }
// //       //   },
// //       //   {
// //       //     id: "item",
// //       //     label: "Item",
// //       //     customCell: (r, i) => {
// //       //       if (r.type !== "Invoice") {
// //       //         return (
// //       //           <td className="table-custom-text">
// //       //             {r.product_table === "partner_programs" &&
// //       //               this.showProgramPlan(r)}
// //       //             {r.product_table === "partner_event" && this.showEventPlan(r)}
// //       //             {r.product_table === "partner_memberships" &&
// //       //               this.showMembershipPlan(r)}
// //       //             {r.product_table === "partner_online" &&
// //       //               this.showProgramPlan(r)}
// //       //           </td>
// //       //         );
// //       //       }
// //       //       if (r.type === "Invoice") {
// //       //         return (
// //       //           <td className="table-custom-text">
// //       //             Invoice ${parseFloat(r.amount / 100).toFixed(2)}
// //       //           </td>
// //       //         );
// //       //       }
// //       //     }
// //       //   },
// //       //   {
// //       //     id: "amountOverdue",
// //       //     label: "Sub-Total",
// //       //     customCell: (r, i) => {
// //       //       if (r.type === "Invoice") {
// //       //         return (
// //       //           <td className="table-custom-text">
// //       //             ${parseFloat(r.itemAmount / 100).toFixed(2)}
// //       //           </td>
// //       //         );
// //       //       }
// //       //       if (r.type === "Installment") {
// //       //         return (
// //       //           <td className="table-custom-text">
// //       //             ${parseFloat(r.amount / 100).toFixed(2)}
// //       //           </td>
// //       //         );
// //       //       }

// //       //       return this.displaySubTotal(r, i);
// //       //     }
// //       //   },
// //       //   // {
// //       //   //   id: "tax",
// //       //   //   label: "Tax %",
// //       //   //   customCell: (r, i) =>this.displayTaxOrders(r)
// //       //   // },
// //       //   {
// //       //     id: "amountOverdue",
// //       //     label: "Total", // Both Invoice and orders have the same field
// //       //     customCell: (r, i) => {
// //       //       if (r.isInstallment) {
// //       //         return (
// //       //           <td className="table-custom-text">
// //       //             ${parseFloat(r.installment_amount).toFixed(2)}
// //       //           </td>
// //       //         );
// //       //       }
// //       //       return (
// //       //         <td className="table-custom-text">
// //       //           ${parseFloat(r.amount / 100).toFixed(2)}
// //       //         </td>
// //       //       );
// //       //     }
// //       //   },
// //       //   {
// //       //     id: "amountOverdue",
// //       //     label: "Purchaser",
// //       //     customCell: (r, i) => {
// //       //       if (r.isInstallment) {
// //       //         return <td className="table-custom-text">{r.buyer}</td>;
// //       //       }
// //       //       if (r.type !== "Invoice") {
// //       //         return <td className="table-custom-text">{r.customer_name}</td>;
// //       //       }
// //       //       if (r.type === "Invoice") {
// //       //         return <td className="table-custom-text">{r.displayName}</td>;
// //       //       }
// //       //     }
// //       //   },
// //       //   {
// //       //     id: "userPaymentMethod",
// //       //     label: "Paid Using",
// //       //     customCell: (r, i) => {
// //       //       if (r.userPaymentMethod) {
// //       //         return (
// //       //           <td className="table-custom-text">{r.userPaymentMethod}</td>
// //       //         );
// //       //       } else if (r.manual) {
// //       //         return <td className="table-custom-text">Offline</td>;
// //       //       } else if (r.stripe) {
// //       //         return <td className="table-custom-text">Credit Card</td>;
// //       //       } else if (r.stripe_transaction_id?.includes("pi_")) {
// //       //         return <td className="table-custom-text">Credit Card</td>;
// //       //       } else {
// //       //         return <td className="table-custom-text">Offline</td>;
// //       //       }
// //       //     }
// //       //   },
// //       //   {
// //       //     id: "status",
// //       //     label: "Status",
// //       //     customCell: (r, i) => {
// //       //       if (r.type === "Invoice") {
// //       //         return (
// //       //           <td className="green">
// //       //             <span
// //       //               style={{
// //       //                 minWidth: "100px",
// //       //                 background: "#1bc88a",
// //       //                 padding: "3px",
// //       //                 borderRadius: "13px",
// //       //                 color: "white",
// //       //                 display: "flex",
// //       //                 justifyContent: "center",
// //       //                 alignItems: "center",
// //       //                 fontFamily: "Open Sans",
// //       //                 fontSize: "12px",
// //       //                 fontWeight: 600
// //       //               }}
// //       //             >
// //       //               {r.invoice_status === "Paid Offline"
// //       //                 ? `Paid (Manual)`
// //       //                 : "Paid"}
// //       //             </span>
// //       //           </td>
// //       //         );
// //       //       }

// //       //       return this.orderStatus(r, i);
// //       //     }
// //       //   },
// //       //   {
// //       //     id: "actio",
// //       //     label: "",
// //       //     customStyle: { width: 80 },
// //       //     customCell: (r, i) => (
// //       //       <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
// //       //         {/* {r.manual && !r.paid && (
// //       //           <button
// //       //             onClick={_ => this.markAsPaid(r)}
// //       //             className="table-action-button"
// //       //           >
// //       //             Mark as Paid
// //       //           </button>
// //       //         )} */}
// //       //         {/* <button className="table-action-button">VIEW</button> */}

// //       //         <div
// //       //         // style={{
// //       //         //   position: "absolute",
// //       //         //   right: "15px",
// //       //         //   top: "5px"
// //       //         // }}
// //       //         >
// //       //           <Dropdown
// //       //             trigger={["click"]}
// //       //             overlay={
// //       //               r.type === "Invoice"
// //       //                 ? this.invoiceMenu(r, i)
// //       //                 : this.menu(r, i)
// //       //             }
// //       //             animation="slide-up"
// //       //           >
// //       //             <button
// //       //               className="table-action-button"
// //       //               style={{
// //       //                 cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
// //       //               }}
// //       //               disabled={this.canSubUserEdit()}
// //       //             >
// //       //               Action
// //       //             </button>
// //       //           </Dropdown>
// //       //         </div>
// //       //       </td>
// //       //     )
// //       //   }
// //       // ]
// //     };
// //   };

// //   // oldheadings = () => {
// //   //   return {
// //   //     Orders: [
// //   //       {
// //   //         id: "amount",
// //   //         label: "Date",
// //   //         customCell: (r, i) => {
// //   //           if (r.type !== "Invoice") {
// //   //             const createdDate = new Date(r.created * 1000);

// //   //             const local = DateTime.fromSeconds(r.created);
// //   //             const rezoned = local.setZone(
// //   //               this.global.organizationInfo.timezone
// //   //             );
// //   //             return (
// //   //               <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
// //   //             );
// //   //           }
// //   //           if (r.type === "Invoice") {
// //   //             const createdDate = new Date(r.createdAt * 1000);

// //   //             const local = DateTime.fromSeconds(
// //   //               new Date(r.createdAt).getTime()
// //   //             );
// //   //             const rezoned = local.setZone(
// //   //               this.global.organizationInfo.timezone
// //   //             );
// //   //             return (
// //   //               <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
// //   //             );
// //   //           }
// //   //         }
// //   //       },
// //   //       {
// //   //         id: "item",
// //   //         label: "Item",
// //   //         customCell: (r, i) => {
// //   //           if (r.type !== "Invoice") {
// //   //             return (
// //   //               <td className="table-custom-text">
// //   //                 {r.product_table === "partner_programs" &&
// //   //                   this.showProgramPlan(r)}
// //   //                 {r.product_table === "partner_event" && this.showEventPlan(r)}
// //   //                 {r.product_table === "partner_memberships" &&
// //   //                   this.showMembershipPlan(r)}
// //   //                 {r.product_table === "partner_online" &&
// //   //                   this.showProgramPlan(r)}
// //   //               </td>
// //   //             );
// //   //           }
// //   //           if (r.type === "Invoice") {
// //   //             return (
// //   //               <td className="table-custom-text">
// //   //                 Invoice ${parseFloat(r.amount / 100).toFixed(2)}
// //   //               </td>
// //   //             );
// //   //           }
// //   //         }
// //   //       },
// //   //       {
// //   //         id: "amountOverdue",
// //   //         label: "Sub-Total",
// //   //         customCell: (r, i) => {
// //   //           if (r.type !== "Invoice") {
// //   //             this.displaySubTotal(r, i);
// //   //           }
// //   //           if (r.type === "Invoice") {
// //   //             return (
// //   //               <td className="table-custom-text">
// //   //                 ${parseFloat(r.itemAmount / 100).toFixed(2)}
// //   //               </td>
// //   //             );
// //   //           }
// //   //         }
// //   //       },
// //   //       // {
// //   //       //   id: "tax",
// //   //       //   label: "Tax %",
// //   //       //   customCell: (r, i) =>this.displayTaxOrders(r)
// //   //       // },
// //   //       {
// //   //         id: "amountOverdue",
// //   //         label: "Total", // Both Invoice and orders have the same field
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">
// //   //             ${parseFloat(r.amount / 100).toFixed(2)}
// //   //           </td>
// //   //         )
// //   //       },
// //   //       {
// //   //         id: "amountOverdue",
// //   //         label: "Purchaser",
// //   //         customCell: (r, i) => {
// //   //           if (r.type !== "Invoice") {
// //   //             return <td className="table-custom-text">{r.customer_name}</td>;
// //   //           }
// //   //           if (r.type === "Invoice") {
// //   //             return <td className="table-custom-text">{r.displayName}</td>;
// //   //           }
// //   //         }
// //   //       },
// //   //       {
// //   //         id: "status",
// //   //         label: "Status",
// //   //         customCell: (r, i) => {
// //   //           if (r.type !== "Invoice") {
// //   //             return this.orderStatus;
// //   //           }
// //   //           if (r.type === "Invoice") {
// //   //             return <td className="table-custom-text">{r.invoice_status}</td>;
// //   //           }
// //   //         }
// //   //       },
// //   //       {
// //   //         id: "actio",
// //   //         label: "",
// //   //         customStyle: { width: 80 },
// //   //         customCell: (r, i) => (
// //   //           <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
// //   //             {/* {r.manual && !r.paid && (
// //   //               <button
// //   //                 onClick={_ => this.markAsPaid(r)}
// //   //                 className="table-action-button"
// //   //               >
// //   //                 Mark as Paid
// //   //               </button>
// //   //             )} */}
// //   //             {/* <button className="table-action-button">VIEW</button> */}

// //   //             <div
// //   //             // style={{
// //   //             //   position: "absolute",
// //   //             //   right: "15px",
// //   //             //   top: "5px"
// //   //             // }}
// //   //             >
// //   //               <Dropdown
// //   //                 trigger={["click"]}
// //   //                 overlay={this.menu(r, i)}
// //   //                 animation="slide-up"
// //   //               >
// //   //                 <button
// //   //                   className="table-action-button"
// //   //                   style={{
// //   //                     cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
// //   //                   }}
// //   //                   disabled={this.canSubUserEdit()}
// //   //                 >
// //   //                   Action
// //   //                 </button>
// //   //               </Dropdown>
// //   //             </div>
// //   //           </td>
// //   //         )
// //   //       }
// //   //     ],
// //   //     "Installments Orders": [
// //   //       {
// //   //         id: "amount",
// //   //         label: "Date",
// //   //         customCell: (r, i) => {
// //   //           const createdDate = new Date(r.created * 1000);
// //   //           const local = DateTime.fromSeconds(r.created);
// //   //           const rezoned = local.setZone(
// //   //             this.global.organizationInfo.timezone
// //   //           );

// //   //           return (
// //   //             <td className="table-custom-text">
// //   //               {/* {format(
// //   //                 new Date(
// //   //                   createdDate.getTime() +
// //   //                   Math.abs(createdDate.getTimezoneOffset() * 60000)
// //   //                 ),
// //   //                 "LLL dd, yyyy"
// //   //               )} */}
// //   //               {rezoned.toFormat("DD")}
// //   //             </td>
// //   //           );
// //   //         }
// //   //       },
// //   //       // {
// //   //       //   id: "tax",
// //   //       //   label: "Tax %",
// //   //       //   customCell: (r, i) =>this.displayTaxInstallments(r)
// //   //       // },
// //   //       {
// //   //         id: "amountPaid",
// //   //         label: "Amnt Paid",
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">
// //   //             $
// //   //             {parseFloat(
// //   //               r.payments.reduce((p, c) => p + parseFloat(c.amount), 0) //  * 1.13
// //   //             ).toFixed(2)}
// //   //           </td>
// //   //         )
// //   //       },
// //   //       {
// //   //         id: "amountPaid",
// //   //         label: "Installment #",
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">
// //   //             {r.payments.length} of {r.no_payments} payments
// //   //           </td>
// //   //         )
// //   //       },
// //   //       {
// //   //         id: "amountPaid",
// //   //         label: "Amnt Remaining",
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">
// //   //             $
// //   //             {parseFloat(
// //   //               r.installment_amount - // * 1.13
// //   //                 r.payments.reduce(
// //   //                   (p, c) => p + parseFloat(c.amount), // * 1.13
// //   //                   0
// //   //                 )
// //   //             ).toFixed(2)}
// //   //           </td>
// //   //         )
// //   //       },
// //   //       {
// //   //         //  * 1.13
// //   //         id: "amountOverdue",
// //   //         label: "Total",
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">
// //   //             ${parseFloat(r.installment_amount).toFixed(2)}
// //   //           </td>
// //   //         )
// //   //       },
// //   //       {
// //   //         id: "amountOverdue",
// //   //         label: "Purchaser",
// //   //         customCell: (r, i) => <td className="table-custom-text">{r.buyer}</td>
// //   //       },
// //   //       {
// //   //         id: "amountOverdue",
// //   //         label: "Status",
// //   //         customCell: (row, i) => (
// //   //           <td key={`id-${i}`}>
// //   //             <span className="green table-custom-text">
// //   //               Paid{" "}
// //   //               {row.payments[row.payments.length - 1].invoice.charge
// //   //                 .amount_refunded !== 0 && "(Refunded)"}
// //   //             </span>
// //   //           </td>
// //   //         )
// //   //       },
// //   //       {
// //   //         id: "actio",
// //   //         label: "",
// //   //         customStyle: { width: 80 },
// //   //         customCell: (r, i) => (
// //   //           <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
// //   //             <div>
// //   //               <Dropdown
// //   //                 trigger={["click"]}
// //   //                 overlay={this.menu(r, i)}
// //   //                 animation="slide-up"
// //   //               >
// //   //                 <button
// //   //                   className="table-action-button"
// //   //                   style={{
// //   //                     cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
// //   //                   }}
// //   //                   disabled={this.canSubUserEdit()}
// //   //                 >
// //   //                   Action
// //   //                 </button>
// //   //               </Dropdown>
// //   //             </div>
// //   //           </td>
// //   //         )
// //   //       }
// //   //     ],
// //   //     Invoices: [
// //   //       {
// //   //         id: "amount",
// //   //         label: "Date",
// //   //         customCell: (r, i) => {
// //   //           const createdDate = new Date(r.createdAt * 1000);

// //   //           const local = DateTime.fromSeconds(new Date(r.createdAt).getTime());
// //   //           const rezoned = local.setZone(
// //   //             this.global.organizationInfo.timezone
// //   //           );
// //   //           return (
// //   //             <td className="table-custom-text">{rezoned.toFormat("DD")}</td>
// //   //           );
// //   //         }
// //   //       },
// //   //       {
// //   //         id: "item",
// //   //         label: "Item",
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">
// //   //             Invoice ${parseFloat(r.amount / 100).toFixed(2)}
// //   //           </td>
// //   //         )
// //   //       },
// //   //       {
// //   //         id: "amountOverdue",
// //   //         label: "Sub-Total",
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">
// //   //             ${parseFloat(r.itemAmount / 100).toFixed(2)}
// //   //           </td>
// //   //         )
// //   //       },
// //   //       // {
// //   //       //   id: "tax",
// //   //       //   label: "Tax %",
// //   //       //   customCell: (r, i) =>this.displayTaxOrders(r)
// //   //       // },
// //   //       {
// //   //         id: "amountOverdue",
// //   //         label: "Total",
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">
// //   //             ${parseFloat(r.amount / 100).toFixed(2)}
// //   //           </td>
// //   //         )
// //   //       },
// //   //       {
// //   //         id: "amountOverdue",
// //   //         label: "Purchaser",
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">{r.displayName}</td>
// //   //         )
// //   //       },
// //   //       {
// //   //         id: "status",
// //   //         label: "Status",
// //   //         customCell: (r, i) => (
// //   //           <td className="table-custom-text">{r.invoice_status}</td>
// //   //         )
// //   //       },
// //   //       {
// //   //         id: "actio",
// //   //         label: "",
// //   //         customStyle: { width: 80 },
// //   //         customCell: (r, i) => (
// //   //           <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
// //   //             <div>
// //   //               <Dropdown
// //   //                 trigger={["click"]}
// //   //                 overlay={this.invoiceMenu(r, i)}
// //   //                 animation="slide-up"
// //   //               >
// //   //                 <button
// //   //                   className="table-action-button"
// //   //                   style={{
// //   //                     cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
// //   //                   }}
// //   //                   disabled={this.canSubUserEdit()}
// //   //                 >
// //   //                   Action
// //   //                 </button>
// //   //               </Dropdown>
// //   //             </div>
// //   //           </td>
// //   //         )
// //   //       }
// //   //     ]
// //   //   };
// //   // };

// //   orderStatus = (row, i) => {
// //     const isRefunded =
// //       !row.amount_refunded || row.amount_refunded === 0 ? false : true;

// //     return (
// //       <td key={`id-${i}`}>
// //         {row.paid ? (
// //           <span
// //             className="green"
// //             style={{
// //               minWidth: "100px",
// //               background: "#1bc88a",
// //               padding: "3px",
// //               borderRadius: "13px",
// //               color: "white",
// //               display: "flex",
// //               justifyContent: "center",
// //               alignItems: "center",
// //               fontFamily: "Open Sans",
// //               fontSize: "12px",
// //               fontWeight: 600
// //             }}
// //           >
// //             Paid{" "}
// //             {/* {row.manual &&
// //               !row.is_manual_stripe &&
// //               row.amount != 0 &&
// //               "(Manual)"} */}
// //             {isRefunded && (
// //               <span>{row.amount_refunded === 0 ? "" : " (Refunded)"}</span>
// //             )}
// //           </span>
// //         ) : (
// //           <div style={{ textAlign: "right", display: "flex" }}>
// //             <span
// //               className="red"
// //               style={{
// //                 minWidth: "100px",
// //                 background: "rgb(33 91 255)",
// //                 padding: "3px",
// //                 borderRadius: "13px",
// //                 color: "white",
// //                 display: "flex",
// //                 justifyContent: "center",
// //                 alignItems: "center",
// //                 fontFamily: "Open Sans",
// //                 fontSize: "12px",
// //                 fontWeight: 600
// //               }}
// //             >
// //               {row.status === "Processing" ? "Processing" : "Pending"}
// //             </span>
// //           </div>
// //         )}
// //         {/* {isRefunded && (
// //           <span>{row.amount_refunded === 0 ? "" : "(Refunded)"}</span>
// //         )} */}
// //       </td>
// //     );
// //   };

// //   /**
// //    * This function handles the edit button functionality of a sub user
// //    * returns a boolean value
// //    */
// //   canSubUserEdit = () => {
// //     if (!this.global.dsUser.accesspoints) {
// //       return false;
// //     }
// //     if (this.global.dsUser.accesspoints) {
// //       return this.global.dsUser.accesspoints.Financials["Paid Orders"].edit
// //         ? false
// //         : true;
// //     }
// //   };

// //   displaySubTotal = (r, i) => {
// //     const tableName = r.product_table;
// //     if (tableName === "partner_programs") {
// //       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
// //         ? JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage
// //         : 0;

// //       const plan = JSON.parse(r.product.plans[r.planId]);

// //       const price = !plan.type
// //         ? parseFloat(plan.tuition_rate)
// //         : r.drop_in_dates
// //         ? (plan.total_price / plan.dayCount) *
// //           r.selectedDays.length *
// //           (1 + parseInt(plan.dropin_interest) / 100)
// //         : parseFloat(plan.total_price) *
// //           (r.session_quantity ? r.session_quantity : 1);
// //       // console.log('price', price);
// //       let couponDiscount = 0;
// //       // Coupon Applied
// //       if (r.coupon) {
// //         couponDiscount =
// //           r.coupon.type === "Percentage"
// //             ? parseFloat(
// //                 parseFloat(price) * (parseFloat(r.coupon.amount_off) / 100)
// //               )
// //             : parseFloat(r.coupon.amount_off);
// //       }
// //       if (r.drop_in_dates && r.coupon) {
// //         const plan = JSON.parse(r.product.plans[r.planId]);

// //         couponDiscount =
// //           r.coupon.type === "Percentage"
// //             ? parseFloat(
// //                 parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
// //                   (1 + parseInt(plan.dropin_interest) / 100) *
// //                   (parseFloat(r.coupon.amount_off) / 100)
// //               )
// //             : parseFloat(r.coupon.amount_off);
// //       }

// //       return (
// //         <td
// //           key={i}
// //           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
// //         >
// //           ${parseFloat(price - couponDiscount).toFixed(2)}
// //         </td>
// //       );
// //     } else if (tableName === "partner_event") {
// //       const taxPercentage = JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
// //         ? JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage
// //         : 0;

// //       const plan = JSON.parse(r.product.tickets[r.planId]);
// //       const ticketPrice = parseFloat(plan.ticket_price);
// //       let couponDiscount = 0;
// //       // Coupon Applied
// //       if (r.coupon) {
// //         couponDiscount =
// //           r.coupon.type === "Percentage"
// //             ? parseFloat(
// //                 parseFloat(ticketPrice) *
// //                   (parseFloat(r.coupon.amount_off) / 100)
// //               )
// //             : parseFloat(r.coupon.amount_off);
// //       }

// //       return (
// //         <td
// //           key={i}
// //           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
// //         >
// //           ${parseFloat(ticketPrice - couponDiscount).toFixed(2)}
// //         </td>
// //       );
// //     } else if (tableName === "partner_memberships") {
// //       const taxPercentage = JSON.parse(r.product.prices[r.planId]).isTaxEnabled
// //         ? JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage
// //         : 0;

// //       const plan = JSON.parse(r.product.prices[r.planId]);
// //       const pricePerTerm = parseFloat(plan.price_per_term);
// //       let couponDiscount = 0;
// //       // Coupon Applied
// //       if (r.coupon) {
// //         couponDiscount =
// //           r.coupon.type === "Percentage"
// //             ? parseFloat(
// //                 parseFloat(pricePerTerm) *
// //                   (parseFloat(r.coupon.amount_off) / 100)
// //               )
// //             : parseFloat(r.coupon.amount_off);
// //       }
// //       return (
// //         <td
// //           key={i}
// //           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
// //         >
// //           ${parseFloat(pricePerTerm - couponDiscount).toFixed(2)}
// //         </td>
// //       );
// //     } else if (tableName === "partner_online") {
// //       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
// //         ? JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage
// //         : 0;

// //       const plan = JSON.parse(r.product.plans[r.planId]);

// //       const price = !plan.type
// //         ? parseFloat(plan.tuition_rate)
// //         : r.drop_in_dates
// //         ? (plan.total_price / plan.dayCount) *
// //           r.selectedDays.length *
// //           (1 + parseInt(plan.dropin_interest) / 100)
// //         : parseFloat(plan.total_price) *
// //           (r.session_quantity ? r.session_quantity : 1);

// //       let couponDiscount = 0;
// //       // Coupon Applied
// //       if (r.coupon) {
// //         couponDiscount =
// //           r.coupon.type === "Percentage"
// //             ? parseFloat(
// //                 parseFloat(price) * (parseFloat(r.coupon.amount_off) / 100)
// //               )
// //             : parseFloat(r.coupon.amount_off);
// //       }

// //       if (r.drop_in_dates && r.coupon) {
// //         const plan = JSON.parse(r.product.plans[r.planId]);

// //         couponDiscount =
// //           r.coupon.type === "Percentage"
// //             ? parseFloat(
// //                 parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
// //                   (1 + parseInt(plan.dropin_interest) / 100) *
// //                   (parseFloat(r.coupon.amount_off) / 100)
// //               )
// //             : parseFloat(r.coupon.amount_off);
// //       }

// //       return (
// //         <td
// //           key={i}
// //           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
// //         >
// //           ${parseFloat(price - couponDiscount).toFixed(2)}
// //         </td>
// //       );
// //     }
// //   };

// //   displayTaxOrders = (r, i) => {
// //     const tableName = r.product_table;
// //     if (tableName === "partner_programs") {
// //       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
// //         ? `${JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage}%`
// //         : "0%";
// //       return (
// //         <td
// //           key={i}
// //           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
// //         >
// //           {taxPercentage}
// //         </td>
// //       );
// //     } else if (tableName === "partner_event") {
// //       const taxPercentage = JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
// //         ? `${JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage}%`
// //         : "0%";
// //       return (
// //         <td
// //           key={i}
// //           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
// //         >
// //           {taxPercentage}
// //         </td>
// //       );
// //     } else if (tableName === "partner_memberships") {
// //       const taxPercentage = JSON.parse(r.product.prices[r.planId]).isTaxEnabled
// //         ? `${JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage}%`
// //         : "0%";
// //       return (
// //         <td
// //           key={i}
// //           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
// //         >
// //           {taxPercentage}
// //         </td>
// //       );
// //     } else if (tableName === "partner_online") {
// //       const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
// //         ? `${JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage}%`
// //         : "0%";
// //       return (
// //         <td
// //           key={i}
// //           style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
// //         >
// //           {taxPercentage}
// //         </td>
// //       );
// //     }
// //   };

// //   displayTaxInstallments = (r, i) => {
// //     const taxPercentage = r.payments[0].invoice.tax_percent
// //       ? r.payments[0].invoice.tax_percent
// //       : 0;

// //     return (
// //       <td
// //         key={i}
// //         style={{ fontSize: "13px", fontFamily: "Open Sans, sans-serif" }}
// //       >
// //         {taxPercentage}%
// //       </td>
// //     );
// //   };

// //   onSelect = async (r, i, event) => {
// //     if (event.key === "MarkPaid") {
// //       this.markAsPaid(r);
// //     }
// //     if (event.key === "MarkUnpaid") {
// //       this.markAsUnpaid(r);
// //     }
// //     if (event.key === "refund") {
// //       if (r.installment_amount) {
// //         this.setState({
// //           openRefundModal: true,
// //           selectedItem: r.payments[r.payments.length - 1].invoice,
// //           usage: "recurring_payments"
// //         });
// //         return;
// //       }
// //       this.setState({
// //         openRefundModal: true,
// //         selectedItem: r,
// //         usage: "paid_orders"
// //       });
// //     }
// //     if (event.key === "emailClient") {
// //       this.setState({ openEmailClientModal: true, selectedItem: r });
// //     }

// //     if (event.key === "editInvoice") {
// //       console.log("EDIT DETAILS", r);
// //       this.props.history.push({
// //         pathname: `/partners/orders/invoice-builder/${r.id}`,
// //         state: { isNewCustomer: false }
// //       });
// //     }

// //     if (event.key === "viewDetails") {
// //       console.log("VIEW DETAILS", r);
// //       this.props.history.push(`orders/orders-receipt/${r.orderId}`);
// //       // this.setState({ openViewDetailsModal: true, selectedItem: r });
// //     }
// //   };

// //   menu = (r, i) => {
// //     return (
// //       <Menu onClick={event => this.onSelect(r, i, event)}>
// //         {r.manual && !r.is_manual_stripe && !r.paid && (
// //           <MenuItem key="MarkPaid">Mark as Paid</MenuItem>
// //         )}
// //         {r.manual && !r.is_manual_stripe && r.paid && (
// //           <MenuItem key="MarkUnpaid">Mark as Unpaid</MenuItem>
// //         )}
// //         {!r.manual && r.status !== "Processing" && (
// //           <MenuItem key="refund">Refund</MenuItem>
// //         )}
// //         {r.manual && r.status !== "Processing" && r.is_manual_stripe && (
// //           <MenuItem key="refund">Refund</MenuItem>
// //         )}
// //         {!r.manual && <MenuItem key="emailClient">Email Client</MenuItem>}
// //         {r.invoice_status === "Issued" &&
// //         r.is_manual_stripe &&
// //         r.status !== "Paid" ? (
// //           <MenuItem key="editInvoice">Edit Invoice</MenuItem>
// //         ) : (
// //           <MenuItem key="viewDetails">View Details</MenuItem>
// //         )}
// //       </Menu>
// //     );
// //   };

// //   onInvoiceSelect = async (r, i, event) => {
// //     if (event.key === "viewDetails") {
// //       console.log("VIEW DETAILS", r);

// //       this.props.history.push({
// //         pathname: `orders/invoice-builder/${r.id}`,
// //         state: { isNewCustomer: false }
// //       });
// //     }

// //     if (event.key === "refund") {
// //       if (r.installment_amount) {
// //         this.setState({
// //           openRefundModal: true,
// //           selectedItem: r.payments[r.payments.length - 1].invoice,
// //           usage: "recurring_payments"
// //         });
// //         return;
// //       }

// //       this.setState({
// //         openRefundModal: true,
// //         selectedItem: r,
// //         usage: "paid_orders"
// //       });
// //     }

// //     if (event.key === "delete-invoice") {
// //       this.setState({
// //         openDeleteInvoiceConfirmationModal: true,
// //         selectedItem: r,
// //         usage: "paid_orders"
// //       });
// //     }
// //   };

// //   invoiceMenu = (r, i) => {
// //     return (
// //       <Menu onClick={event => this.onInvoiceSelect(r, i, event)}>
// //         {/* {r.manual && !r.paid && (
// //           <MenuItem key="MarkPaid">Mark as Paid</MenuItem>
// //         )}
// //         {r.manual && r.paid && (
// //           <MenuItem key="MarkUnpaid">Mark as Unpaid</MenuItem>
// //         )}
// //         {!r.manual && <MenuItem key="emailClient">Email Client</MenuItem>} */}
// //         {!r.userPaymentMethod === "Credit Card" && (
// //           <MenuItem key="refund">Refund</MenuItem>
// //         )}
// //         <MenuItem key="viewDetails">View Details</MenuItem>
// //         {r.tab === "Pending" &&
// //           r.invoice_status !== "Issued" &&
// //           r.status !== "Processing" && (
// //             <MenuItem key="delete-invoice">Delete Invoice</MenuItem>
// //           )}
// //       </Menu>
// //     );
// //   };

// //   showProgramPlan = r => {
// //     // if (r.planId === null) {
// //     //   return null;
// //     // }
// //     if (r.plan === null) {
// //       return null;
// //     }
// //     // const plan = JSON.parse(r.product.plans[r.planId]);
// //     const plan = r.plan;
// //     const objConv = {
// //       week: "Weekly",
// //       "bi-week": "Bi-Weekly",
// //       month: "Monthly"
// //     };
// //     return (
// //       <span>
// //         {plan.type
// //           ? r.drop_in_dates
// //             ? `Semester Drop-in - $${parseFloat(
// //                 (plan.total_price / plan.dayCount) *
// //                   (1 + parseInt(plan.dropin_interest) / 100)
// //               ).toFixed(0)}`
// //             : plan.installments
// //             ? `Semester - Inst - $${parseFloat(
// //                 (plan.total_price / plan.installments_breakdown) *
// //                   (1 + parseInt(plan.installments_interest) / 100)
// //               ).toFixed(0)} - ${plan.installments_plan}`
// //             : plan.type === "Single Sessions"
// //             ? `Single Sessions - $${plan.total_price} x(${r.session_quantity})`
// //             : `Semester - $${plan.total_price}`
// //           : `Recurring - $${plan.tuition_rate} / ${
// //               objConv[plan.billing_cycle]
// //             }, \n ${plan.timing[0]} to ${plan.timing[1]}`}
// //       </span>
// //     );
// //   };

// //   showEventPlan = r => {
// //     const plan = JSON.parse(r.product.tickets[r.planId]);
// //     const ticketName =
// //       plan.ticket_status === "Paid" ? "Standard" : plan.ticket_status;
// //     const ticketPrice = plan.ticket_price ? `$${plan.ticket_price}` : "Free";
// //     return (
// //       <span>
// //         {ticketName} Ticket / {ticketPrice}
// //       </span>
// //     );
// //   };

// //   showMembershipPlan = r => {
// //     const plan = JSON.parse(r.product.prices[r.planId]);
// //     const membershipTerm = plan.membership_term;
// //     const renewalStatus = plan.auto_renew
// //       ? "Auto Renews"
// //       : "Standard Membership";
// //     const pricePerTerm = plan.price_per_term;
// //     return (
// //       <span>
// //         {membershipTerm} Month Term - {renewalStatus} - {pricePerTerm}
// //       </span>
// //     );
// //   };

// //   amountCell = r => {
// //     return (
// //       <td key={`${r.id}-amt`} className="table-custom-text">
// //         ${parseFloat(r.amount).toFixed(2)}
// //       </td>
// //     );
// //   };

// //   servicePeriodCell = r => {
// //     if (!r.servicePeriodStart) return <td key={`${r.id}-sp`}>N/A</td>;
// //     return (
// //       <td key={`${r.id}-sp`} className="table-custom-text">
// //         {format(new Date(r.servicePeriodStart), "LLL d, yyyy")} -{" "}
// //         {format(new Date(r.servicePeriodEnd), "LLL d, yyyy")}
// //       </td>
// //     );
// //   };

// //   customDueDate = r => {
// //     return (
// //       <td key={`${r.id}-dd`} className="table-custom-text">
// //         {format(new Date(r.dueDate), "LLL d, yyyy")}
// //       </td>
// //     );
// //   };

// //   async markAsPaid(r) {
// //     const ep = `${process.env.REACT_APP_API}/partners/orders/mark_paid`;
// //     const res = await axios.put(ep, r);
// //     if (res.data.success) {
// //       this.fetchInitialData();
// //       // this.setState({
// //       //   data: {
// //       //     ...this.state.data,
// //       //     Orders: this.state.data.Orders.map(e =>
// //       //       e.id === r.id ? { ...e, paid: true } : e
// //       //     )
// //       //   }
// //       // });
// //     }
// //   }

// //   async markAsUnpaid(r) {
// //     const ep = `${process.env.REACT_APP_API}/partners/orders/mark_unpaid`;
// //     const res = await axios.put(ep, r);

// //     if (res.data.success) {
// //       this.fetchInitialData();
// //     }
// //   }

// //   async componentDidMount() {
// //     this.fetchInitialData();

// //     // Testing Using Dummy Data:
// //     // this.setState({
// //     //   data: {
// //     //     Orders: [
// //     //       {
// //     //         amount: 62150,
// //     //         created: 1597248282,
// //     //         customer_name: "test",
// //     //         id: 50,
// //     //         manual: true,
// //     //         paid: false,
// //     //         status: null
// //     //       }
// //     //     ]
// //     //   }
// //     // });
// //   }

// //   fetchInitialData = async () => {
// //     this.setGlobal({ loading: true });
// //     const ep = `${process.env.REACT_APP_API}/partners/orders`;
// //     const res = await axios.get(ep);

// //     console.log("res", res.data);
// //     if (res.data.success) {
// //       const ordersInvoicePaid = res.data.data.Invoices.invoices
// //         .filter(e => e.status === "Paid")
// //         .map(e => {
// //           let item = e;
// //           item.created = new Date(e.createdAt).getTime();
// //           item.tab = "Payments";
// //           return item;
// //         });
// //       const ordersInvoicePending = res.data.data.Invoices.invoices
// //         .filter(e => e.status !== "Paid")
// //         .map(e => {
// //           let item = e;
// //           item.created = new Date(e.createdAt).getTime();
// //           item.tab = "Pending";
// //           return item;
// //         });
// //       let orderChargesPaid =
// //         res.data.data.AllPayouts?.charges
// //           ?.filter(e => e.paid || e.status === "Processing")
// //           .sort((a, b) => b.created - a.created) || [];
// //       if (orderChargesPaid.length > 0) {
// //         orderChargesPaid.tab = "Payments";
// //       }
// //       let orderChargesPending =
// //         res.data.data.AllPayouts?.charges
// //           ?.filter(
// //             e => !e.paid && !(e.status === "Processing") && e.status !== null
// //           )
// //           .sort((a, b) => b.created - a.created) || [];
// //       console.log("orderChargesPending", orderChargesPending);
// //       console.log("orderChargesPaid", orderChargesPaid);
// //       if (orderChargesPending.length > 0) {
// //         orderChargesPending.tab = "Payments";
// //       }
// //       let stripeInstallments = res.data.data.AllInstallments
// //         ? res.data.data.AllInstallments?.charges?.sort(
// //             (a, b) => b.created - a.created
// //           ) || []
// //         : [];
// //       if (stripeInstallments.length > 0) {
// //         stripeInstallments.tab = "Payments";
// //       }

// //       const payments = [
// //         ...orderChargesPaid,
// //         ...ordersInvoicePaid,
// //         ...stripeInstallments
// //       ].sort((a, b) => new Date(b.created * 1000) - new Date(a.created * 1000));
// //       const pending = [...ordersInvoicePending, ...orderChargesPending].sort(
// //         (a, b) => new Date(b.created * 1000) - new Date(a.created * 1000)
// //       );

// //       console.log({
// //         data: {
// //           Pending: pending,
// //           Payments: payments
// //           // Orders: res.data.data.AllPayouts
// //           //   ? res.data.data.AllPayouts?.charges?.sort(
// //           //       (a, b) => b.created - a.created
// //           //     ) || []
// //           //   : [],
// //           // "Installments Orders": res.data.data.AllInstallments
// //           //   ? res.data.data.AllInstallments?.charges?.sort(
// //           //       (a, b) => b.created - a.created
// //           //     ) || []
// //           //   : [],
// //           // Invoices: res.data.data.Invoices.sort(
// //           //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
// //           // )
// //         },
// //         earnings:
// //           orderChargesPaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0) +
// //           stripeInstallments.reduce(
// //             (p, c) => p + parseFloat(c.installment_amount), //  * 1.13
// //             0
// //           ) +
// //           ordersInvoicePaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0)
// //       });
// //       this.setState({
// //         data: {
// //           Pending: pending,
// //           Payments: payments
// //           // Orders: res.data.data.AllPayouts
// //           //   ? res.data.data.AllPayouts?.charges?.sort(
// //           //       (a, b) => b.created - a.created
// //           //     ) || []
// //           //   : [],
// //           // "Installments Orders": res.data.data.AllInstallments
// //           //   ? res.data.data.AllInstallments?.charges?.sort(
// //           //       (a, b) => b.created - a.created
// //           //     ) || []
// //           //   : [],
// //           // Invoices: res.data.data.Invoices.sort(
// //           //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
// //           // )
// //         },
// //         earnings:
// //           orderChargesPaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0) +
// //           stripeInstallments.reduce(
// //             (p, c) => p + parseFloat(c.installment_amount), //  * 1.13
// //             0
// //           ) +
// //           ordersInvoicePaid.reduce((p, c) => p + parseFloat(c.amount / 100), 0)
// //       });
// //     }
// //     this.setGlobal({ loading: false });
// //   };

// //   render() {
// //     console.log("this.props", this.props);

// //     return (
// //       <>
// //         {this.state.openCreateCustomerModal && (
// //           <CreateCustomerModal
// //             createNewCustomer={_ =>
// //               this.setState({ openCreateCustomerModal: true })
// //             }
// //             onClose={_ => {
// //               this.setState({ openCreateCustomerModal: false });
// //             }}
// //           />
// //         )}
// //         {this.state.openInvoiceCustomerModal && (
// //           <InvoiceCustomerModal
// //             createNewCustomer={_ =>
// //               this.setState({ openCreateCustomerModal: true })
// //             }
// //             onClose={_ => {
// //               this.setState({ openInvoiceCustomerModal: false });
// //             }}
// //           />
// //         )}
// //         {this.state.openViewDetailsModal && (
// //           <PaidOrdersModal
// //             selectedItem={this.state.selectedItem}
// //             onClose={_ => {
// //               this.setState({ openViewDetailsModal: false });
// //             }}
// //           />
// //         )}
// //         {this.state.openRefundModal && (
// //           <RefundModal
// //             selectedItem={this.state.selectedItem}
// //             amountRefunded={
// //               this.state.usage === "paid_orders"
// //                 ? this.state.selectedItem.amount_refunded
// //                 : this.state.selectedItem.charge.amount_refunded
// //             }
// //             post_payment_credit_notes_amount={
// //               this.state.selectedItem.invoice
// //                 ? this.state.selectedItem.invoice
// //                     .post_payment_credit_notes_amount
// //                 : this.state.selectedItem.post_payment_credit_notes_amount
// //             }
// //             onClose={_ => {
// //               // this.fetchInitialData();
// //               this.fetchInitialData();
// //               this.setState({ openRefundModal: false });
// //             }}
// //             usage={this.state.usage}
// //           />
// //         )}
// //         {this.state.openEmailClientModal && (
// //           <EmailClientModal
// //             selectedItem={this.state.selectedItem}
// //             charge={
// //               this.state.selectedItem.id ||
// //               this.state.selectedItem.payments[
// //                 this.state.selectedItem.payments.length - 1
// //               ].invoice.charge
// //             }
// //             onClose={_ => {
// //               // this.fetchInitialData();
// //               this.setState({ openEmailClientModal: false });
// //             }}
// //           />
// //         )}
// //         {this.state.openDeleteInvoiceConfirmationModal && (
// //           <DeleteInvoiceConfirmationModal
// //             selectedItem={this.state.selectedItem}
// //             confirmDelete={_ => this.fetchInitialData()}
// //             onClose={_ => {
// //               // this.fetchInitialData();
// //               this.setState({ openDeleteInvoiceConfirmationModal: false });
// //             }}
// //           />
// //         )}
// //         <div className="container-fluid">
// //           <div className="row cont">
// //             <div className="col-md-6">
// //               <h1>Order Tracking</h1>
// //             </div>

// //             <div className="col-md-6">
// //               <div className="forbtnwrap justify-end">
// //                 <div
// //                   className="forbtnapp enrollment-btn"
// //                   style={{ marginLeft: "20px" }}
// //                 >
// //                   <button
// //                     className="newapplications"
// //                     style={{
// //                       cursor: "pointer"
// //                     }}
// //                     onClick={_ => {
// //                       this.setState({ openInvoiceCustomerModal: true });
// //                     }}
// //                   >
// //                     <img
// //                       src={MiniPlus}
// //                       alt=""
// //                       style={{ alignSelf: "center" }}
// //                     />
// //                     Invoice
// //                   </button>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>

// //           <div className="cont">
// //             <div className="row">
// //               <div className="col-lg-3">
// //                 <div className="money-box mt-0">
// //                   <div className="row">
// //                     <div className="col-lg-12 col-sm-6">
// //                       <div className="money-box-inner">
// //                         <h2>ORDER EARNINGS</h2>
// //                         <h3>
// //                           {parseFloat(this.state.earnings || 0).toLocaleString(
// //                             "en-US",
// //                             {
// //                               style: "currency",
// //                               currency: "USD"
// //                             }
// //                           )}
// //                         </h3>
// //                       </div>
// //                     </div>
// //                     <div className="col-lg-12 col-sm-6">
// //                       <div className="money-box-inner">
// //                         <h2>TOTAL ORDERS</h2>
// //                         <h3>
// //                           {this.state.data.Payments.length +
// //                             this.state.data["Pending"].length}
// //                         </h3>
// //                       </div>
// //                     </div>
// //                     <div className="col-lg-12 col-sm-6">
// //                       <div className="money-box-inner">
// //                         <h2>PAID ORDERS</h2>
// //                         <h3>{this.state.data.Payments.length}</h3>
// //                       </div>
// //                     </div>
// //                     <div className="col-lg-12 col-sm-6">
// //                       <div className="money-box-inner">
// //                         <h2>PAYMENTS OWED</h2>
// //                         <h3>{this.state.data.Pending.length}</h3>
// //                       </div>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </div>
// //               <div className="col-lg-9">
// //                 <NewTabbedTable
// //                   data={this.state.data}
// //                   tabs={this.tabs}
// //                   headings={this.headings()}
// //                   actions={this.actions}
// //                 />
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </>
// //     );
// //   }
// // }

// // export default withRouter(TuitionPayments);
