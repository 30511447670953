import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import AddEnrollmentModal from "../../components/modals/AddEnrollmentModal";
import NotesModal from "../../components/modals/NotesModal";
import "../../assets/css/componentSpecificCss/eventsManageSingle.css";
import ApplicationViewModal from "../../components/modals/ApplicationViewModal";
import CancelProgramModal from "../../components/modals/CancelProgramModal";
import EventShareLinkModal from "../../components/modals/EventShareLinkModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import AddInstructorModal from "../../components/modals/AddInstructorModal";
import NudgeInviteModal from "../../components/modals/NudgeInviteModal";
import { Delete, SettingsBackupRestore as Restore } from "@material-ui/icons";
import ReactTooltip from "react-tooltip";
import ConfirmInactiveModal from "../../components/modals/ConfirmInactiveModal";
import EventAttendeeListPDF from "../../components/modals/EventAttendeeListPDF";

class ProgramList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: { event_start: null, event_end: null },
      filter: "",
      tours: [],
      original: [],
      customers: {},
      leadModal: false,
      showInstructorModal: false,
      modalEvent: {},
      showApplicationViewModal: false,
      selectedProgram: null,
      type: "",
      displayCategory: "Events",
      openCancelEventModal: false,
      openEventLinkModal: false,
      headings: {},
      showNudgeInviteModal: false,
      showConfirmArchiveInviteModal: false,
      openAttendeeListPDF: false
    };

    this.tabs = ["Attendees", "Applications", "Inactive"];
    this.actions = [{ name: "Notes", action: null }];
  }

  async componentDidMount() {
    await this.fetchInitialData();
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Manage Events", to: "/events/manage" },
        {
          label: this.state.data.event_title,
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
  }

  // If the URL changes, refetch data and rerender componenet.
  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      await this.fetchInitialData();
    }
  }

  fetchInitialData = async () => {
    const param = this.props.match.params.id;
    const ep = `${process.env.REACT_APP_API}/partners/events/${param}`;
    const customers_ep = `${process.env.REACT_APP_API}/partners/events/customers/${param}`;
    const customers = await axios.get(customers_ep);

    const res = await axios.get(ep);

    const ep2 = `${process.env.REACT_APP_API}/partners/invitations/event/${param}`;
    const res2 = await axios.get(ep2);

    customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    console.log("res:",res.data.data);
    this.setState({
      data: res.data.data,
      customers: {
        Attendees: customers.data.data.filter(
          e =>
            e.status_stage === "Accepted_Paid" ||
            e.status_stage === "Accepted_Manual" ||
            (e.status_stage === "Accepted_Unpaid" && res.data.data.manual_invoices)
        ),
        Applications: customers.data.data.filter(
          e =>
            e.status_stage === "New_Pending" ||
            (e.status_stage === "Accepted_Unpaid" && !res.data.data.manual_invoices) ||
            e.status_stage === "Waitlisted_Unpaid" ||
            e.status_stage === "Waitlisted_Paid"
        ),
        Inactive: [
          ...customers.data.data.filter(
            e =>
              e.status_stage === "Rejected" ||
              e.status_stage === "Inactive" ||
              e.status_stage === "Cancelled" ||
              e.status_stage === "Cancelled_Unpaid"
          ),
          ...res2.data.data.filter(e => !e.isArchived)
        ]
      }
    });

    this.setState({
      headings: {
        Attendees: [
          { id: "displayName", label: "Name" },
          ...(this.state.data.addChild
            ? [
                {
                  id: "childName",
                  label: "Child Name",
                  customCell: (r, i) => {
                    return <td key={i}>{r.childName}</td>;
                  }
                }
              ]
            : []),
          { id: "email", label: "Email" },
          {
            id: "start",
            label: "Ticket Type",
            customCell: (r, i) => {
              if (r.plan_id !== 0 && !r.plan_id) {
                return <td key={i}>Auto Register</td>;
              }
              const plan = JSON.parse(this.state.data.tickets[r.plan_id]);
              return <td key={i}>{plan.ticket_status}</td>;
            }
          },
          {
            id: "end",
            label: "Status",
            customCell: (r, i) => {
              return <td key={i}>Active</td>;
            }
          },
          {
            id: "order",
            label: "Ticket Price (excl. Tax)",
            customCell: (r, i) => {
              if (r.plan_id !== 0 && !r.plan_id) {
                return <td key={i}>Free</td>;
              }
              const plan = JSON.parse(this.state.data.tickets[r.plan_id]);
              return <td key={i}>${plan.ticket_price}</td>;
            }
          },
          {
            id: "actionf",
            label: "",
            customCell: (r, i) => {
              return (
                <td
                  key={`${i}-btn`}
                  style={{ whiteSpace: "nowrap", textAlign: "right" }}
                >
                  <button
                    style={{ marginRight: "15px" }}
                    onClick={_ => {
                      // this.props.history.push(`/partners/application/${r.id}`)
                      let appStatus;
                      if (r.status_stage === "Accepted_Paid" || r.status_stage === "Accepted_Manual") {
                        appStatus = "Enrolled";
                      } else if (r.status_stage === "Accepted_Unpaid" && this.state.data.manual_invoices) {
                        appStatus = "Accepted";
                      }

                      this.setState({
                        selectedProgram: r,
                        showApplicationViewModal: true,
                        type: appStatus
                      });
                    }}
                  >
                    View Details
                  </button>
                </td>
              );
            }
          }
        ],
        Applications: [
          { id: "displayName", label: "Name" },
          ...(this.state.data.addChild
            ? [
                {
                  id: "childName",
                  label: "Child Name",
                  customCell: (r, i) => {
                    return <td key={i}>{r.childName}</td>;
                  }
                }
              ]
            : []),
          { id: "email", label: "Email" },
          {
            id: "start",
            label: "Ticket Type",
            customCell: (r, i) => {
              if (r.plan_id !== 0 && !r.plan_id) {
                return <td key={i}>Auto Register</td>;
              }
              const plan = JSON.parse(this.state.data.tickets[r.plan_id]);
              return <td key={i}>{plan.ticket_status}</td>;
            }
          },
          {
            id: "status_stage",
            label: "Status",
            customCell: (r, i) => (
              <td key={i}>
                {r.status_stage === "Accepted_Unpaid"
                  ? r.application_required
                    ? "Accepted_Unpaid"
                    : "Direct_Unpaid"
                  : r.status_stage}
              </td>
            )
          },
          {
            id: "order",
            label: "Ticket Price (excl. Tax)",
            customCell: (r, i) => {
              if (r.plan_id !== 0 && !r.plan_id) {
                return <td key={i}>Free</td>;
              }
              const plan = JSON.parse(this.state.data.tickets[r.plan_id]);
              return <td key={i}>${plan.ticket_price}</td>;
            }
          },
          {
            id: "actionf",
            label: "",
            customCell: (r, i) => {
              return (
                <td
                  key={`${i}-btn`}
                  style={{ whiteSpace: "nowrap", textAlign: "right" }}
                >
                  <button
                    style={{ marginRight: "15px" }}
                    onClick={_ => {
                      // this.props.history.push(`/partners/application/${r.id}`)
                      let appStatus;
                      if (r.status_stage === "New_Pending") {
                        appStatus = "New";
                      }
                      if (r.status_stage === "Accepted_Unpaid") {
                        appStatus = "Accepted";
                      }
                      if (
                        r.status_stage === "Waitlisted_Unpaid" ||
                        r.status_stage === "Waitlisted_Paid"
                      ) {
                        appStatus = "Waitlisted";
                      }
                      if (r.status_stage === "Rejected") {
                        appStatus = "Rejected";
                      }

                      this.setState({
                        selectedProgram: r,
                        showApplicationViewModal: true,
                        type: appStatus
                      });
                    }}
                  >
                    View Details
                  </button>
                </td>
              );
            }
          }
        ],
        Inactive: [
          { id: "displayName", label: "Name" },
          ...(this.state.data.addChild
            ? [
                {
                  id: "childName",
                  label: "Child Name",
                  customCell: (r, i) => {
                    return <td key={i}>{r.childName}</td>;
                  }
                }
              ]
            : []),
          { id: "email", label: "Email" },
          // {
          //   id: "start",
          //   label: "Ticket Type",
          //   customCell: (r, i) =>{
          //     const plan = JSON.parse(this.state.data.tickets[r.plan_id]);
          //     return <td key={i}>{plan.ticket_status}</td>;
          //   }
          // },
          {
            id: "status_stage",
            label: "Status",
            customCell: (r, i) => {
              return (
                <td key={i}>{r.status_stage ? r.status_stage : "Invited"}</td>
              );
            }
          },
          {
            id: "order",
            label: "Ticket Price (excl. Tax)",
            customCell: (r, i) => {
              if (r.plan_id !== 0 && !r.plan_id) {
                return <td key={i}>Free</td>;
              }
              const plan = JSON.parse(this.state.data.tickets[r.plan_id]);
              const hasPromo = !r.isPromotion ? false : true;
              let isPromoPercentage = false;
              if (hasPromo) {
                isPromoPercentage =
                  r.promo?.discount_type === "Percentage" ? true : false;
              }
              return (
                <td key={i}>
                  ${plan.ticket_price}
                  {hasPromo
                    ? `${
                        isPromoPercentage
                          ? `, Promotion: ${r.promo.discount_percent}%`
                          : `, Promotion: $${r.promo.discount_fixed}`
                      }`
                    : ""}
                </td>
              );
            }
          },
          {
            id: "actionf",
            label: "",
            customCell: (r, i) => {
              return (
                <td
                  key={`${i}-btn`}
                  style={{ whiteSpace: "nowrap", textAlign: "right" }}
                >
                  {r.came_from ? (
                    <>
                      <button
                        style={{ marginRight: "15px" }}
                        onClick={_ => {
                          this.setState({
                            selectedProgram: r,
                            showNudgeInviteModal: true
                          });
                        }}
                      >
                        Nudge
                      </button>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmArchiveInviteModal: true,
                            idToEdit: r.id
                          });
                        }}
                        style={{ color: "#BBB", cursor: "pointer" }}
                        data-for="archive"
                        data-tip
                      />
                      <ReactTooltip
                        id="archive"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Archive Invite
                      </ReactTooltip>
                    </>
                  ) : (
                    <button
                      style={{ marginRight: "15px" }}
                      onClick={_ => {
                        // this.props.history.push(`/partners/application/${r.id}`)
                        let appStatus;
                        if (r.status_stage === "New_Pending") {
                          appStatus = "New";
                        }
                        if (r.status_stage === "Accepted_Unpaid") {
                          appStatus = "Accepted";
                        }
                        if (
                          r.status_stage === "Waitlisted_Unpaid" ||
                          r.status_stage === "Waitlisted_Paid"
                        ) {
                          appStatus = "Waitlisted";
                        }
                        if (r.status_stage === "Rejected") {
                          appStatus = "Rejected";
                        }
                        if (r.status_stage === "Inactive") {
                          appStatus = "Inactive";
                        }

                        this.setState({
                          selectedProgram: r,
                          showApplicationViewModal: true,
                          type: appStatus
                        });
                      }}
                    >
                      View Details
                    </button>
                  )}
                </td>
              );
            }
          }
        ]
      }
    });
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };
  viewNotes = (row, table) => {
    this.setState({
      notesModalShown: true,
      modalData: row,
      table: table
    });
  };

  archiveInvite = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/invitations/archive/${this.state.idToEdit}`;
    const results = await axios.post(ep);
    console.log("results", results);
    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  /**
   * This function handles the edit button functionality of a sub user
   *  returns boolean
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return this.global.dsUser.accesspoints.Manage.Events.edit ? false : true;
    }
  };

  getFilteredCustomers() {
    // Create a deep copy of the customers object
    const customersArr = JSON.parse(JSON.stringify(this.state.customers));

    if (customersArr["Applications"]) {
      customersArr["Applications"] = customersArr["Applications"].filter(
        customer => {
          const customerName = customer?.displayName?.toLowerCase().replace(/\s/g, "");
          const childName = customer?.childName?.toLowerCase().replace(/\s/g, "");
          const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
          return customerName.includes(filter) || (childName && childName.includes(filter));
        }
      );
    }

    if (customersArr["Attendees"]) {
      customersArr["Attendees"] = customersArr["Attendees"].filter(customer => {
        const customerName = customer?.displayName?.toLowerCase().replace(/\s/g, "");
        const childName = customer?.childName?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return customerName.includes(filter) || (childName && childName.includes(filter));
      });
    }

    if (customersArr["Inactive"]) {
      customersArr["Inactive"] = customersArr["Inactive"].filter(customer => {
        const customerName = customer?.displayName?.toLowerCase().replace(/\s/g, "");
        const childName = customer?.childName?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return customerName.includes(filter) || (childName && childName.includes(filter));
      });
    }

    return customersArr;
  }

  render() {
    console.log("this.state", this.state);
    return (
      <div className="admin">
        {this.state.notesModalShown && (
          <NotesModal
            data={this.state.modalData}
            table={this.state.table}
            onClose={() =>
              this.setState({
                notesModalShown: false,
                modalData: null
              })
            }
          />
        )}
        {this.state.showInstructorModal && (
          <AddInstructorModal
            open={this.state.showInstructorModal}
            program={this.state.modalEvent}
            onClose={() =>
              this.setState({
                showInstructorModal: false,
                modalEvent: null
              })
            }
            type="Event"
          />
        )}
        {this.state.addEnrollment && (
          <AddEnrollmentModal
            open={this.state.addEnrollment}
            program={this.state.modalProgram}
            onClose={() => {
              this.setState({
                addEnrollment: false,
                modalProgram: null
              });
              this.fetchInitialData();
            }}
            table={"partner_event"}
            type="events"
            promotion_type="event"
          />
        )}

        {this.state.showConfirmArchiveInviteModal && (
          <ConfirmInactiveModal
            onSubmit={() => {
              this.archiveInvite();
            }}
            onClose={_ =>
              this.setState({ showConfirmArchiveInviteModal: false })
            }
            toArchive={true}
            modalBody={"Are you sure you would like to archive this invite?"}
          />
        )}

        {this.state.showApplicationViewModal && (
          <ApplicationViewModal
            refresh={this.fetchInitialData}
            program={this.state.selectedProgram}
            onClose={_ => {
              this.setState({ showApplicationViewModal: false });
            }}
            isApplication={this.state.data.application_required}
            type={this.state.type}
            displayCategory={this.state.displayCategory}
            canSubUserEdit={this.canSubUserEdit()}
          />
        )}

        {this.state.openCancelEventModal && (
          <CancelProgramModal
            onClose={_ => {
              this.fetchInitialData();
              this.setState({ openCancelEventModal: false });
            }}
            data={this.state.data}
            // custommers={this.state.customers}
            // success={e => this.fetchInitialData()}
            // setGlobal={this.setGlobal}
            // global={this.global}
            table={"partner_event"}
          />
        )}
        {this.state.openAttendeeListPDF && (
          <EventAttendeeListPDF
            onClose={_ => {
              // this.fetchInitialData();
              this.setState({ openAttendeeListPDF: false });
            }}
            data={this.state.data}
            customers={this.state.customers}
          />
        )}
        {this.state.openEventLinkModal && (
          <EventShareLinkModal
            onClose={_ => {
              this.setState({ openEventLinkModal: false });
            }}
            programId={this.state.data.id}
            type={"Event"}
          />
        )}

        {this.state.showNudgeInviteModal && (
          <NudgeInviteModal
            invite={this.state.selectedProgram}
            program_table={"partner_event"}
            fetchData={_ => this.fetchInitialData()}
            onClose={_ => this.setState({ showNudgeInviteModal: false })}
          />
        )}

        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>{this.state.data.event_title}</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div
                  className="forbtnapp attendee-btn"
                  style={{ marginLeft: "20px" }}
                >
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ =>
                      this.setState({
                        addEnrollment: true,
                        modalProgram: this.state.data
                      })
                    }
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Attendee
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="display-container">
              <div className="left-container">
                <div className="image-container">
                  <LazyLoadImage
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.data.event_image}`}
                    alt="event"
                    className="image"
                    wrapperClassName="image"
                    effect="opacity"
                  />
                </div>
                <p className="event-name">{this.state.data.event_title}</p>
                <p className="event-duration">
                  {format(
                    new Date(this.state.data.event_start),
                    "LLLL dd, yyyy"
                  )}{" "}
                  at {format(new Date(this.state.data.event_start), "hh:mm aa")}{" "}
                  - <br />
                  {format(
                    new Date(this.state.data.event_end),
                    "LLLL dd, yyyy"
                  )}{" "}
                  at {format(new Date(this.state.data.event_end), "hh:mm aa")}
                </p>
                <div className="address-container">
                  <h3 className="address-title">
                    {this.state.data.address1} {this.state.data.address2}{" "}
                    {this.state.data.city} {this.state.data.province}
                  </h3>
                </div>
                <div className="options-container">
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.props.history.push(
                            `/preview/event/${this.state.data.id}`
                          );
                    }}
                  >
                    <h3 className="option-name">Event Preview</h3>
                    <i className="far fa-calendar-check option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.viewNotes(this.state.data, "partner_event");
                    }}
                  >
                    <h3 className="option-name">Notes</h3>
                    <i className="fas fa-pencil-alt option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={() => {
                      if (!this.canSubUserEdit()) {
                        this.setState({
                          showInstructorModal: true,
                          modalEvent: this.state.data
                        });
                      }
                    }}
                  >
                    <h3 className="option-name">Instructors</h3>
                    <i className="fas fa-info option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.props.history.push({
                            pathname: `/partners/send-message`,
                            search: `?type=Events&id=${this.props.match.params.id}`,
                            query: {
                              type: "Events",
                              id: `${this.props.match.params.id}`
                            }
                          });
                    }}
                  >
                    <h3 className="option-name">Message Attendees</h3>
                    <i className="fas fa-bullhorn option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.setState({ openCancelEventModal: true });
                    }}
                  >
                    <h3 className="option-name">Cancel Event</h3>
                    <i className="fas fa-times-circle option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      this.setState({
                        openAttendeeListPDF: true
                      })
                    }}
                  >
                    <h3 className="option-name">Attendee List PDF</h3>
                    <i className="far fa-edit option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.setState({ openEventLinkModal: true });
                    }}
                  >
                    <h3 className="option-name">Event Share Link</h3>
                    <i className="fas fa-share"></i>
                  </div>
                </div>
              </div>
              <div className="tablecont">
                <div className="table-responsive">
                  <div
                    style={{
                      display: "inline-flex",
                      flex: "1 1",
                      border: "none",
                      borderRadius: "10px",
                      overflow: "hidden",
                      width: "100%"
                    }}
                  >
                    <i
                      className="fas fa-search"
                      style={{ padding: "8px", background: "white" }}
                    ></i>
                    <input
                      type="text"
                      placeholder="Search by name"
                      style={{
                        border: "none",
                        fontSize: "13px",
                        padding: "5px",
                        outline: 0,
                        background: "white",
                        flex: 1
                      }}
                      value={this.state.filter}
                      onChange={e => this.setState({ filter: e.target.value })}
                    />
                  </div>
                  <NewTabbedTable
                    tabs={this.tabs}
                    headings={this.state.headings}
                    data={this.getFilteredCustomers()}
                    actions={this.actions}
                    initialTab={this.props.location.state?.tab}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgramList;
