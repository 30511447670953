import React, { createRef } from "reactn";
import SectionFive from "./forms/SectionFive";
import { format, addWeeks, isFuture, add, addDays, subDays } from "date-fns";
import "../../../assets/css/componentSpecificCss/customization.css";
import { ModalBanner } from "../../../assets";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import axios from "axios";
import { Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import EditOnlinePricingPlanModal from "../../../components/modals/EditOnlinePricingPlanModal";
import EditOnlineSingleSessionPlanModal from "../../../components/modals/EditOnlineSingleSessionPlanModal";
import EditOnlineSemesterPlan from "../../../components/modals/EditOnlineSemesterPlan";
import EditFreeOnline from "../../../components/modals/EditFreeOnline";
import PriceCardModal from "../../../components/modals/PriceCardModal";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const getFirstDay = (startingDate, daysOfWeek) => {
  // Code to compensate for the local timezone of the starting date
  startingDate.setTime(
    startingDate.getTime() + startingDate.getTimezoneOffset() * 60 * 1000
  );

  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};
class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enrolledSubscribers: [],
      openEditRecurringPlanModal: false,
      applicationEnrollment: [],
      allEnrollments: [],
      openEditOnlineSingleSessionsPlanModal: false,
      openEditOnlineSemesterPlanModal: false,
      openEditFreeModal: false
    };
    this.scrollContainerRef = createRef();
  }

  freeMenu = (element, planKey) => {
    return (
      <Menu onClick={event => this.onFreeSelect(element, planKey, event)}>
        <MenuItem key="inactive">
          {element.active === false ? "Make Active" : "Make Inactive"}
        </MenuItem>
        {this.displaySemesterEnrollments(element) === 0 && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        <MenuItem key="delete">Delete</MenuItem>
      </Menu>
    );
  };

  onFreeSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false ? true : false;

      this.setGlobal({
        online: {
          ...this.global.online,
          plans: this.global.online.plans.map((e, i) =>
            i === planKey ? { ...element, active: active } : e
          )
        }
      });

      await this.dispatch.saveVirtual();
    }
    if (event.key === "edit") {
      this.setState({ openEditFreeModal: true, selectedItem: element });
    }
    if (event.key === "delete") {
      this.deleteFreePlan(element, planKey);
    }
  };

  deleteFreePlan = async (plan, key) => {
    this.setGlobal({
      online: {
        ...this.global.online,
        plans: this.global.online.plans.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveVirtual();
  };

  semesterMenu = (element, planKey) => {
    return (
      <Menu onClick={event => this.onSemesterSelect(element, planKey, event)}>
        <MenuItem key="inactive">
          {element.active === false ? "Make Active" : "Make Inactive"}
        </MenuItem>
        {this.displaySemesterEnrollments(element) === 0 && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        <MenuItem key="delete">Delete</MenuItem>
        {/* <MenuItem key="subscribe">Subscriber Info</MenuItem> */}
      </Menu>
    );
  };

  displaySemesterEnrollments = element => {
    const planId = element.id;

    if (planId || (planId === 0 && planId !== null)) {
      const findPlan = this.state.allEnrollments.filter(
        e => e.plan_id === planId && e.status_stage === "Accepted_Paid"
      );
      return findPlan ? findPlan.length : 0;
    } else {
      return 0;
    }
  };

  onSingleSessionsSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false ? true : false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: element.prodId,
        active: active
      });

      this.setGlobal({ loading: false });
      if (res.data.success) {
        this.setGlobal({
          online: {
            ...this.global.online,
            plans: this.global.online.plans.map((e, i) =>
              i === planKey ? { ...element, active: active } : e
            )
          }
        });
      }

      await this.dispatch.saveVirtual();
    } else if (event.key === "edit") {
      this.setState({
        openEditOnlineSingleSessionsPlanModal: true,
        selectedItem: element
      });
    } else {
      this.deleteSingleSessionsPlan(element, planKey);
    }
  };

  deleteSingleSessionsPlan = async (plan, key) => {
    if (plan.prodId) {
      /**
       * Validation to check for number of enrollments before deleting
       */
      // const findPlan = this.state.enrolledSubscribers.find(e => e.planId == plan.prodId.id)
      // const checkEnrollments = findPlan ? findPlan.data.length : 0
      // if(checkEnrollments > 0){
      //   toast.error('Cannot delete a program with enrollments');
      //   return
      // }else{
      const applicationEnrollment = this.state.applicationEnrollment.filter(
        f => f.plan_id == plan.id
      ).length;
      if (applicationEnrollment > 0) {
        toast.error("Cannot delete a program with applications");
        return;
      }
      const active = plan.active === false ? true : false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: plan.prodId,
        active: active
      });

      this.setGlobal({ loading: false });

      if (res.data.success) {
        this.setGlobal({
          online: {
            ...this.global.online,
            plans: this.global.online.plans.map((e, i) =>
              i === key ? { ...plan, archived: true, active: active } : e
            )
          }
        });
      }

      await this.dispatch.saveVirtual();
      return;
      // }
    }

    this.setGlobal({
      online: {
        ...this.global.online,
        plans: this.global.online.plans.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveVirtual();
  };

  onSemesterSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false ? true : false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: element.prodId,
        active: active
      });

      this.setGlobal({ loading: false });
      if (res.data.success) {
        this.setGlobal({
          online: {
            ...this.global.online,
            plans: this.global.online.plans.map((e, i) =>
              i === planKey ? { ...element, active: active } : e
            )
          }
        });
      }

      await this.dispatch.saveVirtual();
    } else if (event.key === "edit") {
      this.setState({
        openEditOnlineSemesterPlanModal: true,
        selectedItem: element
      });
    } else {
      this.deleteSemesterPlan(element, planKey);
    }
  };

  deleteSemesterPlan = async (plan, key) => {
    if (plan.prodId) {
      /**
       * Validation to check for number of enrollments before deleting
       */
      // const findPlan = this.state.enrolledSubscribers.find(e => e.planId == plan.prodId.id)
      // const checkEnrollments = findPlan ? findPlan.data.length : 0
      const applicationEnrollment = this.state.applicationEnrollment.filter(
        f => f.plan_id == plan.id
      ).length;
      if (applicationEnrollment > 0) {
        toast.error("Cannot delete a program with applications");
        return;
      }
      // if(checkEnrollments > 0){
      //   toast.error('Cannot delete a program with enrollments');
      //   return
      // }else{
      const active = plan.active === false ? true : false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: plan.prodId,
        active: active
      });

      this.setGlobal({ loading: false });

      if (res.data.success) {
        this.setGlobal({
          online: {
            ...this.global.online,
            plans: this.global.online.plans.map((e, i) =>
              i === key ? { ...plan, archived: true, active: active } : e
            )
          }
        });
      }

      await this.dispatch.saveVirtual();
      return;
      // }
    }

    this.setGlobal({
      online: {
        ...this.global.online,
        plans: this.global.online.plans.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveVirtual();
  };

  handleUserDateSelectToggle = async (plan, key) => {
    this.setGlobal({
      online: {
        ...this.global.online,
        plans: this.global.online.plans.map((e, i) =>
          i === key
            ? { ...plan, isStartDateEnabled: !plan.isStartDateEnabled }
            : e
        )
      }
    });

    await this.dispatch.saveVirtual();
  };

  handleUserClassDateSelectToggle = async (plan, key) => {
    this.setGlobal({
      online: {
        ...this.global.online,
        plans: this.global.online.plans.map((e, i) =>
          i === key
            ? { ...plan, isDateSelectionEnabled: !plan.isDateSelectionEnabled }
            : e
        )
      }
    });

    await this.dispatch.saveVirtual();
  };

  handleUserSessionTimingSelectToggle = async (plan, key) => {
    this.setGlobal({
      online: {
        ...this.global.online,
        plans: this.global.online.plans.map((e, i) =>
          i === key ? { ...plan, isTimeEnabled: !plan.isTimeEnabled } : e
        )
      }
    });

    await this.dispatch.saveVirtual();
  };

  displayEnableDate = (element, key) => {
    return (
      <Switch
        checked={element.isStartDateEnabled}
        color="primary"
        onChange={_ => this.handleUserDateSelectToggle(element, key)}
      />
    );
  };

  displayEnableClassDate = (element, key) => {
    return (
      <Switch
        checked={element.isDateSelectionEnabled}
        color="primary"
        onChange={_ => this.handleUserClassDateSelectToggle(element, key)}
      />
    );
  };

  displayEnableSessionsTimingDate = (element, key) => {
    return (
      <Switch
        checked={element.isTimeEnabled}
        color="primary"
        onChange={_ => this.handleUserSessionTimingSelectToggle(element, key)}
      />
    );
  };

  displayEnrollments = element => {
    const planId = element.prodId.id;

    if (planId) {
      const findPlan = this.state.enrolledSubscribers.find(
        e => e.planId == planId
      );
      return findPlan ? findPlan.data.length : 0;
    } else {
      return 0;
    }
  };

  menu = (element, planKey) => {
    return (
      <Menu onClick={event => this.onSelect(element, planKey, event)}>
        <MenuItem key="inactive">
          {element.active === false ? "Make Active" : "Make Inactive"}
        </MenuItem>
        <MenuItem key="hidden">{element.isHidden === true ? "Show" : "Hide"}</MenuItem>
        {this.displayEnrollments(element) === 0 && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        <MenuItem key="delete">Delete</MenuItem>
        {/* <MenuItem key="subscribe">Subscriber Info</MenuItem> */}
      </Menu>
    );
  };

  displaySingleSessionEnrollments = (element, planKey) => {
    console.log("element", element);
    console.log("planKey", planKey);

    if (
      this.state.allEnrollments.filter(e => e.plan_id === planKey).length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  singleSessionsMenu = (element, planKey) => {
    return (
      <Menu
        onClick={event => this.onSingleSessionsSelect(element, planKey, event)}
      >
        <MenuItem key="inactive">
          {element.active === false ? "Make Active" : "Make Inactive"}
        </MenuItem>
        {this.displaySingleSessionEnrollments(element, planKey) && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        <MenuItem key="delete">Delete</MenuItem>
        {/* <MenuItem key="subscribe">Subscriber Info</MenuItem> */}
      </Menu>
    );
  };

  onSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false ? true : false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_plan`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: element.prodId.id,
        active: active
      });

      this.setGlobal({ loading: false });
      if (res.data.success) {
        this.setGlobal({
          online: {
            ...this.global.online,
            plans: this.global.online.plans.map((e, i) =>
              i === planKey ? { ...element, active: active } : e
            )
          }
        });
      }
      await this.dispatch.saveVirtual();
    } else if (event.key === "hidden") {
      const isHidden = element.isHidden === true ? false : true;
      this.setGlobal({
        online: {
          ...this.global.online,
          plans: this.global.online.plans.map((e, i) =>
            i === planKey ? { ...element, isHidden: isHidden } : e
          )
        }
      });
      await this.dispatch.saveVirtual();
    } else if (event.key === "edit") {
      this.setState({
        openEditRecurringPlanModal: true,
        selectedItem: element
      });
    } else {
      this.deletePlan(element, planKey);
    }
  };

  deletePlan = async (plan, key) => {
    if (plan.prodId.id) {
      /**
       * Validation to check for number of enrollments before deleting
       */
      const findPlan = this.state.enrolledSubscribers.find(
        e => e.planId == plan.prodId.id
      );
      const applicationEnrollment = this.state.applicationEnrollment.filter(
        f => f.plan_id == plan.id
      ).length;
      const checkEnrollments = findPlan ? findPlan.data.length : 0;

      if (checkEnrollments > 0 || applicationEnrollment > 0) {
        toast.error("Cannot delete a program with enrollments/applications");
        return;
      } else {
        const ep = `${process.env.REACT_APP_API}/partners/stripe/delete_plan`;
        this.setGlobal({ loading: true });
        const res = await axios.delete(ep, {
          data: { planId: plan.prodId.id }
        });

        this.setGlobal({ loading: false });

        if (res.data.success) {
          this.setGlobal({
            online: {
              ...this.global.online,
              plans: this.global.online.plans.map((e, i) =>
                i === key ? { ...plan, archived: true } : e
              )
            }
          });
        }
        await this.dispatch.saveVirtual();
        return;
      }
    }

    this.setGlobal({
      online: {
        ...this.global.online,
        plans: this.global.online.plans.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveVirtual();
  };

  async componentDidMount() {
    const scrollContainer = document.getElementById('scroll-card-container');
    scrollContainer.addEventListener('wheel', (e) => {
      e.preventDefault();
      const container = document.getElementById("scroll-card-container");
      container.scrollTo({
        top: 0,
        left: container.scrollLeft + e.deltaY,
        behaviour: 'smooth'
      })}, { passive: false });
    //...an array filled with values
    const plansIds = this.global.online.plans.filter(e => {
      const element = typeof e === "string" ? JSON.parse(e) : e;
      if (element.type === "Free") {
        return;
      }
      if (!element.prodId.id) {
        return;
      } else {
        return element.prodId.id;
      }
    });

    plansIds.forEach(e => JSON.parse(e));

    const ep = `${process.env.REACT_APP_API}/partners/stripe/subscribers_plan`;

    const requestPlan = plansIds.map(e => {
      const element = typeof e === "string" ? JSON.parse(e) : e;
      return axios.get(ep, {
        params: {
          planId: element.prodId.id
        }
      });
    });

    axios
      .all(requestPlan)
      .then(responses => {
        const newData = responses.map(e => {
          return e.data.data
            ? { planId: e.config.params.planId, data: e.data.data.data }
            : { planId: e.config.params.planId, data: [] };
        });

        this.setState({
          enrolledSubscribers: newData
        });
        // use/access the results
      })
      .catch(errors => {});

    const ep2 = `${process.env.REACT_APP_API}/partners/virtual/application/count/${this.global.online.id}`;
    const res2 = await axios.get(ep2);

    const ep3 = `${process.env.REACT_APP_API}/partners/virtual/application-enrolled/count/${this.global.online.id}`;
    const res3 = await axios.get(ep3);

    if (res2.data.success) {
      this.setState({
        applicationEnrollment: res2.data.data,
        allEnrollments: res3.data.data
      });
    }
  }

  componentWillUnmount() {
    const scrollContainer = document.getElementById('scroll-card-container');

    scrollContainer.removeEventListener('wheel', (e) => {
      e.preventDefault();
      const container = document.getElementById("scroll-card-container");
      container.scrollTo({
        top: 0,
        left: container.scrollLeft + e.deltaY,
        behaviour: 'smooth'
      })});
  }

  onDragEnd = (e) => {
    console.log("e in onDragEnd: ", e);
    if (!e.destination) {
      return;
    }

    const resultArr = Array.from(this.global.online?.plans);
    console.log("resultArr", resultArr);
    let newParsed = resultArr.map((f, id) => {
      if (typeof f === "string") {
        f = JSON.parse(f);
        if (!f.order) {
          f.order = id;
        }
        return f;
      } else {
        if (!f.order) {
          f.order = id;
        }
        return f;
      }
    });

    const [removedPlan] = newParsed.splice(e.source.index, 1);
    newParsed.splice(e.destination.index, 0, removedPlan);
    console.log("New Ordering: ", newParsed);
    const newOrdering = newParsed.map((plan, index) => ({...plan, order: index}));
    this.setGlobal({
      online: {
        ...this.global.online,
        plans: newOrdering
      }
    });
  }

  getDropdownMenu = (menu) => {
    return(
      <Dropdown
        trigger={["click"]}
        overlay={menu}
        animation="slide-up"
      >
        <button
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            minWidth: 20,
            minHeight: 20,
            outline: "none",
            border: 0,
            background: "#f4f6fd",
            fontSize: "14px"
          }}
        >
          <i className="fas fa-ellipsis-v"></i>
        </button>
      </Dropdown>
    );
  }

  render() {
    console.log("plans", this.global.online?.plans);
    console.log("this.state", this.state);

    return (
      <>
        {this.state.openEditOnlineSingleSessionsPlanModal && (
          <EditOnlineSingleSessionPlanModal
            selectedItem={this.state.selectedItem}
            onClose={_ =>
              this.setState({ openEditOnlineSingleSessionsPlanModal: false })
            }
          />
        )}

        {this.state.openEditRecurringPlanModal && (
          <EditOnlinePricingPlanModal
            selectedItem={this.state.selectedItem}
            onClose={_ => this.setState({ openEditRecurringPlanModal: false })}
          />
        )}

        {this.state.openEditOnlineSemesterPlanModal && (
          <EditOnlineSemesterPlan
            selectedItem={this.state.selectedItem}
            onClose={_ =>
              this.setState({ openEditOnlineSemesterPlanModal: false })
            }
          />
        )}

        {this.state.openEditFreeModal && (
          <EditFreeOnline
            selectedItem={this.state.selectedItem}
            onClose={_ => this.setState({ openEditFreeModal: false })}
          />
        )}
        
        <div className="cont boxes-row mt-50px">
          <SectionFive vals={this.global.myApplicationProfileInfo} />
        
          <div id="onlinePriceCardContainer" className="cont boxes-row mt-50px center-box">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable" direction={`${window.innerWidth <= 767 ? "vertical" : "horizontal"}`}>
                {provided => (
                  <div id="scroll-card-container" className="price-card-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {this.global.online?.plans
                      ?.sort((prev, next) => {
                        let newPrev = prev;
                        let newNext = next;
                        if (typeof prev === "string") {
                          newPrev = JSON.parse(prev);
                        }
                  
                        if (typeof next === "string") {
                          newNext = JSON.parse(next);
                        }
                  
                        return newPrev.order - newNext.order;
                      })
                      .map((e, key) => {
                        const element = typeof e === "string" ? JSON.parse(e) : e;
                        console.log("ELEMENT", key, ": ", element);
                        if (!element.type && !element.archived)
                          return (
                            <Draggable key={key} draggableId={String(key)} index={key}>
                              {provided => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                  <PriceCardModal
                                    key={key}
                                    id={key}
                                    type={element.type}
                                    inactive={element.active === false || element.isHidden === true}
                                    plan_category={"Recurring"}
                                    billing_cycle={element.billing_cycle}
                                    timing={element.timing}
                                    day_slot={element.day_slot}
                                    days_per_week={element.days_per_week}
                                    description={element.description}
                                    enrollments={this.displayEnrollments(element)}
                                    applications={this.state.applicationEnrollment.filter(
                                      f => f.plan_id === element.id
                                    ).length}
                                    dateSelection={this.displayEnableDate(element, key)}
                                    plan_price={element.tuition_rate}
                                    deposit={element.deposit}
                                    taxInfo={element.taxInfo}
                                    DropdownMenu={this.getDropdownMenu(this.menu(element, key))}
                                    onEditButtonClick={() => {
                                      this.setState({
                                        openEditRecurringPlanModal: true,
                                        selectedItem: element
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        if ((element.type === "Semester" || element.type ==="One-Time") && !element.archived)
                          return (
                            <Draggable key={key} draggableId={String(key)} index={key}>
                              {provided => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                  <PriceCardModal
                                  key={key}
                                  id={key}
                                  type={element.type}
                                  inactive={element.active === false}
                                  description={element.description}
                                  firstDay={getFirstDay(
                                      new Date(this.global.online.program_start),
                                      this.global.online.days_of_week
                                  )}
                                  lastDay={getLastDay(
                                      addWeeks(
                                        getFirstDay(
                                          new Date(this.global.online.program_start),
                                          this.global.online.days_of_week
                                        ),
                                        this.global.online.number_of_weeks - 1
                                      ),
                                      this.global.online.days_of_week
                                    )
                                  }
                                  plan_category={element.type}
                                  enrollments={this.displaySemesterEnrollments(element)}
                                  applications={this.state.applicationEnrollment.filter(
                                    f => f.plan_id === element.id
                                  ).length}
                                  plan_cap={element.planCap}
                                  plan_price={element.total_price}
                                  taxInfo={element.taxInfo}
                                  installments={element.installments}
                                  installments_breakdown={element.installments_breakdown}
                                  DropdownMenu={this.getDropdownMenu(this.semesterMenu(element, key))}
                                  addOn={element.addOn}
                                  addOnItems={element.addOnItems}
                                  onEditButtonClick={() => {
                                    if (element.type === "Semester") {
                                      this.setState({
                                        openEditOnlineSemesterPlanModal: true,
                                        selectedItem: element
                                      });
                                    }

                                    if (element.type === "One-Time") {
                                      this.setState({
                                      });
                                    }

                                  }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );

                        if (element.type === "Free Trial" && !element.archived)
                          return (
                            <Draggable key={key} draggableId={String(key)} index={key}>
                              {provided => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                  <PriceCardModal
                                    key={key}
                                    id={key}
                                    type={element.type}
                                    inactive={element.active === false}
                                    plan_category={this.global.online.number_of_weeks == 0 ? "Recurring" : "Semester"}
                                    firstDay={getFirstDay(
                                      new Date(this.global.online.program_start),
                                      this.global.online.days_of_week
                                    )}
                                    lastDay={getLastDay(
                                      addWeeks(
                                        getFirstDay(
                                          new Date(this.global.online.program_start),
                                          this.global.online.days_of_week
                                        ),
                                        this.global.online.number_of_weeks - 1
                                      ),
                                      this.global.online.days_of_week
                                    )}
                                    description={element.description}
                                    plan_cap={element.planCap}
                                    separateForChildren={element.separateForChildren}
                                    autoEmail={element.allowAutoEmail}
                                    trialPeriod={element.trialPeriod}
                                    target={element.trialDemographic}
                                    enrollments={this.displaySemesterEnrollments(element)}
                                    applications={this.state.applicationEnrollment.filter(
                                      f => f.plan_id === element.id
                                    ).length}
                                    plan_price={element.total_price}
                                    DropdownMenu={this.getDropdownMenu(this.freeMenu(element, key))}
                                    onEditButtonClick={() => {
                                      this.setState({
                                        openEditFreeTrialModal: true,
                                        selectedItem: element
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );

                        if (element.type === "Drop-In" && !element.archived)
                          return (
                            <Draggable key={key} draggableId={String(key)} index={key}>
                              {provided => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                  <PriceCardModal
                                    key={key}
                                    id={key}
                                    type={element.type}
                                    inactive={element.active === false}
                                    plan_category={element.type}
                                    firstDay={getFirstDay(
                                      new Date(this.global.online.program_start),
                                      this.global.online.days_of_week
                                    )}
                                    lastDay={getLastDay(
                                      addWeeks(
                                        getFirstDay(
                                          new Date(this.global.online.program_start),
                                          this.global.online.days_of_week
                                        ),
                                        this.global.online.number_of_weeks - 1
                                      ),
                                      this.global.online.days_of_week
                                    )}
                                    description={element.description}
                                    plan_cap={element.planCap}
                                    enrollments={this.displaySemesterEnrollments(element)}
                                    applications={this.state.applicationEnrollment.filter(
                                      f => f.plan_id === element.id
                                    ).length}
                                    plan_price={element.total_price}
                                    enableSpecificDates={element.enableSpecificDates}
                                    taxInfo={element.taxInfo}
                                    addOn={element.addOn}
                                    addOnItems={element.addOnItems}
                                    DropdownMenu={this.getDropdownMenu(this.freeMenu(element, key))}
                                    onEditButtonClick={() => {
                                      this.setState({
                                        openEditDropInModal: true,
                                        selectedItem: element
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        if (element.type === "Free" && !element.archived)
                          return (
                            <Draggable key={key} draggableId={String(key)} index={key}>
                              {provided => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                  <PriceCardModal
                                    key={key}
                                    id={key}
                                    type={element.type}
                                    inactive={element.active === false}
                                    plan_category={this.global.online.number_of_weeks == 0 ? "Recurring" : "Semester"}
                                    firstDay={getFirstDay(
                                      new Date(this.global.online.program_start),
                                      this.global.online.days_of_week
                                    )}
                                    lastDay={getLastDay(
                                      addWeeks(
                                        getFirstDay(
                                          new Date(this.global.online.program_start),
                                          this.global.online.days_of_week
                                        ),
                                        this.global.online.number_of_weeks - 1
                                      ),
                                      this.global.online.days_of_week
                                    )}
                                    description={element.description}
                                    enrollments={this.displaySemesterEnrollments(element)}
                                    applications={this.state.applicationEnrollment.filter(
                                      f => f.plan_id === element.id
                                    ).length}
                                    plan_price={element.total_price}
                                    DropdownMenu={this.getDropdownMenu(this.freeMenu(element, key))}
                                    onEditButtonClick={() => {
                                      this.setState({
                                        openEditFreeModal: true,
                                        selectedItem: element
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );

                        if (element.type === "Single Sessions" && !element.archived)
                          return (  
                            <Draggable key={key} draggableId={String(key)} index={key}>
                              {provided => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                  <PriceCardModal
                                    key={key}
                                    id={key}
                                    type={element.type}
                                    inactive={element.active === false}
                                    plan_category={`Single Session (${element.duration_type + (element.duration ? ` ${element.duration} min` : "")})`}
                                    description={element.description}
                                    frequency={element.frequency}
                                    quantity={element.quantity}
                                    enrollments={this.displaySingleSessionEnrollments(element, element.id)}
                                    applications={this.state.applicationEnrollment.filter(
                                      f => f.plan_id === element.id
                                    ).length}
                                    plan_price={element.total_price}
                                    taxInfo={element.taxInfo}
                                    dateSelection={
                                      element.duration_type === "Class" 
                                        ? this.displayEnableClassDate(element,key) 
                                        : this.displayEnableSessionsTimingDate(element, key)
                                    }
                                    DropdownMenu={this.getDropdownMenu(this.singleSessionsMenu(element, key))}
                                    onEditButtonClick={() => {
                                      this.setState({
                                        openEditOnlineSingleSessionsPlanModal: true,
                                        selectedItem: element
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );

                        //this if-block is just a placeholder incase we want to offer an option in the future to revive an archived plan.
                        if (element.archived) {
                          return null;
                        }

                        //THIS POINT IN THE CODE SHOULD NEVER BE REACHED UNLESS YOU FORGOT
                        //TO ADD A PRICE PLAN THAT NONE OF THE ABOVE IF-BLOCKS FAILED TO CATCH!
                        return (
                          <div>
                            Something went wrong! Price plan "{element.type}" is not one
                            of the accepted types.
                          </div>
                        );
                      })}
                    {provided.placeholder}
                    {<button
                      id={`${this.global.online?.plans.filter((plan) => {
                        let element = plan;
                        if (typeof plan === 'string') {
                          element = JSON.parse(plan);
                        }
                        return !element.archived;
                      }).length === 0 ? "no-plans-button" : ""}`}
                      className="addchild add-plan"
                      onClick={_ => this.props.openModal()}
                    >
                      <h3>Add Plan</h3>
                    </button>}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

          </div>
        </div>
      </>
    );
  }
}

export default Setup;
