import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { NewTabbedTable } from "../../components/UI";
import { toast } from "react-toastify";
import "../../assets/css/componentSpecificCss/applicationViewModal.css";
import BaseModal from "./baseModal/BaseModal";
import NotesModal from "./NotesModal";
import ConnectionsApplicationViewModal from "./ConnectionsApplicationViewModal";
import SurveyViewModal from "./FormViewModal";
import EndEnrollmentModal from "./EndEnrollmentModal";
import { CONTENT_BLOCK, GET_HTML } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { format } from "date-fns";

class ConnectionsViewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      formSections: [],
      came_from: "",
      data: { Active: [] },
      openViewApplicationModal: false,
      openEndEnrollmentModal: false,
      openViewSurveyModal: false,
      notesModalShown: false,
      modalData: null,
      table: null,
      selectedItem: null
    };
  }

  getRow(r, i) {
    return (
      <tr
        style={{
          fontSize: "12px",
          color: "#959595",
          borderBottom: "2px solid gainsboro"
        }}
        key={i}
      >
        {this.getInterestedInCell(r, i)}
        {this.getAdditionalCell(r, i)}
        {this.getDateCell(r, i)}
        {this.getToolTipCell(r, i)}
      </tr>
    );
  }

  getInterestedInCell(r, i) {
    if (r.came_from && r.came_from.includes("Initiated")) {
      return <td key={`${i}-came_from`}>Initiated Booking</td>;
    }
    console.log("Not Initiated cell");

    let statusStage = r.status_stage ? r.status_stage : "";
    if (r.status_stage && !r.product.application_required) {
      if (r.status_stage === "Accepted_Unpaid") {
        statusStage = "Direct_Unpaid";
      } else if (r.status_stage === "Accepted_Paid") {
        statusStage = "Direct_Paid";
      }
    }

    let description = "";
    let isFormCompleted = null;
    let productName = r.title;
    let tab = undefined;
    if (r.status_stage === "Accepted_Paid") {
      description = "Enrolled: " + productName;
      tab = 0;
    } else if (
      r.status_stage === "Waitlisted_Unpaid" ||
      r.status_stage === "New_Pending" ||
      r.status_stage === "Accepted_Unpaid"
    ) {
      description = "Application: " + productName;
      tab = 1;
    } else if (
      r.status_stage === "Inactive" ||
      r.status_stage === "Cancelled" ||
      r.status_stage === "Cancelled_Unpaid" ||
      r.status_stage === "Rejected" ||
      r.status_stage === "Application_Cancelled"
    ) {
      description = "Inactive: " + productName;
      tab = 2;
    } else {
      isFormCompleted = r.custom ? true : false;
      if (r.formId) {
        description = `${r.came_from}: ${r.form ? r.form.form_sub_name : ""}`;
      } else {
        description = r.came_from;
        isFormCompleted = r.classification === "pending" ? false : true;
      }
    }

    return (
      <td key={`${i}-came_from`}>
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => {
            if (tab === undefined) return;

            let type = "";
            let idField = "";
            switch (r.classificationType) {
              case "Program":
                type = "programs";
                idField = "programId";
                break;
              case "Online":
                type = "online";
                idField = "programId";
                break;
              case "Event":
                type = "events";
                idField = "eventId";
                break;
              case "Membership":
                type = "membership";
                idField = "membershipId";
                break;
              case "Party":
                type = "parties";
                idField = "partyId";
                break;
            }
            this.props.history.push({
              pathname: `/partners/${type}/manage/single/${r[idField]}`,
              state: { tab }
            });
          }}
        >
          {description}{" "}
        </span>
        <span style={{ fontStyle: "italic" }}>
          {isFormCompleted === false ? `(Pending)` : ""}
        </span>
        <span style={{ fontStyle: "italic" }}>
          {r.status_stage ? `(${statusStage})` : ""}
        </span>
      </td>
    );
  }

  getAdditionalCell(r, i) {
    return <td key={`${i}-additional`}> {r.childName}</td>;
  }

  getDateCell(r, i) {
    return (
      <td key={`${i}-date`}>
        {format(new Date(r.createdAt), "LLLL dd, yyyy")}
      </td>
    );
  }

  getToolTipCell(r, i) {
    let isPendingForm = false;
    if (
      (r.came_from === "Survey" ||
        r.came_from === "Contact-Us" ||
        r.came_from === "Waiver" ||
        r.came_from === "Appointments") &&
      r.custom === null
    ) {
      isPendingForm = true;
    }

    return (
      <td key={`${i}-btn`} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
        {r.status === "Enrollment" && (
          <>
            <i
              className="fas fa-paper-plane"
              style={{
                marginRight: "30px",
                cursor: "pointer",
                color: "#ccc",
                fontSize: "16px"
              }}
              onClick={_ => {
                this.setState({
                  openViewApplicationModal: true,
                  selectedItem: r
                });
              }}
              data-tip
              data-for="application-icon"
            ></i>
            <ReactTooltip
              id="application-icon"
              place="right"
              effect="solid"
              type="info"
            >
              View Application Details
            </ReactTooltip>
          </>
        )}

        {(r.came_from === "Survey" ||
          r.came_from === "Contact-Us" ||
          r.came_from === "Waiver" ||
          r.came_from === "Appointments") &&
          !isPendingForm && (
            <>
              <i
                className="fas fa-paper-plane"
                style={{
                  marginRight: "30px",
                  cursor: "pointer",
                  color: "#ccc",
                  fontSize: "16px"
                }}
                onClick={_ => {
                  this.setState({
                    openViewSurveyModal: true,
                    selectedItem: r
                  });
                }}
                data-tip
                data-for="application-icon"
              ></i>
              <ReactTooltip
                id="application-icon"
                place="right"
                effect="solid"
                type="info"
              >
                View Application Details
              </ReactTooltip>
            </>
          )}
        {!isPendingForm && (
          <>
            <i
              className="fas fa-clipboard icon"
              style={{
                marginRight: "10px",
                cursor: "pointer",
                color: "#ccc",
                fontSize: "16px"
              }}
              onClick={_ =>
                this.setState({
                  notesModalShown: true,
                  modalData: r,
                  table: r.table
                })
              }
              data-tip
              data-for="note-icon"
            />
            <ReactTooltip
              id="note-icon"
              place="right"
              effect="solid"
              type="info"
            >
              View Notes
            </ReactTooltip>
          </>
        )}
        {/* <button style={{ marginRight: "30px" }} onClick={_ => this.setState({ notesModalShown: true, modalData: r, table: r.came_from === "Contact-Us" ? "partner_leads" : "partner_connections" })}>Notes</button> */}
      </td>
    );
  }

  async fetchData() {
    this.setGlobal({ loading: true });
    console.log("FETCHING DATA with params:", this.props.program);
    // gets only first connection. Redundant as we search for all below. Leave and change after
    // testing works.
    const ep = `${process.env.REACT_APP_API}/partners/connections/latest-info`;
    const results = await axios.get(ep, {
      params: {
        table: this.props.program.table,
        id: this.props.program.id
      }
    });

    if (results.data.success) {
      if (!results.data.data) {
        this.setState({ came_from: this.props.program.came_from });
      } else {
        const info = results.data.data;

        const status =
          info.application_required &&
          (info.status_stage === "Accepted_Unpaid" ||
            info.status_stage === "New_Pending")
            ? "Application"
            : "Enrolled";

        let name_column = "";

        switch (this.props.program.table) {
          case "programs_customers":
          case "online_customers":
            name_column = "program_name";
            break;
          case "membership_customers":
            name_column = "membership_name";
            break;
          case "event_customers":
            name_column = "event_title";
            break;
        }
        console.log("RETURNED FROM FETCH", info);
        this.setState({ came_from: `${status}: ${info[name_column]}` });
      }
    }
    // fetching recent 10 historical data
    const ep2 = `${process.env.REACT_APP_API}/partners/connections/${true}`;
    const results2 = await axios.get(ep2, {
      params: {
        parentId: this.props.program.parentId
      }
    });
    if (results2.data.success) {
      this.setState({
        data: {
          Active: results2.data.data.connections
            .filter(f => f.email === this.props.program.email && !f.isArchived)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 10)
        }
      });
    }
    this.setGlobal({ loading: false });
  }

  pauseEnrollment = async (isStripe, id, resume) => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/orders/toggle-pause`;
    const res = await axios.post(ep, { id: id, is_stripe: isStripe });
    if (res.data.success) {
      toast.success(`Successfully ${resume ? "resumed" : "paused"} enrollment`);
    } else {
      toast.error("Please wait until the next invoice has been generated!");
    }
    this.setState({ openViewApplicationModal: false });
    await this.fetchData();
  };

  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return !this.global.dsUser.accesspoints.Leads.Applications.edit;
    }
  };

  async componentDidMount() {
    console.log("BEFORE FIRST FETCH PROPS: ", this.props);
    await this.fetchData();
    console.log("state: ", this.state);
  }

  requestTour = async e => {
    e.preventDefault();
  };

  displayModalData = () => {
    return (
      /*       <div className="request-modal-cont">
        <div className="request-modal lesspadding"> */
      <div>
        {/* <h3>Connection Details</h3> */}
        <div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Name
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.displayName}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Came From
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.state.came_from}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Email
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.email}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Cell Phone
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.phone}
            </span>
          </div>
          <table className="table">
            <thead
              style={{
                color: "#A9A9A9",
                fontSize: "12.5px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                textAlign: "left",
                borderBottom: "1px solid gainsboro"
              }}
            >
              <tr>
                <th id="table-header-interested-in">Interested In</th>
                <th id="table-header-additional">Additional</th>
                <th id="table-header-date">Date</th>
                <th id="table-header-tool-tip"></th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.Active.map((item, index) => {
                return this.getRow(item, index);
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  getBaseModalFields() {
    const infoFields = {
      type: GET_HTML,
      data: {
        gethtml: this.displayModalData
      }
    };

    const fields = [];
    fields.push(infoFields);
    return fields;
  }

  getBaseModalButtons = () => {};

  getBaseModalProps = () => {
    return {
      title: `Connection Details`,
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "65vh",
      midSectionHeight: "49vh"
    };
  };

  render() {
    return (
      <div>
        {this.state.notesModalShown && (
          <NotesModal
            data={this.state.modalData}
            table={this.state.table}
            onClose={() =>
              this.setState({
                notesModalShown: false,
                modalData: null
              })
            }
            showTime={this.state.table === "partner_leads" ? true : false}
          />
        )}
        {this.state.openViewApplicationModal && (
          <ConnectionsApplicationViewModal
            refresh={() => {
              this.setGlobal({ loading: true });
              this.fetchData();
              this.setGlobal({ loading: false });
            }}
            endEnrollment={_ => {
              this.setState({
                openEndEnrollmentModal: true,
                openViewApplicationModal: false
              });
            }}
            pauseEnrollment={(isStripe, subscription, resume) => {
              this.pauseEnrollment(isStripe, subscription, resume);
            }}
            program={this.state.selectedItem}
            onClose={_ => {
              this.setState({ openViewApplicationModal: false });
            }}
            isApplication={this.state.selectedItem.product.application_required}
            type={
              this.state.selectedItem.status_stage === "Accepted_Paid"
                ? "Enrolled"
                : this.state.selectedItem.status_stage === "New_Pending"
                ? "New"
                : this.state.selectedItem.status_stage === "Accepted_Unpaid"
                ? "Accepted"
                : this.state.selectedItem.status_stage ===
                    "Waitlisted_Unpaid" ||
                  this.state.selectedItem.status_stage === "Waitlisted_Paid"
                ? "Waitlisted"
                : this.state.selectedItem.status_stage ===
                    "Application_Cancelled" ||
                  this.state.selectedItem.status_stage === "Cancelled_Unpaid"
                ? "Archived"
                : null
            }
            displayCategory={
              this.state.selectedItem.classificationType === "Program"
                ? "Programs"
                : this.state.selectedItem.classificationType === "Event"
                ? "Events"
                : this.state.selectedItem.classificationType === "Party"
                ? "Parties"
                : this.state.selectedItem.classificationType === "Membership"
                ? "Memberships"
                : this.state.selectedItem.classificationType === "Online"
                ? "Online Virtual"
                : this.state.displayCategory
            }
            canSubUserEdit={this.canSubUserEdit()}
          />
        )}
        {this.state.openEndEnrollmentModal && (
          <EndEnrollmentModal
            program={this.state.selectedItem}
            onClose={_ => {
              this.fetchData();
              this.setState({ openEndEnrollmentModal: false });
            }}
            listingType={this.state.selectedItem.classificationType}
          />
        )}
        {this.state.openViewSurveyModal && (
          <SurveyViewModal
            onClose={() => this.setState({ openViewSurveyModal: false })}
            selectedItem={this.state.selectedItem}
            program={this.state.selectedItem}
          />
        )}
        {!(
          this.state.notesModalShown ||
          this.state.openViewApplicationModal ||
          this.state.openViewSurveyModal ||
          this.state.openEndEnrollmentModal
        ) && <BaseModal {...this.getBaseModalProps()} />}
      </div>
    );
  }
}

export default ConnectionsViewModal;
