import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { format } from "date-fns";
import Switch from "@material-ui/core/Switch";
import ReactTooltip from "react-tooltip";

class SectionThree extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      days: {},
      leftIndex: [0, 2, 4, 6, 8, 10, 12, 14, 16],
      rightIndex: [1, 3, 5, 7, 9, 11, 13, 15, 17],
      openEditProgramStartDateModal: false
    };
  }

  handleChange = (e, v) => {
    if (e === "total_spots" && v === "") {
      v = null;
    }

    this.setGlobal({
      program: {
        ...this.global.program,
        [e]: v
      }
    });
  };

  setDay(day) {
    const days = { ...this.global.program.days_of_week };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.handleChange("days_of_week", days);
  }

  setCustomTime = day => {
    const days = { ...this.global.program.customTimes };
    if (days[day]) delete days[day];
    else days[day] = [-1, -1];
    this.handleChange("customTimes", days);
  };

  insertTime = (day, index) => {
    const days = { ...this.global.program.customTimes };
    const editedTimes = days[day].splice(index + 2, 0, -1, -1);
    this.handleChange("customTimes", days);
  };

  deleteTime = (day, index) => {
    const days = { ...this.global.program.customTimes };
    const editedTimes = days[day].splice(index, 2);
    this.handleChange("customTimes", days);
  };

  customForm = () => {
    if (!this.global.program.days_of_week) {
      return;
    }

    const toNatural = num => {
      const int = Math.round(num);
      return int > 0 ? int : 1;
    };

    const renderCustomTimeRange = dayOfWeek => {
      return new Array(this.global.program.customTimes[dayOfWeek].length / 2)
        .fill(1)
        .map((_, index) => {
          return (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names" style={{ marginLeft: "25px" }}>
                  {dayOfWeek}
                </h3>
              </div>
              <div
                className="col-7 d-flex padding-zero"
                style={{ alignItems: "center" }}
              >
                <div style={{ width: "40%" }}>
                  <formTypes.TimeDropdownSelect
                    end={parseFloat(
                      (this.global.program.customTimes &&
                        parseInt(
                          this.global.program.customTimes[dayOfWeek][
                            index * 2 + 1
                          ]
                        ) !== -1 &&
                        this.global.program.customTimes[dayOfWeek][
                          index * 2 + 1
                        ]) ||
                        null
                    )}
                    name="customTimes"
                    value={parseFloat(
                      this.global.program.customTimes[dayOfWeek][index * 2] ||
                        -1
                    )}
                    onChange={(e, v) => {
                      const times = this.global.program.customTimes;
                      times[dayOfWeek][index * 2] = v;
                      this.handleChange(e, times);
                    }}
                    searchable={false}
                  ></formTypes.TimeDropdownSelect>
                </div>
                To
                <div style={{ width: "40%" }}>
                  <formTypes.TimeDropdownSelect
                    start={parseFloat(
                      (this.global.program.customTimes &&
                        parseInt(
                          this.global.program.customTimes[dayOfWeek][index * 2]
                        ) !== -1 &&
                        this.global.program.customTimes[dayOfWeek][
                          index * 2
                        ]) ||
                        null
                    )}
                    name="customTimes"
                    value={parseFloat(
                      this.global.program.customTimes[dayOfWeek][
                        index * 2 + 1
                      ] || -1
                    )}
                    onChange={(e, v) => {
                      const times = this.global.program.customTimes;
                      times[dayOfWeek][index * 2 + 1] = v;
                      this.handleChange(e, times);
                    }}
                    searchable={false}
                  ></formTypes.TimeDropdownSelect>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button
                    type="button"
                    onClick={e => {
                      this.insertTime(dayOfWeek, index * 2);
                    }}
                    style={{
                      backgroundColor: "#568ae3",
                      border: "none",
                      color: "white",
                      borderRadius: "3px",
                      marginLeft: "5px"
                    }}
                  >
                    +
                  </button>
                  {this.global.program.customTimes[dayOfWeek].length !== 2 && (
                    <button
                      type="button"
                      onClick={e => {
                        this.deleteTime(dayOfWeek, index * 2);
                      }}
                      style={{
                        backgroundColor: "grey",
                        border: "none",
                        color: "white",
                        borderRadius: "3px",
                        marginLeft: "5px"
                      }}
                    >
                      -
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        });
    };

    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Category {""}
                <i
                  data-tip
                  data-for="Category"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="Category"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  A short phrase to organize your programs into simple categories. Max 40 characters
                </ReactTooltip>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  maxLength="40"
                  value={this.global.program.category_description}
                  name="category_description"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              {this.global.program.program_category === "Single Sessions" ? (
                <h3 className="label-names">
                  Spots per Timeslot {""}
                  <i
                    data-tip
                    data-for="spots-per-timeslot"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="spots-per-timeslot"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    The max number of spots available for each timeslot
                    specified below
                  </ReactTooltip>
                </h3>
              ) : (
                <h3 className="label-names">Total Spots</h3>
              )}
            </div>
            <div className="col-7 d-flex">
              <input
                className="input-fields"
                style={{ width: "100%" }}
                type="number"
                value={this.global.program.total_spots}
                name="total_spots"
                onChange={e =>{
                  if (e.target.value === "") {
                    this.handleChange(
                      e.target.name,
                      ""
                    );
                    return;
                  }
                  this.handleChange(
                    e.target.name,
                    parseInt(parseFloat(e.target.value)).toString()
                  );}
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Show Total Spots</h3>
            </div>
            <div className="col-7 d-flex">
              <div className="w-100">
                <Switch
                  disabled={!this.global.program.total_spots}
                  name="show_total_spots"
                  checked={this.global.program.show_total_spots}
                  onClick={_ => {
                    this.handleChange(
                      "show_total_spots",
                      !this.global.program.show_total_spots
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Enable Waitlist{" "}
                <i
                  data-tip
                  data-for="enable_waitlist"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="enable_waitlist"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  Customers will be waitlisted once all total spots get filled
                </ReactTooltip>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className="w-100">
                <Switch
                  disabled={!this.global.program.total_spots}
                  name="enable_waitlist"
                  checked={this.global.program.enable_waitlist}
                  onClick={_ => {
                    this.handleChange(
                      "enable_waitlist",
                      !this.global.program.enable_waitlist
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Enable Continuous Registration{" "}
                <i
                  data-tip
                  data-for="enable_continuous_registration"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="enable_continuous_registration"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  Ability for parents to enroll / register after the program's
                  start date up until the program's end date
                </ReactTooltip>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className="w-100">
                <Switch
                  name="continuous_registration"
                  isDisabled={this.global.program.isRecurring}
                  checked={
                    this.global.program.continuous_registration ||
                    this.global.program.isRecurring
                  }
                  onClick={_ => {
                    this.handleChange(
                      "continuous_registration",
                      !this.global.program.continuous_registration
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Start Date{" "}
                <i
                  className="far fa-edit option-img"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({ openEditProgramStartDateModal: true })
                  }
                ></i>
                {/* <i
                  data-tip
                  data-for="program-start"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="program-start"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  The start date for the program
                </ReactTooltip> */}
              </h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.DateOnly
                value={this.global.program.program_start}
                onChange={this.handleChange}
                name="program_start"
                // minDate={
                //   this.global.program.program_category === "Single Sessions"
                //     ? new Date()
                //     : null
                // }
                minDate={new Date()}
                isDisabled={
                  !this.global.enrollments || this.global.enrollments.length > 0
                }
                style={{ width: "100px" }}
              />
            </div>
          </div>
          {this.global.program.isRecurring ? (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">Number of Weeks</h3>
              </div>
              <div className="col-7 d-flex">
                <formTypes.Select
                  options={new Array(1).fill(1).map((_, k) => {
                    return { label: "Ongoing (Recurring)", value: 0 };
                  })}
                  onChange={e =>
                    this.handleChange("number_of_weeks", e.target.value)
                  }
                  value={this.global.program.number_of_weeks}
                ></formTypes.Select>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">
                  Number of Weeks
                  {/* {this.global.program.program_category !== */}
                  {/* "Single Sessions" ? ( */}
                    <span className="requiredField">*</span>
                  {/* ) : ( */}
                    {/* "" */}
                  {/* )} */}
                </h3>
              </div>
              <div className="col-7 d-flex">
                <formTypes.Select
                  /*                   options={new Array(13).fill(1).map((_, k) => {
                    return { label: `${k + 1} Week(s)`, value: k + 1 };
                  })} */
                  options={[
                    { label: "Please select", value: 0 },
                    ...new Array(52).fill(1).map((_, k) => {
                      return { label: `${k + 1} Week(s)`, value: k + 1 };
                    })
                  ]}
                  onChange={e =>
                    this.handleChange("number_of_weeks", e.target.value)
                  }
                  //value={this.global.program.number_of_weeks}
                  value={(() => {
                    console.log(
                      "global number of weeks…",
                      this.global.program.number_of_weeks
                    );
                    return this.global.program.number_of_weeks;
                  })()}
                ></formTypes.Select>
              </div>
            </div>
          )}

          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 className="label-names">
                Days of Week <span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                name="days"
                className="row days"
                validation={"validDays"}
                style={{ width: "100%", margin: 0, padding: 0 }}
              >
                <div
                  data-day="Monday"
                  className={`day-monday day ${
                    this.global.program.days_of_week["Monday"] ? "selecta" : ""
                  }`}
                  onClick={e => {
                    this.setDay(e.target.getAttribute("data-day"));
                    this.setCustomTime(e.target.getAttribute("data-day"));
                  }}
                >
                  M
                </div>
                <div
                  data-day="Tuesday"
                  className={`day-tuesday day ${
                    this.global.program.days_of_week["Tuesday"] ? "selecta" : ""
                  }`}
                  onClick={e => {
                    this.setDay(e.target.getAttribute("data-day"));
                    this.setCustomTime(e.target.getAttribute("data-day"));
                  }}
                >
                  Tu
                </div>
                <div
                  data-day="Wednesday"
                  className={`day-wednesday day ${
                    this.global.program.days_of_week["Wednesday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => {
                    this.setDay(e.target.getAttribute("data-day"));
                    this.setCustomTime(e.target.getAttribute("data-day"));
                  }}
                >
                  W
                </div>
                <div
                  data-day="Thursday"
                  className={`day-thursday day ${
                    this.global.program.days_of_week["Thursday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => {
                    this.setDay(e.target.getAttribute("data-day"));
                    this.setCustomTime(e.target.getAttribute("data-day"));
                  }}
                >
                  Th
                </div>
                <div
                  data-day="Friday"
                  className={`day-friday day ${
                    this.global.program.days_of_week["Friday"] ? "selecta" : ""
                  }`}
                  onClick={e => {
                    this.setDay(e.target.getAttribute("data-day"));
                    this.setCustomTime(e.target.getAttribute("data-day"));
                  }}
                >
                  F
                </div>
                <div
                  data-day="Saturday"
                  className={`day-saturday day ${
                    this.global.program.days_of_week["Saturday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => {
                    this.setDay(e.target.getAttribute("data-day"));
                    this.setCustomTime(e.target.getAttribute("data-day"));
                  }}
                >
                  Sa
                </div>
                <div
                  data-day="Sunday"
                  className={`day-sunday day ${
                    this.global.program.days_of_week["Sunday"] ? "selecta" : ""
                  }`}
                  onClick={e => {
                    this.setDay(e.target.getAttribute("data-day"));
                    this.setCustomTime(e.target.getAttribute("data-day"));
                  }}
                >
                  Su
                </div>
              </div>
            </div>
          </div>
          {this.global.program.program_category !== "Single Sessions" ? (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">
                  Timing
                  <span className="requiredField"> *</span>
                </h3>
              </div>
              <div
                className="col-7 d-flex padding-zero"
                style={{ alignItems: "center" }}
              >
                <div style={{ width: "40%" }}>
                  <formTypes.TimeDropdownSelect
                    end={parseFloat(
                      (this.global.program.timing &&
                        parseInt(this.global.program.timing[1]) !== -1 &&
                        this.global.program.timing[1]) ||
                        null
                    )}
                    value={parseFloat(this.global.program.timing[0] || -1)}
                    name="timing"
                    onChange={(e, v) =>
                      this.handleChange(e, [v, this.global.program.timing[1]])
                    }
                  ></formTypes.TimeDropdownSelect>
                </div>
                To
                <div style={{ width: "40%" }}>
                  <formTypes.TimeDropdownSelect
                    start={parseFloat(
                      (this.global.program.timing &&
                        parseInt(this.global.program.timing[0]) !== -1 &&
                        this.global.program.timing[0]) ||
                        null
                    )}
                    name="timing"
                    value={parseFloat(this.global.program.timing[1] || -1)}
                    onChange={(e, v) =>
                      this.handleChange(e, [this.global.program.timing[0], v])
                    }
                  ></formTypes.TimeDropdownSelect>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              {/* <div className="col-5">
              <h3 className="label-names">Timing</h3>
            </div> */}
              {/* <div
              className="col-7 d-flex padding-zero"
              style={{ alignItems: "center" }}
            >
              <div style={{ width: "40%" }}>
                <formTypes.TimeDropdownSelect
                  value={parseFloat(this.global.program.timing[0] || -1)}
                  name="timing"
                  onChange={(e, v) =>
                    this.handleChange(e, [v, this.global.program.timing[1]])
                  }
                ></formTypes.TimeDropdownSelect>
              </div>
              To
              <div style={{ width: "40%" }}>
                <formTypes.TimeDropdownSelect
                  name="timing"
                  value={parseFloat(this.global.program.timing[1] || -1)}
                  onChange={(e, v) =>
                    this.handleChange(e, [this.global.program.timing[0], v])
                  }
                ></formTypes.TimeDropdownSelect>
              </div>
            </div> */}
              {!this.global.program.enableCustomTimes ? (
                this.global.program.timing.length >= 2 &&
                new Array(this.global.program.timing.length / 2)
                  .fill(1)
                  .map((_, index) => {
                    return (
                      <div className="row">
                        <div className="col-5">
                          <h3
                            className="label-names"
                            style={{ marginLeft: "25px" }}
                          >
                            {/* Add the required star to the first timing selector */}
                            Timing {index + 1}
                            {this.global.program.program_details ===
                              "Lessons / Sessions" && index === 0 ? (
                              <span className="requiredField"> *</span>
                            ) : null}
                          </h3>
                        </div>
                        <div
                          className="col-7 d-flex padding-zero"
                          style={{ alignItems: "center" }}
                        >
                          <div style={{ width: "40%" }}>
                            <formTypes.TimeDropdownSelect
                              end={parseFloat(
                                (this.global.program.timing &&
                                  parseInt(
                                    this.global.program.timing[index * 2 + 1]
                                  ) !== -1 &&
                                  this.global.program.timing[index * 2 + 1]) ||
                                  null
                              )}
                              value={parseFloat(
                                this.global.program.timing[
                                  this.state.leftIndex[index]
                                ] || -1
                              )}
                              name="timing"
                              onChange={(e, v) => {
                                const times = this.global.program.timing;
                                times[this.state.leftIndex[index]] = v;
                                this.handleChange(e, times);
                              }}
                            ></formTypes.TimeDropdownSelect>
                          </div>
                          To
                          <div style={{ width: "40%" }}>
                            <formTypes.TimeDropdownSelect
                              start={parseFloat(
                                (this.global.program.timing &&
                                  parseInt(
                                    this.global.program.timing[index * 2]
                                  ) !== -1 &&
                                  this.global.program.timing[index * 2]) ||
                                  null
                              )}
                              name="timing"
                              value={parseFloat(
                                this.global.program.timing[
                                  this.state.rightIndex[index]
                                ] || -1
                              )}
                              onChange={(e, v) => {
                                const times = this.global.program.timing;
                                times[this.state.rightIndex[index]] = v;
                                this.handleChange(e, times);
                              }}
                            ></formTypes.TimeDropdownSelect>
                          </div>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <>
                  {this.global.program.days_of_week["Monday"] &&
                    renderCustomTimeRange("Monday")}
                  {this.global.program.days_of_week["Tuesday"] &&
                    renderCustomTimeRange("Tuesday")}
                  {this.global.program.days_of_week["Wednesday"] &&
                    renderCustomTimeRange("Wednesday")}
                  {this.global.program.days_of_week["Thursday"] &&
                    renderCustomTimeRange("Thursday")}
                  {this.global.program.days_of_week["Friday"] &&
                    renderCustomTimeRange("Friday")}
                  {this.global.program.days_of_week["Saturday"] &&
                    renderCustomTimeRange("Saturday")}
                  {this.global.program.days_of_week["Sunday"] &&
                    renderCustomTimeRange("Sunday")}
                </>
              )}

              {this.global.program.program_category === "Single Sessions" &&
                !this.global.program.enableCustomTimes && (
                  <div
                    className="col-12 d-flex"
                    style={{ justifyContent: "flex-end", marginTop: "10px" }}
                  >
                    <button
                      type="button"
                      disabled={this.global.program.timing.length === 16}
                      className="btn addNew-btn" //btn-primary
                      onClick={_ =>
                        this.setGlobal({
                          program: {
                            ...this.global.program,
                            timing: [...this.global.program.timing, -1, -1]
                          }
                        })
                      }
                    >
                      Add Time Range
                    </button>
                  </div>
                )}
            </div>
          )}
          {this.global.program.program_category === "Single Sessions" && (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">Custom Times</h3>
              </div>
              <div className="col-7 d-flex">
                <div className="w-100">
                  <Switch
                    //disabled={!this.global.program.total_spots ? true : false}
                    name="enableCustomTimes"
                    checked={this.global.program.enableCustomTimes}
                    onClick={_ => {
                      this.handleChange(
                        "enableCustomTimes",
                        !this.global.program.enableCustomTimes
                      );
                      if (this.global.program.enableCustomTimes) {
                        const object = {};
                        if (this.global.program.days_of_week["Monday"]) {
                          object["Monday"] = [-1, -1];
                        }
                        if (this.global.program.days_of_week["Tuesday"]) {
                          object["Tuesday"] = [-1, -1];
                        }
                        if (this.global.program.days_of_week["Wednesday"]) {
                          object["Wednesday"] = [-1, -1];
                        }
                        if (this.global.program.days_of_week["Thursday"]) {
                          object["Thursday"] = [-1, -1];
                        }
                        if (this.global.program.days_of_week["Friday"]) {
                          object["Friday"] = [-1, -1];
                        }
                        if (this.global.program.days_of_week["Saturday"]) {
                          object["Saturday"] = [-1, -1];
                        }
                        if (this.global.program.days_of_week["Sunday"]) {
                          object["Sunday"] = [-1, -1];
                        }
                        this.handleChange("timing", [-1, -1]);
                        this.handleChange("customTimes", object);
                      } else {
                        this.handleChange("customTimes", {});
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Closure Dates</h3>
            </div>
            <div className="col-7 d-flex">
              <div style={{ width: 0 }}>
                <formTypes.Date
                  style={{ display: "none" }}
                  id="datenumber2"
                  value={null}
                  onChange={(e, v) =>
                    this.handleChange(e, [
                      ...this.global.program.closure_dates,
                      v
                    ])
                  }
                  name="closure_dates"
                ></formTypes.Date>
              </div>

              <formTypes.DateMultiSelect
                className="no-border"
                value={this.global.program.closure_dates.map((e, k) => ({
                  name: format(new Date(e), "LLLL dd, yyyy"),
                  value: format(new Date(e), "LLLL dd, yyyy")
                }))}
                open={_ => document.getElementById("datenumber2").click()}
                name="closure_dates"
                onChange={(e, v) =>
                  this.handleChange(
                    e,
                    v.map(e => e.name)
                  )
                }
              ></formTypes.DateMultiSelect>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Closure Timeframe</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.Date
                  name="closure_timeframe"
                  value={this.global.program.closure_timeframe[0]}
                  maxDate={
                    this.global.program.closure_timeframe[1]
                      ? new Date(this.global.program.closure_timeframe[1])
                      : null
                  }
                  onChange={(e, v) =>
                    this.handleChange(e, [
                      v,
                      this.global.program.closure_timeframe[1]
                    ])
                  }
                ></formTypes.Date>
              </div>
              <span style={{ marginRight: "5px", marginLeft: "5px" }}>To</span>
              <div className={`w-100`}>
                <formTypes.Date
                  name="closure_timeframe"
                  value={this.global.program.closure_timeframe[1]}
                  isDisabled={!this.global.program.closure_timeframe[0]}
                  minDate={
                    this.global.program.closure_timeframe[0]
                      ? new Date(this.global.program.closure_timeframe[0])
                      : null
                  }
                  onChange={(e, v) =>
                    this.handleChange(e, [
                      this.global.program.closure_timeframe[0],
                      v
                    ])
                  }
                ></formTypes.Date>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Schedule Information"}
        customForm={this.customForm()}
        editStartDate={this.state.openEditProgramStartDateModal}
        handleStartDateChange={date => {
          if (date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
            const day = String(date.getDate()).padStart(2, "0");

            this.setGlobal({
              program: {
                ...this.global.program,
                program_start: `${year}-${month}-${day}`
              }
            });
          }
        }}
        onCloseStartDateModal={() =>
          this.setState({ openEditProgramStartDateModal: false })
        }
        programStartDate={this.global.program.program_start}
      />
    );
  }
}

export default SectionThree;
