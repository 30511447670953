import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  SWITCH
} from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddFreeOnline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total_price: 0,
      description: "",
      isCapEnabled: false,
      isBilledLater: false,
      planCap: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async savePlan(e) {
    e.preventDefault();
    const obj = { ...this.state, type: "Free" };
    obj.description.substring(0, 50);
    if (this.global.online.total_spots && this.global.online.total_spots < parseInt(obj.planCap)) {
      obj.planCap = this.global.online.total_spots;
    } else {
      if (parseInt(obj.planCap) > 200) {
        obj.planCap = 200;
      }
    }

    this.setGlobal(
      {
        online: {
          ...this.global.online,
          // product_stripe: res.data.data.product,
          plans: [
            ...this.global.online.plans,
            {
              ...obj,
              method: "Online",
              id: this.global.online.plans.length
            }
          ]
        }
      },
      () => {
        this.dispatch.saveVirtual();
      }
    );
    this.props.onClose();
  }

  getBaseModalFields() {
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: true,
        info: "max 50 char",
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description.substring(0, 50)
      },
      validators: {validateSubmit: () => this.state.description.trim()}
    };

    const AddCap = {
      type: SWITCH,
      data: {
        name: "Enable Program Plan Cap",
        isDisabled: false,
        checked: this.state.isCapEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isCapEnabled: e.target.checked,
            planCap: ""
          })
      }
    };
    
    const BilledLater = {
      type: SWITCH,
      data: {
        name: "Billed Later",
        isDisabled: false,
        checked: this.state.isBilledLater,
        info: "If checked, the plan will display as Billed Later rather than Free plan on your marketplace page",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isBilledLater: e.target.checked
          })
      }
    };

    const PlanCap = {
      type: STRING_INPUT,
      data: {
        type: "number",
        name: this.global.online.total_spots ? `Plan Cap (Max: ${this.global.online.total_spots})` : "Plan Cap",
        required: false,
        placeholder: "Enter Cap",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            planCap: e.target.value
          });
        }
      }
    }; 

    const fields = [];

    fields.push(descriptionInput);
    if (!this.global.online.isRecurring) {
      fields.push(AddCap);
      
    }
    if (this.state.isCapEnabled) {
      fields.push(PlanCap);
    }
    fields.push(BilledLater);

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Create",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.savePlan(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create Free Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

    render() {
        return <BaseModal {...this.getBaseModalProps()} />;
    }
}

export default withRouter(AddFreeOnline);